import { Actions } from './actions';
import {
  CLOSE_ERROR_MODAL,
  CLOSE_ERROR_POPUP_PACKING,
  GET_ALL_PACKING_ERROR,
  GET_ALL_PACKING_REQUEST,
  GET_ALL_PACKING_SUCCESS,
  GET_PACKING_BY_ID_REQUEST,
  GET_PACKING_BY_ID_ERROR,
  GET_PACKING_BY_ID_SUCCESS,
  POST_ADD_NEW_PACKING_ERROR,
  POST_ADD_NEW_PACKING_SUCCESS,
  PUT_UPDATE_PACKING_ERROR,
  PUT_UPDATE_PACKING_SUCCESS,
} from './constants';
import { ErrorResponse } from '../../../../entities/ImportCodes/interfaces';

const initialState = {
  loading: false as boolean,
  error: null as Error | null,
  packages: [] as any,

  packingById: {} as any,

  newPack: {} as any,
  updatePackResponse: {} as any,

  validateFileErrorResponse: {} as ErrorResponse,

  getPackingErrorResponses: {} as any,
  addPackingErrorResponses: {} as any,
  updatePackingErrorResponses: {} as any,
  getPackingByIdErrorResponses: {} as any,
};

export type PackingState = typeof initialState;

export const PackingReducer = (state: PackingState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_ALL_PACKING_REQUEST: {
      return {
        ...state,
        loading: true,
        packingById: {} as any,
        packages: [],
      };
    }
    case GET_ALL_PACKING_SUCCESS: {
      return {
        ...state,
        loading: false,
        packages: action.payload,
      };
    }
    case GET_ALL_PACKING_ERROR: {
      return {
        ...state,
        loading: false,
        getPackingErrorResponses: action.payload,
      };
    }

    case CLOSE_ERROR_MODAL: {
      return {
        ...state,
        validateFileErrorResponse: {},
      };
    }
    case GET_PACKING_BY_ID_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_PACKING_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        packingById: action.payload,
      };
    }
    case GET_PACKING_BY_ID_ERROR: {
      return {
        ...state,
        loading: false,
        getPackingByIdErrorResponses: action.payload,
      };
    }

    case POST_ADD_NEW_PACKING_SUCCESS: {
      return {
        ...state,
        loading: false,
        newPack: action.payload,
      };
    }
    case POST_ADD_NEW_PACKING_ERROR: {
      return {
        ...state,
        loading: false,
        addPackingErrorResponses: action.payload,
      };
    }

    case PUT_UPDATE_PACKING_SUCCESS: {
      return {
        ...state,
        updatePackResponse: action.payload,
      };
    }
    case PUT_UPDATE_PACKING_ERROR: {
      return {
        ...state,
        updatePackingErrorResponses: action.payload,
      };
    }

    case CLOSE_ERROR_POPUP_PACKING: {
      return {
        ...state,
        getPackingErrorResponses: {} as any,
        addPackingErrorResponses: {} as any,
        updatePackingErrorResponses: {} as any,
        getPackingByIdErrorResponses: {} as any,
      };
    }

    default: {
      return state;
    }
  }
};
