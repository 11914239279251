import { Select, type SelectChangeEvent } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

import styles from './SelectField.module.scss';

const selectStyle = {
  width: 300,
  height: 30,
  fontSize: '13px',
  bgcolor: '#ffffff',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
};

const smallSelectStyle = {
  width: 200,
  height: 30,
  fontSize: '13px',
  bgcolor: '#ffffff',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
};

interface Props {
  title: string;
  name: string;
  value: string;
  dropdownValues?: any;
  variant?: string;
  size?: string;
  error?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  handleChangeSelect?: any;
}

export const SelectField: React.FC<Props> = ({
  title,
  name,
  value,
  variant,
  size,
  children,
  error,
  disabled,
  handleChangeSelect,
}) => {
  const { setFieldValue } = useFormikContext<any>();

  return (
    <div className={variant === 'noLabel' ? styles.noLabelDropdownElement : styles.dropdownElement}>
      {variant === 'noLabel' ? null : <span className={styles.dropdownElementText}>{title}:</span>}
      <Select
        sx={size === 'small' ? smallSelectStyle : selectStyle}
        name={name}
        onChange={
          handleChangeSelect ? handleChangeSelect : (e: SelectChangeEvent<any>) => setFieldValue(name, e.target.value)
        }
        value={value}
        error={error}
        disabled={disabled}
        MenuProps={{
          MenuListProps: {
            sx: {
              bgcolor: '#ffffff',
            },
          },
        }}
      >
        {children}
      </Select>
    </div>
  );
};
