import axios from 'axios';
import { msalInstance } from '../index';
import { msalScopes } from '../modules/Auth/constants';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Accept-Language': 'uk-UA',
  },
});

const getToken = async () => {
  const account = msalInstance.getAllAccounts()[0];
  try {
    const token = await msalInstance.acquireTokenSilent({
      // @ts-expect-error
      scopes: msalScopes,
      account,
    });
    return token.accessToken;
  } catch (error) {
    msalInstance.loginRedirect();
    // eslint-disable-next-line
    Promise.reject(error);
  }
};

instance.interceptors.request.use(
  // @ts-expect-error
  async (config) => {
    const token = await getToken();

    const Xcid = localStorage.getItem('cid');
    const XentraGroupId = localStorage.getItem('entraGroupId');

    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
          'x-cid': `${Xcid}`,
          'x-entraGroupId': `${XentraGroupId}`,
          'x-module': process.env.REACT_APP_REACT_APP_MODULE_ID,
          'x-versioncode': process.env.REACT_APP_VERSION_DATA,
        },
      };
    }

    return config;
  },
  (error) => Promise.reject(error),
);
