export const GET_ALL_CODES_REQUEST = 'GET_ALL_CODES_REQUEST' as const;
export const GET_ALL_CODES_SUCCESS = 'GET_ALL_CODES_SUCCESS' as const;
export const GET_ALL_CODES_ERROR = 'GET_ALL_CODES_ERROR' as const;

export const POST_ALLOW_TO_PRINT_REQUEST = 'POST_ALLOW_TO_PRINT_REQUEST' as const;
export const POST_ALLOW_TO_PRINT_SUCCESS = 'POST_ALLOW_TO_PRINT_SUCCESS' as const;
export const POST_ALLOW_TO_PRINT_ERROR = 'POST_ALLOW_TO_PRINT_ERROR' as const;

export const POST_DETAILED_INFO_REQUEST = 'POST_DETAILED_INFO_REQUEST' as const;
export const POST_DETAILED_INFO_SUCCESS = 'POST_DETAILED_INFO_SUCCESS' as const;
export const POST_DETAILED_INFO_ERROR = 'POST_DETAILED_INFO_ERROR' as const;

export const CLOSE_ERROR_POPUP_BANK = 'CLOSE_ERROR_POPUP_BANK' as const;
