import * as Yup from 'yup';

export const ProductsSchema = Yup.object().shape({
  externalOrderNumber: Yup.mixed().required(),
  countryCodeId: Yup.mixed().required(),
  products: Yup.array().of(
    Yup.object().shape({
      filters: Yup.array().of(
        Yup.object().shape({
          unitsPlannedCount: Yup.mixed(),
        }),
      ),
    }),
  ),
});
