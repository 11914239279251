import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  menu: {
    '& .MuiPaper-root': {
      backgroundColor: '#535353 !important',
      color: '#ffffff !important',
    },
    '& .MuiList-root': {
      backgroundColor: '#535353 !important',
      color: '#ffffff !important',
    },
    '& .Mui-selected': {
      backgroundColor: 'turquoise',
      color: 'white',
      fontWeight: 600,
    },
  },
  list: {
    '& .MuiMenuItem-root': {
      backgroundColor: '#535353 !important',
      color: '#ffffff !important',
    },
  },
}));
