export const GET_BOX_TEMPLATES_REQUEST = 'GET_BOX_TEMPLATES_REQUEST' as const;
export const GET_BOX_TEMPLATES_SUCCESS = 'GET_BOX_TEMPLATES_SUCCESS' as const;
export const GET_BOX_TEMPLATES_ERROR = 'GET_BOX_TEMPLATES_ERROR' as const;

export const GET_BOX_TEMPLATES_BY_ID_REQUEST = 'GET_BOX_TEMPLATES_BY_ID_REQUEST' as const;
export const GET_BOX_TEMPLATES_BY_ID_SUCCESS = 'GET_BOX_TEMPLATES_BY_ID_SUCCESS' as const;
export const GET_BOX_TEMPLATES_BY_ID_ERROR = 'GET_BOX_TEMPLATES_BY_ID_ERROR' as const;

export const DELETE_BOX_TEMPLATES_BY_ID_REQUEST = 'DELETE_BOX_TEMPLATES_BY_ID_REQUEST' as const;
export const DELETE_BOX_TEMPLATES_BY_ID_SUCCESS = 'DELETE_BOX_TEMPLATES_BY_ID_SUCCESS' as const;
export const DELETE_BOX_TEMPLATES_BY_ID_ERROR = 'DELETE_BOX_TEMPLATES_BY_ID_ERROR' as const;

export const POST_CREATE_BOX_TEMPLATES_REQUEST = 'POST_CREATE_BOX_TEMPLATES_REQUEST' as const;
export const POST_CREATE_BOX_TEMPLATES_SUCCESS = 'POST_CREATE_BOX_TEMPLATES_SUCCESS' as const;
export const POST_CREATE_BOX_TEMPLATES_ERROR = 'POST_CREATE_BOX_TEMPLATES_ERROR' as const;

export const PUT_UPDATE_BOX_TEMPLATES_REQUEST = 'PUT_UPDATE_BOX_TEMPLATES_REQUEST' as const;
export const PUT_UPDATE_BOX_TEMPLATES_SUCCESS = 'PUT_UPDATE_BOX_TEMPLATES_SUCCESS' as const;
export const PUT_UPDATE_BOX_TEMPLATES_ERROR = 'PUT_UPDATE_BOX_TEMPLATES_ERROR' as const;

export const POST_UPDATE_PHOTO_BOX_TEMPLATES_REQUEST = 'POST_UPDATE_PHOTO_BOX_TEMPLATES_REQUEST' as const;
export const POST_UPDATE_PHOTO_BOX_TEMPLATES_SUCCESS = 'POST_UPDATE_PHOTO_BOX_TEMPLATES_SUCCESS' as const;
export const POST_UPDATE_PHOTO_BOX_TEMPLATES_ERROR = 'POST_UPDATE_PHOTO_BOX_TEMPLATES_ERROR' as const;

export const GET_PHOTO_BOX_REQUEST = 'GET_PHOTO_BOX_REQUEST' as const;
export const GET_PHOTO_BOX_SUCCESS = 'GET_PHOTO_BOX_SUCCESS' as const;
export const GET_PHOTO_BOX_ERROR = 'GET_PHOTO_BOX_ERROR' as const;

export const CLEAR_PHOTO_BOX = 'CLEAR_PHOTO_BOX' as const;
export const CLOSE_ERROR_POPUP_BOX = 'CLOSE_ERROR_POPUP_BOX' as const;
