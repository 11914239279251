import { Actions } from './actions';
import {
  GET_WITHDRAWAL_REPORT_REQUEST,
  GET_WITHDRAWAL_REPORT_SUCCESS,
  GET_WITHDRAWAL_REPORT_ERROR,
  GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_REQUEST,
  GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_SUCCESS,
  GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_ERROR,
  CLOSE_ERROR_POPUP_WITHDRAWAL_REPORT_ERROR,
} from './constants';

const initialState = {
  loading: false as boolean,
  loadingUpdate: false as boolean,
  error: null as Error | null,

  withdrawalReportResponse: [] as any,
  withdrawalReportErrorResponses: {} as any,
};

export type WithdrawalReportState = typeof initialState;

export const WithdrawalReportReducer = (state: WithdrawalReportState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_WITHDRAWAL_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        withdrawalReportResponse: [],
        withdrawalReportErrorResponses: {},
      };
    }
    case GET_WITHDRAWAL_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        withdrawalReportResponse: action.payload,
      };
    }
    case GET_WITHDRAWAL_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        withdrawalReportErrorResponses: action.payload,
      };
    }

    case GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case CLOSE_ERROR_POPUP_WITHDRAWAL_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        withdrawalReportErrorResponses: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
