import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddTemplateModal from '../../common/AddTemplateModal';
import InputSearch from '../../common/InputSearch';
import { TemplatesTable } from '../../common/TemplatesTable/TemplatesTable';
import useModal from '../../shared/hooks/useModal/useModal';

import styles from '../UnitTemplates/UnitTemplates.module.scss';
import { getBoxTemplatesRequest, postCreateBoxTemplatesRequest } from './services/actions';
import { boxTemplateSelector } from './services/selector';
import { Spinner } from '../../common/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import ErrorResponse from '../../shared/ErrorResponse';
import { getCountryListRequest } from '../../../entities/Countries/actions';

export const BoxTemplates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isShow, openModal, closeModal } = useModal();

  useEffect(() => {
    dispatch(getBoxTemplatesRequest({ level: 2 }));
  }, []);

  const onAddHandler = () => {
    dispatch(getCountryListRequest());
    dispatch(postCreateBoxTemplatesRequest());
    openModal();
  };

  const { boxTemplates, createdBoxTemplate, loading, getBoxTemplateError } = useSelector(boxTemplateSelector);

  const [tableData, setTableData] = useState<any[]>(boxTemplates);

  useEffect(() => {
    setTableData(boxTemplates);
  }, [boxTemplates]);

  useEffect(() => {
    if (Object.keys(getBoxTemplateError).length !== 0 && getBoxTemplateError?.status === 403) {
      navigate('/error');
    }
  }, [getBoxTemplateError]);

  return (
    <>
      <div className={styles.wrapper}>
        {Object.keys(getBoxTemplateError).length !== 0 ? <ErrorResponse error={getBoxTemplateError} /> : null}
        <section className={styles.templateHeader}>
          <div className={styles.loadingGroup}>
            <span className={styles.templateSubTitle}>Шаблони ящиків (Level 2)</span>
            {loading ? <Spinner /> : null}
          </div>
          <span>Створюйте і редагуйте шаблони етикеток для ящиків</span>
        </section>
        <section className={styles.header}>
          <div className={styles.searchSection}>
            <div className={styles.searchGroup}>
              <span>Фільтр по всім полям:</span>
              <InputSearch setTableData={setTableData} data={boxTemplates} />
            </div>
            <Button variant="contained" type="button" className={styles.btn} onClick={onAddHandler}>
              <span className={styles.btnText}>Додати</span>
              <AddIcon className={styles.icon} />
            </Button>
          </div>
        </section>
        <section>
          <TemplatesTable data={tableData} level={2} />
        </section>
      </div>
      <AddTemplateModal isShown={isShow} closeModal={closeModal} level={2} data={createdBoxTemplate} />
    </>
  );
};
