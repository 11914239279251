import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  GET_RECEIPTS_REQUEST,
  DELETE_DEACTIVATE_REQUEST,
  GET_PRODUCT_LIST_REQUEST,
  POST_CREATE_RECEIPT_REQUEST,
  GET_RECEIPT_PDF_FILE_REQUEST,
} from './constants';
import {
  getReceiptsRequest,
  getReceiptsSuccess,
  getReceiptsError,
  deleteDeactivateRequest,
  deleteDeactivateSuccess,
  deleteDeactivateError,
  getProductListSuccess,
  getProductListError,
  postCreateReceiptRequest,
  postCreateReceiptSuccess,
  postCreateReceiptError,
  getReceiptPDFFileError,
  getReceiptPDFFileRequest,
  getReceiptPDFFileSuccess,
} from './actions';
import { Receipts } from '../../../../entities/Receipts/service';

function* getReceiptsRequestSaga(action: ReturnType<typeof getReceiptsRequest>) {
  try {
    const response: AxiosResponse = yield call(Receipts.getReceipts, action.payload);
    const receipts = response.data.ResponseBody;
    yield put(getReceiptsSuccess(receipts));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getReceiptsError(error));
  }
}

function* deleteDeactivateRequestSaga(action: ReturnType<typeof deleteDeactivateRequest>) {
  try {
    const response: AxiosResponse = yield call(Receipts.deleteDeactivate, action.payload);
    const deactivateResponse = response.data;
    yield put(deleteDeactivateSuccess(deactivateResponse));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(deleteDeactivateError(error));
  }
}

function* getProductListRequestSaga() {
  try {
    const response: AxiosResponse = yield call(Receipts.getProductList);
    const productListResponse = response.data.ResponseBody.map((product: any) => {
      const { internalName, ...rest } = product;
      return { ...rest, label: internalName ? internalName : null };
    });

    yield put(getProductListSuccess(productListResponse));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getProductListError(error));
  }
}

function* postCreateReceiptRequestSaga(action: ReturnType<typeof postCreateReceiptRequest>) {
  try {
    const response: AxiosResponse = yield call(Receipts.postCreate, action.payload);
    const createResponse = response.data;
    yield put(postCreateReceiptSuccess(createResponse));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postCreateReceiptError(error));
  }
}

function* getPDFFileRequestSaga(action: ReturnType<typeof getReceiptPDFFileRequest>) {
  try {
    yield call(Receipts.getReceiptPDFFile, action.payload);

    yield put(getReceiptPDFFileSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getReceiptPDFFileError(error));
  }
}

export function* ReceiptsSagaWatcher() {
  yield takeLatest(GET_RECEIPTS_REQUEST, getReceiptsRequestSaga);
  yield takeLatest(DELETE_DEACTIVATE_REQUEST, deleteDeactivateRequestSaga);
  yield takeLatest(GET_PRODUCT_LIST_REQUEST, getProductListRequestSaga);
  yield takeLatest(POST_CREATE_RECEIPT_REQUEST, postCreateReceiptRequestSaga);
  yield takeLatest(GET_RECEIPT_PDF_FILE_REQUEST, getPDFFileRequestSaga);
}
