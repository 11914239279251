import { AxiosResponse } from 'axios';
import { call, put, takeLatest, delay } from 'redux-saga/effects';
import {
  GET_ALL_PRINT_CODES_REQUEST,
  POST_CANCEL_PRINT_JOB_REQUEST,
  POST_FILTER_PRINT_JOB_REQUEST,
  POST_PRINT_JOB_DETAILED_REQUEST,
  POST_UNRESERVE_MARKING_CODES_REQUEST,
} from './constants';
import {
  getAllPrintCodesError,
  getAllPrintCodesSuccess,
  postCancelPrintJobError,
  postCancelPrintJobRequest,
  postCancelPrintJobSuccess,
  postFilterPrintJobError,
  postFilterPrintJobRequest,
  postFilterPrintJobSuccess,
  postPrintJobDetailedError,
  postPrintJobDetailedRequest,
  postPrintJobDetailedSuccess,
  postUnreserveMarkingCodesError,
  postUnreserveMarkingCodesRequest,
  postUnreserveMarkingCodesSuccess,
} from './actions';
import { PrintCodes } from '../../../../entities/PrintCodes/service';

function* getAllPrintCodesSaga() {
  try {
    const response: AxiosResponse = yield call(PrintCodes.getAllPrintCodes);
    const res = response.data.ResponseBody;
    yield put(getAllPrintCodesSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getAllPrintCodesError(error));
  }
}

function* postCancelPrintJobSaga(action: ReturnType<typeof postCancelPrintJobRequest>) {
  try {
    yield call(PrintCodes.cancelPrintJob, action.payload);
    yield put(postCancelPrintJobSuccess());
    yield put(postPrintJobDetailedRequest(action.payload));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postCancelPrintJobError(error));
  }
}

function* postPrintJobDetailedSaga(action: ReturnType<typeof postPrintJobDetailedRequest>) {
  try {
    const response: AxiosResponse = yield call(PrintCodes.printCodesDetailed, action.payload);
    const res = response.data.ResponseBody;
    yield put(postPrintJobDetailedSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postPrintJobDetailedError(error));
  }
}

function* postFilterPrintJobSaga(action: ReturnType<typeof postFilterPrintJobRequest>) {
  try {
    const response: AxiosResponse = yield call(PrintCodes.filterPrintJob, action.payload);
    const res = response.data.ResponseBody;
    yield put(postFilterPrintJobSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postFilterPrintJobError(error));
  }
}

function* postUnreserveMarkingCodesRequestSaga(action: ReturnType<typeof postUnreserveMarkingCodesRequest>) {
  try {
    const response: AxiosResponse = yield call(PrintCodes.unreserveMarkingCodes, action.payload);
    const res = response.data.UserText;
    yield put(postUnreserveMarkingCodesSuccess(res));
    yield delay(5000);
    yield put(postUnreserveMarkingCodesSuccess(''));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postUnreserveMarkingCodesError(error));
  }
}

export function* PrintCodesSagaWatcher() {
  yield takeLatest(GET_ALL_PRINT_CODES_REQUEST, getAllPrintCodesSaga);
  yield takeLatest(POST_FILTER_PRINT_JOB_REQUEST, postFilterPrintJobSaga);
  yield takeLatest(POST_CANCEL_PRINT_JOB_REQUEST, postCancelPrintJobSaga);
  yield takeLatest(POST_PRINT_JOB_DETAILED_REQUEST, postPrintJobDetailedSaga);
  yield takeLatest(POST_UNRESERVE_MARKING_CODES_REQUEST, postUnreserveMarkingCodesRequestSaga);
}
