import { useFormikContext } from 'formik';
import { useEffect } from 'react';

interface Props {
  resetCheckbox: any;
}

export const ClearCheckbox = ({ resetCheckbox }: Props) => {
  const { values } = useFormikContext<any>();
  useEffect(() => {
    if (values.documentStatus === 1) {
      resetCheckbox();
    }
  }, [values.documentStatus]);

  return null;
};
