export const POST_ADD_NEW_PACKING_REQUEST = 'POST_ADD_NEW_PACKING_REQUEST' as const;
export const POST_ADD_NEW_PACKING_SUCCESS = 'POST_ADD_NEW_PACKING_SUCCESS' as const;
export const POST_ADD_NEW_PACKING_ERROR = 'POST_ADD_NEW_PACKING_ERROR' as const;

export const PUT_UPDATE_PACKING_REQUEST = 'PUT_UPDATE_PACKING_REQUEST' as const;
export const PUT_UPDATE_PACKING_SUCCESS = 'PUT_UPDATE_PACKING_SUCCESS' as const;
export const PUT_UPDATE_PACKING_ERROR = 'PUT_UPDATE_PACKING_ERROR' as const;

export const GET_ALL_PACKING_REQUEST = 'GET_ALL_PACKING_REQUEST' as const;
export const GET_ALL_PACKING_SUCCESS = 'GET_ALL_PACKING_SUCCESS' as const;
export const GET_ALL_PACKING_ERROR = 'GET_ALL_PACKING_ERROR' as const;

export const GET_PACKING_BY_ID_REQUEST = 'GET_PACKING_BY_ID_REQUEST' as const;
export const GET_PACKING_BY_ID_SUCCESS = 'GET_PACKING_BY_ID_SUCCESS' as const;
export const GET_PACKING_BY_ID_ERROR = 'GET_PACKING_BY_ID_ERROR' as const;

export const DELETE_PACKING_REQUEST = 'DELETE_PACKING_REQUEST' as const;
export const DELETE_PACKING_SUCCESS = 'DELETE_PACKING_SUCCESS' as const;
export const DELETE_PACKING_ERROR = 'DELETE_PACKING_ERROR' as const;

export const CLOSE_ERROR_MODAL = 'CLOSE_ERROR_MODAL' as const;
export const CLOSE_ERROR_POPUP_PACKING = 'CLOSE_ERROR_POPUP_PACKING' as const;
