import {
  CLEAR_PHOTO_UNIT,
  CLOSE_ERROR_POPUP_UNIT,
  DELETE_UNIT_TEMPLATES_BY_ID_ERROR,
  DELETE_UNIT_TEMPLATES_BY_ID_REQUEST,
  DELETE_UNIT_TEMPLATES_BY_ID_SUCCESS,
  GET_PHOTO_UNIT_ERROR,
  GET_PHOTO_UNIT_REQUEST,
  GET_PHOTO_UNIT_SUCCESS,
  GET_UNIT_TEMPLATES_BY_ID_ERROR,
  GET_UNIT_TEMPLATES_BY_ID_REQUEST,
  GET_UNIT_TEMPLATES_BY_ID_SUCCESS,
  GET_UNIT_TEMPLATES_ERROR,
  GET_UNIT_TEMPLATES_REQUEST,
  GET_UNIT_TEMPLATES_SUCCESS,
  POST_CREATE_UNIT_TEMPLATES_ERROR,
  POST_CREATE_UNIT_TEMPLATES_REQUEST,
  POST_CREATE_UNIT_TEMPLATES_SUCCESS,
  POST_UPDATE_PHOTO_UNIT_TEMPLATES_ERROR,
  POST_UPDATE_PHOTO_UNIT_TEMPLATES_REQUEST,
  POST_UPDATE_PHOTO_UNIT_TEMPLATES_SUCCESS,
  PUT_UPDATE_UNIT_TEMPLATES_ERROR,
  PUT_UPDATE_UNIT_TEMPLATES_REQUEST,
  PUT_UPDATE_UNIT_TEMPLATES_SUCCESS,
} from './constants';

export const getUnitTemplatesRequest = (body: any) => ({
  type: GET_UNIT_TEMPLATES_REQUEST,
  payload: body,
});
export const getUnitTemplatesSuccess = (data: any) => ({
  type: GET_UNIT_TEMPLATES_SUCCESS,
  payload: data,
});
export const getUnitTemplatesError = (error: any) => ({
  type: GET_UNIT_TEMPLATES_ERROR,
  payload: error,
});

export const postCreateUnitTemplatesRequest = () => ({
  type: POST_CREATE_UNIT_TEMPLATES_REQUEST,
});
export const postCreateUnitTemplatesSuccess = (data: any) => ({
  type: POST_CREATE_UNIT_TEMPLATES_SUCCESS,
  payload: data,
});
export const postCreateUnitTemplatesError = (error: any) => ({
  type: POST_CREATE_UNIT_TEMPLATES_ERROR,
  payload: error,
});

export const putUpdateUnitTemplatesRequest = (data: any) => ({
  type: PUT_UPDATE_UNIT_TEMPLATES_REQUEST,
  payload: data,
});
export const putUpdateUnitTemplatesSuccess = (data: any) => ({
  type: PUT_UPDATE_UNIT_TEMPLATES_SUCCESS,
  payload: data,
});
export const putUpdateUnitTemplatesError = (error: any) => ({
  type: PUT_UPDATE_UNIT_TEMPLATES_ERROR,
  payload: error,
});

export const getUnitTemplatesByIdRequest = (body: any) => ({
  type: GET_UNIT_TEMPLATES_BY_ID_REQUEST,
  payload: body,
});
export const getUnitTemplatesByIdSuccess = (data: any) => ({
  type: GET_UNIT_TEMPLATES_BY_ID_SUCCESS,
  payload: data,
});
export const getUnitTemplatesByIdError = (error: any) => ({
  type: GET_UNIT_TEMPLATES_BY_ID_ERROR,
  payload: error,
});

export const deleteUnitTemplatesByIdRequest = (body: any) => ({
  type: DELETE_UNIT_TEMPLATES_BY_ID_REQUEST,
  payload: body,
});
export const deleteUnitTemplatesByIdSuccess = (data: any) => ({
  type: DELETE_UNIT_TEMPLATES_BY_ID_SUCCESS,
  payload: data,
});
export const deleteUnitTemplatesByIdError = (error: any) => ({
  type: DELETE_UNIT_TEMPLATES_BY_ID_ERROR,
  payload: error,
});

export const postUpdatePhotoUnitTemplatesRequest = (body: any) => ({
  type: POST_UPDATE_PHOTO_UNIT_TEMPLATES_REQUEST,
  payload: body,
});
export const postUpdatePhotoUnitTemplatesSuccess = (data: any) => ({
  type: POST_UPDATE_PHOTO_UNIT_TEMPLATES_SUCCESS,
  payload: data,
});
export const postUpdatePhotoUnitTemplatesError = (error: any) => ({
  type: POST_UPDATE_PHOTO_UNIT_TEMPLATES_ERROR,
  payload: error,
});

export const getPhotoUnitRequest = (body: any) => ({
  type: GET_PHOTO_UNIT_REQUEST,
  payload: body,
});
export const getPhotoUnitSuccess = (data: any) => ({
  type: GET_PHOTO_UNIT_SUCCESS,
  payload: data,
});
export const getPhotoUnitError = (error: any) => ({
  type: GET_PHOTO_UNIT_ERROR,
  payload: error,
});

export const clearPhotoUnit = () => ({
  type: CLEAR_PHOTO_UNIT,
});

export const closeErrorPopUpUnit = () => ({
  type: CLOSE_ERROR_POPUP_UNIT,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpUnit>
  | ReturnType<typeof clearPhotoUnit>
  | ReturnType<typeof getPhotoUnitRequest>
  | ReturnType<typeof getPhotoUnitSuccess>
  | ReturnType<typeof getPhotoUnitError>
  | ReturnType<typeof postUpdatePhotoUnitTemplatesRequest>
  | ReturnType<typeof postUpdatePhotoUnitTemplatesSuccess>
  | ReturnType<typeof postUpdatePhotoUnitTemplatesError>
  | ReturnType<typeof deleteUnitTemplatesByIdRequest>
  | ReturnType<typeof deleteUnitTemplatesByIdSuccess>
  | ReturnType<typeof deleteUnitTemplatesByIdError>
  | ReturnType<typeof getUnitTemplatesByIdRequest>
  | ReturnType<typeof getUnitTemplatesByIdSuccess>
  | ReturnType<typeof getUnitTemplatesByIdError>
  | ReturnType<typeof putUpdateUnitTemplatesRequest>
  | ReturnType<typeof putUpdateUnitTemplatesSuccess>
  | ReturnType<typeof putUpdateUnitTemplatesError>
  | ReturnType<typeof postCreateUnitTemplatesRequest>
  | ReturnType<typeof postCreateUnitTemplatesSuccess>
  | ReturnType<typeof postCreateUnitTemplatesError>
  | ReturnType<typeof getUnitTemplatesRequest>
  | ReturnType<typeof getUnitTemplatesSuccess>
  | ReturnType<typeof getUnitTemplatesError>;
