import MenuIcon from '@mui/icons-material/Menu';
import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import * as React from 'react';
import { style } from './style';

import styles from './WithdrawalTable.module.scss';
import useModal from '../../../../shared/hooks/useModal/useModal';
import AddWithdrawalModal from '../AddWithdrawalModal';

interface Props {
  data: any;
}

export const WithdrawalTable: React.FC<Props> = ({ data }) => {
  const [pageSize, setPageSize] = React.useState(16);
  const [withdrawalReason, setWithdrawalReason] = React.useState<any>(null);
  const { isShow, openModal, closeModal } = useModal();

  return (
    <>
      <div style={{ height: 658, width: '100%', marginTop: '21px' }}>
        <DataGrid
          localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
          rows={data}
          headerHeight={43}
          rowHeight={35}
          disableSelectionOnClick
          columns={[
            { field: 'id', headerName: 'ID', width: 70 },
            { field: 'name', headerName: 'Причина вилучення', minWidth: 450, flex: 1 },
            {
              field: 'button',
              headerName: 'Меню',
              headerAlign: 'center',
              width: 60,
              align: 'center',
              renderCell: (params: GridRenderCellParams<Date>) => (
                <MenuIcon
                  className={styles.icon}
                  onClick={() => {
                    setWithdrawalReason(params.row);
                    openModal();
                  }}
                />
              ),
            },
          ]}
          sx={style}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          disableColumnMenu
          componentsProps={{
            pagination: {
              SelectProps: {
                MenuProps: {
                  sx: {
                    '& .MuiMenuItem-root': {
                      fontSize: 14,
                      bgcolor: '#ffffff',
                    },
                  },
                  PaperProps: {
                    sx: {
                      bgcolor: '#ffffff',
                    },
                  },
                },
              },
            },
          }}
        />
      </div>

      {withdrawalReason && (
        <AddWithdrawalModal
          withdrawalReason={withdrawalReason}
          isShown={isShow}
          closeModal={() => {
            closeModal();
            setWithdrawalReason(null);
          }}
          type="edit"
        />
      )}
    </>
  );
};
