import { Actions } from './actions';
import {
  CLOSE_ERROR_MODAL,
  GET_EXTERNAL_STATUSES_ERROR,
  GET_EXTERNAL_STATUSES_REQUEST,
  GET_EXTERNAL_STATUSES_SUCCESS,
  POST_IMPORT_FILE_ERROR,
  POST_IMPORT_FILE_REQUEST,
  POST_IMPORT_FILE_SUCCESS,
  POST_VALIDATE_FILE_ERROR,
  POST_VALIDATE_FILE_REQUEST,
  POST_VALIDATE_FILE_SUCCESS,
  POST_GENERATE_CODES_SUCCESS,
  POST_GENERATE_CODES_REQUEST,
  POST_GENERATE_CODES_ERROR,
} from './constants';
import { ErrorResponse, ExternalStatusesBody, ValidateFileBody } from '../../../../entities/ImportCodes/interfaces';

const initialState = {
  loading: false as boolean,
  error: null as Error | null,
  statuses: [] as ExternalStatusesBody[],

  validateFileResponse: {} as ValidateFileBody,
  validateFileErrorResponse: {} as ErrorResponse,

  getStatusesErrorResponse: {} as any,

  generateCodesStartLoading: false as boolean,
  generateCodesResponse: {} as any,
  generateCodesErrorResponse: {} as ErrorResponse,

  applyFileResponse: {} as any,
  applyFileErrorResponse: {} as any,
};

export type ProvidersState = typeof initialState;

export const ImportCodesReducer = (state: ProvidersState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_EXTERNAL_STATUSES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_EXTERNAL_STATUSES_SUCCESS: {
      return {
        ...state,
        statuses: action.payload,
        loading: false,
      };
    }
    case GET_EXTERNAL_STATUSES_ERROR: {
      return {
        ...state,
        loading: false,
        getStatusesErrorResponse: action.payload,
      };
    }
    case POST_VALIDATE_FILE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_VALIDATE_FILE_SUCCESS: {
      return {
        ...state,
        validateFileResponse: action.payload,
        loading: false,
      };
    }
    case POST_VALIDATE_FILE_ERROR: {
      return {
        ...state,
        validateFileErrorResponse: action.payload,
        loading: false,
      };
    }
    case POST_IMPORT_FILE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_IMPORT_FILE_SUCCESS: {
      return {
        ...state,
        applyFileResponse: action.payload,
        loading: false,
      };
    }
    case POST_IMPORT_FILE_ERROR: {
      return {
        ...state,
        applyFileErrorResponse: action.payload,
        loading: false,
      };
    }

    case POST_GENERATE_CODES_REQUEST: {
      return {
        ...state,
        generateCodesResponse: {},
        generateCodesStartLoading: true,
      };
    }
    case POST_GENERATE_CODES_SUCCESS: {
      return {
        ...state,
        generateCodesResponse: action.payload,
        generateCodesStartLoading: false,
      };
    }
    case POST_GENERATE_CODES_ERROR: {
      return {
        ...state,
        generateCodesErrorResponse: action.payload,
        generateCodesStartLoading: false,
      };
    }

    case CLOSE_ERROR_MODAL: {
      return {
        ...state,
        validateFileErrorResponse: {},
        applyFileErrorResponse: {},
        getStatusesErrorResponse: {},
        generateCodesResponse: {},
      };
    }
    default: {
      return state;
    }
  }
};
