import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  GET_SERIES_REPORT_REQUEST,
  GET_EXCEL_FILE_SERIES_REQUEST,
  POST_UNRESERVE_MARKING_CODES_SUCCESS,
} from './constants';
import {
  getSeriesReportError,
  getSeriesReportRequest,
  getSeriesReportSuccess,
  getExcelFileSeriesError,
  getExcelFileSeriesRequest,
  getExcelFileSeriesSuccess,
  postUnreserveMarkingCodesError,
  postUnreserveMarkingCodesRequest,
  postUnreserveMarkingCodesSuccess,
} from './actions';
import { Reports } from '../../../../entities/Reports/service';

function* getSeriesReportSaga(action: ReturnType<typeof getSeriesReportRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.getSeriesReport, action.payload);
    const seriesReport = response.data.ResponseBody;
    yield put(getSeriesReportSuccess(seriesReport));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getSeriesReportError(error));
  }
}

function* getExcelFileSeriesRequestSaga(action: ReturnType<typeof getExcelFileSeriesRequest>) {
  try {
    yield call(Reports.getExcelSeriesReport, action.payload);
    yield put(getExcelFileSeriesSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getExcelFileSeriesError(error));
  }
}

function* postUnreserveMarkingCodesRequestSaga(action: ReturnType<typeof postUnreserveMarkingCodesRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.unreserveMarkingCodes, action.payload);
    const res = response.data.UserText;
    yield put(postUnreserveMarkingCodesSuccess(res));
    yield delay(5000);
    yield put(postUnreserveMarkingCodesSuccess(''));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postUnreserveMarkingCodesError(error));
  }
}

export function* SeriesReportSagaWatcher() {
  yield takeLatest(GET_SERIES_REPORT_REQUEST, getSeriesReportSaga);
  yield takeLatest(GET_EXCEL_FILE_SERIES_REQUEST, getExcelFileSeriesRequestSaga);
  yield takeLatest(POST_UNRESERVE_MARKING_CODES_SUCCESS, postUnreserveMarkingCodesRequestSaga);
}
