import { AccountInfo } from '@azure/msal-browser';
import { SET_ACCOUNT, GET_ACCOUNT } from './constants';

export const setAccount = (account: AccountInfo | null) => ({
  type: SET_ACCOUNT,
  payload: { account },
});

export const getAccount = () => ({
  type: GET_ACCOUNT,
});

export type Actions = ReturnType<typeof getAccount> | ReturnType<typeof setAccount>;
