import {
  CLEAR_PHOTO_BOX,
  CLOSE_ERROR_POPUP_BOX,
  DELETE_BOX_TEMPLATES_BY_ID_ERROR,
  DELETE_BOX_TEMPLATES_BY_ID_REQUEST,
  DELETE_BOX_TEMPLATES_BY_ID_SUCCESS,
  GET_BOX_TEMPLATES_BY_ID_ERROR,
  GET_BOX_TEMPLATES_BY_ID_REQUEST,
  GET_BOX_TEMPLATES_BY_ID_SUCCESS,
  GET_BOX_TEMPLATES_ERROR,
  GET_BOX_TEMPLATES_REQUEST,
  GET_BOX_TEMPLATES_SUCCESS,
  GET_PHOTO_BOX_ERROR,
  GET_PHOTO_BOX_REQUEST,
  GET_PHOTO_BOX_SUCCESS,
  POST_CREATE_BOX_TEMPLATES_ERROR,
  POST_CREATE_BOX_TEMPLATES_REQUEST,
  POST_CREATE_BOX_TEMPLATES_SUCCESS,
  POST_UPDATE_PHOTO_BOX_TEMPLATES_ERROR,
  POST_UPDATE_PHOTO_BOX_TEMPLATES_REQUEST,
  POST_UPDATE_PHOTO_BOX_TEMPLATES_SUCCESS,
  PUT_UPDATE_BOX_TEMPLATES_ERROR,
  PUT_UPDATE_BOX_TEMPLATES_REQUEST,
  PUT_UPDATE_BOX_TEMPLATES_SUCCESS,
} from './constants';

export const getBoxTemplatesRequest = (body: any) => ({
  type: GET_BOX_TEMPLATES_REQUEST,
  payload: body,
});
export const getBoxTemplatesSuccess = (data: any) => ({
  type: GET_BOX_TEMPLATES_SUCCESS,
  payload: data,
});
export const getBoxTemplatesError = (error: any) => ({
  type: GET_BOX_TEMPLATES_ERROR,
  payload: error,
});

export const postCreateBoxTemplatesRequest = () => ({
  type: POST_CREATE_BOX_TEMPLATES_REQUEST,
});
export const postCreateBoxTemplatesSuccess = (data: any) => ({
  type: POST_CREATE_BOX_TEMPLATES_SUCCESS,
  payload: data,
});
export const postCreateBoxTemplatesError = (error: any) => ({
  type: POST_CREATE_BOX_TEMPLATES_ERROR,
  payload: error,
});

export const putUpdateBoxTemplatesRequest = (data: any) => ({
  type: PUT_UPDATE_BOX_TEMPLATES_REQUEST,
  payload: data,
});
export const putUpdateBoxTemplatesSuccess = (data: any) => ({
  type: PUT_UPDATE_BOX_TEMPLATES_SUCCESS,
  payload: data,
});
export const putUpdateBoxTemplatesError = (error: any) => ({
  type: PUT_UPDATE_BOX_TEMPLATES_ERROR,
  payload: error,
});

export const getBoxTemplatesByIdRequest = (body: any) => ({
  type: GET_BOX_TEMPLATES_BY_ID_REQUEST,
  payload: body,
});
export const getBoxTemplatesByIdSuccess = (data: any) => ({
  type: GET_BOX_TEMPLATES_BY_ID_SUCCESS,
  payload: data,
});
export const getBoxTemplatesByIdError = (error: any) => ({
  type: GET_BOX_TEMPLATES_BY_ID_ERROR,
  payload: error,
});

export const deleteBoxTemplatesByIdRequest = (body: any) => ({
  type: DELETE_BOX_TEMPLATES_BY_ID_REQUEST,
  payload: body,
});
export const deleteBoxTemplatesByIdSuccess = (data: any) => ({
  type: DELETE_BOX_TEMPLATES_BY_ID_SUCCESS,
  payload: data,
});
export const deleteBoxTemplatesByIdError = (error: any) => ({
  type: DELETE_BOX_TEMPLATES_BY_ID_ERROR,
  payload: error,
});

export const postUpdatePhotoBoxTemplatesRequest = (body: any) => ({
  type: POST_UPDATE_PHOTO_BOX_TEMPLATES_REQUEST,
  payload: body,
});
export const postUpdatePhotoBoxTemplatesSuccess = (data: any) => ({
  type: POST_UPDATE_PHOTO_BOX_TEMPLATES_SUCCESS,
  payload: data,
});
export const postUpdatePhotoBoxTemplatesError = (error: any) => ({
  type: POST_UPDATE_PHOTO_BOX_TEMPLATES_ERROR,
  payload: error,
});

export const getPhotoBoxRequest = (body: any) => ({
  type: GET_PHOTO_BOX_REQUEST,
  payload: body,
});
export const getPhotoBoxSuccess = (data: any) => ({
  type: GET_PHOTO_BOX_SUCCESS,
  payload: data,
});
export const getPhotoBoxError = (error: any) => ({
  type: GET_PHOTO_BOX_ERROR,
  payload: error,
});

export const clearPhotoBox = () => ({
  type: CLEAR_PHOTO_BOX,
});

export const closeErrorPopUpBox = () => ({
  type: CLOSE_ERROR_POPUP_BOX,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpBox>
  | ReturnType<typeof postUpdatePhotoBoxTemplatesError>
  | ReturnType<typeof postUpdatePhotoBoxTemplatesSuccess>
  | ReturnType<typeof postUpdatePhotoBoxTemplatesRequest>
  | ReturnType<typeof getPhotoBoxRequest>
  | ReturnType<typeof getPhotoBoxSuccess>
  | ReturnType<typeof getPhotoBoxError>
  | ReturnType<typeof clearPhotoBox>
  | ReturnType<typeof deleteBoxTemplatesByIdRequest>
  | ReturnType<typeof deleteBoxTemplatesByIdSuccess>
  | ReturnType<typeof deleteBoxTemplatesByIdError>
  | ReturnType<typeof getBoxTemplatesByIdRequest>
  | ReturnType<typeof getBoxTemplatesByIdSuccess>
  | ReturnType<typeof getBoxTemplatesByIdError>
  | ReturnType<typeof putUpdateBoxTemplatesRequest>
  | ReturnType<typeof putUpdateBoxTemplatesSuccess>
  | ReturnType<typeof putUpdateBoxTemplatesError>
  | ReturnType<typeof postCreateBoxTemplatesRequest>
  | ReturnType<typeof postCreateBoxTemplatesSuccess>
  | ReturnType<typeof postCreateBoxTemplatesError>
  | ReturnType<typeof getBoxTemplatesRequest>
  | ReturnType<typeof getBoxTemplatesSuccess>
  | ReturnType<typeof getBoxTemplatesError>;
