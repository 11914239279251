import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import './App.css';
import AvailableCodes from './modules/Bank/AvailableCodes';
import ImportCodes from './modules/Bank/ImportCodes';
import ExportLists from './modules/Documents/ExportLists';
import EntryEditor from './modules/Documents/ExportLists/EntryEditor';
import { BackFlowProcesses } from './modules/EventsLog/BackFlowProcesses/BackFlowProcesses';
import CodesReport from './modules/EventsLog/CodesReport';
import AuditTrail from './modules/EventsLog/AuditTrail';
import Withdrawal from './modules/EventsLog/Withdrawal';
import SeriesReport from './modules/EventsLog/SeriesReport';
import { LicensedDevices } from './modules/EventsLog/LicensedDevices/LicensedDevices';
import Packaging from './modules/Handbook/Packaging';
import Products from './modules/Handbook/Products';
import ProductsList from './modules/Handbook/Products/ProductsList';
import Main from './modules/Main';
import PrintCodes from './modules/OperationsAndTools/PrintCodes';
import SearchCodes from './modules/OperationsAndTools/SearchCodes';
import Layout from './modules/shared/Layout';
import BoxTemplates from './modules/Templates/BoxTemplates';
import PalletTemplates from './modules/Templates/PalletTemplates';
import UnitTemplates from './modules/Templates/UnitTemplates';
import InventoryList from './modules/Documents/InventoryList';
import ImportReport from './modules/EventsLog/ImportReport';
import Withdrawals from './modules/Handbook/Withdrawal';
// import { Auth } from './modules/Auth/Auth';
import { Login } from './modules/Login/Login';
import ErrorPage from './modules/shared/ErrorPage';
import Shipment from './modules/EventsLog/Shipment';
import { Settings } from './modules/About/Settings/Settings';
import { Contacts } from './modules/About/Contacts/Contacts';
import Page451 from './modules/shared/Page451';
import Receipts from './modules/Bank/Receipts';
import InvoiceReport from './modules/EventsLog/InvoiceReport';

const App: React.FC = () => {
  return (
    <div className="App">
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Layout>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/main" element={<Main />} />
            <Route path="/available" element={<AvailableCodes />} />
            <Route path="/import" element={<ImportCodes />} />
            <Route path="/products" element={<ProductsList />} />
            <Route path="/product" element={<Products />} />
            <Route path="/packaging" element={<Packaging />} />
            <Route path="/box_templates" element={<BoxTemplates />} />
            <Route path="/unit_templates" element={<UnitTemplates />} />
            <Route path="/pallet_templates" element={<PalletTemplates />} />
            <Route path="/printCodes" element={<PrintCodes />} />
            <Route path="/searchCodes" element={<SearchCodes />} />
            <Route path="/importCodesReport" element={<ImportReport />} />
            <Route path="/codes_report" element={<CodesReport />} />
            <Route path="/series_report" element={<SeriesReport />} />
            <Route path="/audittrail_report" element={<AuditTrail />} />
            <Route path="/withdrawal" element={<Withdrawal />} />
            <Route path="/export_lists" element={<ExportLists />} />
            <Route path="/inventory" element={<InventoryList />} />
            <Route path="/export_lists/entry" element={<EntryEditor />} />
            <Route path="/shipment" element={<Shipment />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/restriction" element={<Page451 />} />
            <Route path="/tree" element={<SearchCodes />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/licensedDevices" element={<LicensedDevices />} />
            <Route path="/processes" element={<BackFlowProcesses />} />
            <Route path="/receipts" element={<Receipts />} />
            <Route path="/withdrawals" element={<Withdrawals />} />
            <Route path="/invoices_report" element={<InvoiceReport />} />
          </Routes>
        </Layout>
        <span className="version">
          Sytecs LLC ® GATEWAY Portal, ver.: {process.env.REACT_APP_VERSION_DATA}. Account:{' '}
          {localStorage.getItem('account')}.
        </span>
      </AuthenticatedTemplate>
    </div>
  );
};

export default App;
