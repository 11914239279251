export const GET_ALL_SHIPPING_ORDERS_REQUEST = 'GET_ALL_SHIPPING_ORDERS_REQUEST' as const;
export const GET_ALL_SHIPPING_ORDERS_SUCCESS = 'GET_ALL_SHIPPING_ORDERS_SUCCESS' as const;
export const GET_ALL_SHIPPING_ORDERS_ERROR = 'GET_ALL_SHIPPING_ORDERS_ERROR' as const;

export const POST_CREATE_SHIPPING_ORDER_REQUEST = 'POST_CREATE_SHIPPING_ORDER_REQUEST' as const;
export const POST_CREATE_SHIPPING_ORDER_SUCCESS = 'POST_CREATE_SHIPPING_ORDER_SUCCESS' as const;
export const POST_CREATE_SHIPPING_ORDER_ERROR = 'POST_CREATE_SHIPPING_ORDER_ERROR' as const;

export const GET_SHIPPING_ORDER_BY_ID_REQUEST = 'GET_SHIPPING_ORDER_BY_ID_REQUEST' as const;
export const GET_SHIPPING_ORDER_BY_ID_SUCCESS = 'GET_SHIPPING_ORDER_BY_ID_SUCCESS' as const;
export const GET_SHIPPING_ORDER_BY_ID_ERROR = 'GET_SHIPPING_ORDER_BY_ID_ERROR' as const;

export const GET_SHIPPING_ORDER_BY_DATE_REQUEST = 'GET_SHIPPING_ORDER_BY_DATE_REQUEST' as const;
export const GET_SHIPPING_ORDER_BY_DATE_SUCCESS = 'GET_SHIPPING_ORDER_BY_DATE_SUCCESS' as const;
export const GET_SHIPPING_ORDER_BY_DATE_ERROR = 'GET_SHIPPING_ORDER_BY_DATE_ERROR' as const;

export const PUT_UPDATE_SHIPPING_ORDER_REQUEST = 'PUT_UPDATE_SHIPPING_ORDER_REQUEST' as const;
export const PUT_UPDATE_SHIPPING_ORDER_SUCCESS = 'PUT_UPDATE_SHIPPING_ORDER_SUCCESS' as const;
export const PUT_UPDATE_SHIPPING_ORDER_ERROR = 'PUT_UPDATE_SHIPPING_ORDER_ERROR' as const;

export const POST_CHECK_SERIES_REQUEST = 'POST_CHECK_SERIES_REQUEST' as const;
export const POST_CHECK_SERIES_SUCCESS = 'POST_CHECK_SERIES_SUCCESS' as const;
export const POST_CHECK_SERIES_ERROR = 'POST_CHECK_SERIES_ERROR' as const;

export const POST_CHECK_PRODUCT_REQUEST = 'POST_CHECK_PRODUCT_REQUEST' as const;
export const POST_CHECK_PRODUCT_SUCCESS = 'POST_CHECK_PRODUCT_SUCCESS' as const;
export const POST_CHECK_PRODUCT_ERROR = 'POST_CHECK_PRODUCT_ERROR' as const;

export const DELETE_SHIPPING_ORDER_BY_ID_REQUEST = 'DELETE_SHIPPING_ORDER_BY_ID_REQUEST' as const;
export const DELETE_SHIPPING_ORDER_BY_ID_SUCCESS = 'DELETE_SHIPPING_ORDER_BY_ID_SUCCESS' as const;
export const DELETE_SHIPPING_ORDER_BY_ID_ERROR = 'DELETE_SHIPPING_ORDER_BY_ID_ERROR' as const;

export const DELETE_FILTER_REQUEST = 'DELETE_FILTER_REQUEST' as const;
export const DELETE_FILTER_SUCCESS = 'DELETE_FILTER_SUCCESS' as const;
export const DELETE_FILTER_ERROR = 'DELETE_FILTER_ERROR' as const;

export const DELETE_PRODUCT_FROM_DOCUMENT_REQUEST = 'DELETE_PRODUCT_FROM_DOCUMENT_REQUEST' as const;
export const DELETE_PRODUCT_FROM_DOCUMENT_SUCCESS = 'DELETE_PRODUCT_FROM_DOCUMENT_SUCCESS' as const;
export const DELETE_PRODUCT_FROM_DOCUMENT_ERROR = 'DELETE_PRODUCT_FROM_DOCUMENT_ERROR' as const;

export const POST_UPDATE_ICON_SERIES_REQUEST = 'POST_UPDATE_ICON_SERIES_REQUEST' as const;
export const POST_UPDATE_ICON_SERIES_SUCCESS = 'POST_UPDATE_ICON_SERIES_SUCCESS' as const;
export const POST_UPDATE_ICON_SERIES_ERROR = 'POST_UPDATE_ICON_SERIES_ERROR' as const;

export const CLOSE_ERROR_POPUP_SHIPPING = 'CLOSE_ERROR_POPUP_SHIPPING' as const;

export const SET_ERROR_MESSAGE_SHIPPINGS = 'SET_ERROR_MESSAGE_SHIPPINGS' as const;

export const DOWNLOAD_SHIPPING_ORDER_REQUEST = 'DOWNLOAD_SHIPPING_ORDER_REQUEST' as const;
export const DOWNLOAD_SHIPPING_ORDER_SUCCESS = 'DOWNLOAD_SHIPPING_ORDER_SUCCESS' as const;
export const DOWNLOAD_SHIPPING_ORDER_ERROR = 'DOWNLOAD_SHIPPING_ORDER_ERROR' as const;

export const DOWNLOAD_AGGREGATION_REPORT_REQUEST = 'DOWNLOAD_AGGREGATION_REPORT_REQUEST' as const;
export const DOWNLOAD_AGGREGATION_REPORT_SUCCESS = 'DOWNLOAD_AGGREGATION_REPORT_SUCCESS' as const;
export const DOWNLOAD_AGGREGATION_REPORT_ERROR = 'DOWNLOAD_AGGREGATION_REPORT_ERROR' as const;
