import { AxiosResponse } from 'axios';
import { instance as axios } from 'api/axios';

export class Withdrawal {
  public static async getAllWithdrawals() {
    const url = '/Withdrawal/Reason/GetAll';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async addNewWithdrawal(body: any) {
    const url = '/Withdrawal/Reason/Create';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async updateWithdrawal(body: any) {
    const url = '/Withdrawal/Reason/Update';
    const response: AxiosResponse = await axios.put(url, body);
    return response;
  }

  public static async deleteWithdrawal(body: any) {
    const url = '/Withdrawal/Reason/Delete';
    const response: AxiosResponse = await axios.delete(url, { data: body });
    return response;
  }
}
