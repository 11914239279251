import { AxiosResponse } from 'axios';
import { instance as axios } from 'api/axios';

export class PrintCodes {
  public static async getAllPrintCodes() {
    const url = '/PrintJob/GetAll';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async cancelPrintJob(body: any) {
    const url = '/PrintJob/Cancel';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async printCodesDetailed(body: any) {
    const url = '/PrintJob/GetDetailed';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async filterPrintJob(body: any) {
    const url = '/PrintJob/GetAllByDates';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async unreserveMarkingCodes(body: any) {
    const url = '/PrintJob/UnreserveMarkingCodes';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }
}
