import DownloadIcon from '@mui/icons-material/Download';
import styles from './CodesReport.module.scss';
import { Spinner } from '../../common/Spinner/Spinner';
import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import moment from 'moment';
import InputSearch from '../../common/InputSearch';
import { calendarStyleEnabled, calendarStyleDisabled, selectStyle } from './muiStyles/muiStyles';
import AgregationReportTable from '../../common/AgregationReportTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAggregationReportRequest,
  getAggregationReportSuccess,
  getGlobalReportRequest,
  putUpdateStatusSuccess,
} from './services/actions';
import { aggregationReportSelector } from './services/selector';
import ErrorResponse from '../../shared/ErrorResponse';
import { useNavigate } from 'react-router-dom';
import { disabledInputStyle, inputStyle } from './services/style';
import ArrowBackIcon from '../../../icons/arrow_back.svg';
import ArrowForwardIcon from '../../../icons/arrow_forward.svg';
import 'moment/locale/uk';

export const CodesReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectValue, setSelectValue] = useState<any>(undefined);
  const [viewType, setViewType] = useState<number>(1);
  const [seriesValue, setSeriesValue] = useState<any>('');
  const [selectReportTypeValue, setReportTypeValue] = useState<any>(1);

  const [calendarDate, setCalendarDate] = useState<any>(new Date());

  const handleMonthChange = (increment: number) => {
    const newDate = new Date(calendarDate);
    newDate.setMonth(newDate.getMonth() + increment);
    setCalendarDate(newDate);
  };

  const changeDateCalendar = (calendarValue: any) => {
    const newDate = new Date(calendarValue);
    newDate.setMonth(newDate.getMonth());
    setCalendarDate(newDate);
  };

  const { loading, aggregationReportResponse, aggregationReportErrorResponses } =
    useSelector(aggregationReportSelector);

  const [tableData, setTableData] = useState<any[]>(aggregationReportResponse);

  const showHandler = () => {
    dispatch(putUpdateStatusSuccess([]));
    if (viewType === 1) {
      dispatch(
        getAggregationReportRequest({
          fromDate: moment(calendarDate).format('YYYY-MM-01'),
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
          level: selectValue,
        }),
      );
    } else {
      dispatch(getAggregationReportRequest({ Series: seriesValue, level: selectValue }));
    }
  };

  useEffect(() => {
    if (Object.keys(aggregationReportErrorResponses).length !== 0 && aggregationReportErrorResponses?.status === 403) {
      navigate('/error');
    }
  }, [aggregationReportErrorResponses, navigate]);

  const onExelDownload = () => {
    const ssccs: any = [];
    if (selectValue === 2) {
      tableData.map((item: any) => ssccs.push(item.sscc));
    } else {
      tableData.map((item: any) => ssccs.push(item.palletSscc));
    }
    dispatch(getGlobalReportRequest({ SSCCs: ssccs, level: selectValue, reportType: selectReportTypeValue }));
  };

  return (
    <div className={styles.wrapper}>
      {Object.keys(aggregationReportErrorResponses).length !== 0 ? (
        <ErrorResponse error={aggregationReportErrorResponses} />
      ) : null}
      <div className={styles.loadingGroup}>
        <span className={styles.title}>Звіт з виробництва</span>
        {loading ? <Spinner /> : null}
      </div>
      <span>Перегляд виготовлених невідвантажених агрегатів рівня Level 2 (ящик) та Level 3 (палета)</span>
      <div className={styles.filterBlock}>
        <div className={styles.firstRow}>
          <div className={styles.calendarItem}>
            <span>Пошук по:</span>
            <Select
              sx={selectStyle}
              onChange={(e: SelectChangeEvent<any>) => {
                setViewType(e.target.value);
                setSeriesValue('');
                dispatch(getAggregationReportSuccess([]));
              }}
              value={viewType}
            >
              <MenuItem sx={{ fontSize: '13px' }} value={1}>
                Дата пакування
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px' }} value={2}>
                Серія
              </MenuItem>
            </Select>
          </div>
          <div className={styles.calendarItem}>
            <span>Дані за пeріод:</span>
            <div className={styles.calendarRow}>
              <LocalizationProvider dateAdapter={AdapterMoment} locale={'uk'}>
                <DatePicker
                  inputFormat="MM/YYYY"
                  value={calendarDate}
                  views={['year', 'month']}
                  onChange={changeDateCalendar}
                  disabled={viewType !== 1}
                  renderInput={(params) => (
                    <TextField sx={viewType !== 1 ? calendarStyleDisabled : calendarStyleEnabled} {...params} />
                  )}
                  PaperProps={{
                    sx: {
                      '.MuiPickersCalendarHeader-label': {
                        fontSize: '13px',
                      },
                      '& .PrivatePickersYear-yearButton': {
                        fontSize: '13px',
                      },
                      '& .MuiTypography-root': {
                        fontSize: '13px',
                      },
                    },
                  }}
                />
                <Tooltip title="Місяць назад" placement="top">
                  <img
                    src={ArrowBackIcon}
                    onClick={() => (viewType !== 1 ? () => undefined : handleMonthChange(-1))}
                    alt=""
                  />
                </Tooltip>
                <Tooltip title="Місяць вперед" placement="top">
                  <img
                    src={ArrowForwardIcon}
                    onClick={() => (viewType !== 1 ? () => undefined : handleMonthChange(1))}
                    alt=""
                  />
                </Tooltip>
              </LocalizationProvider>
            </div>
          </div>
          <div className={styles.calendarItem}>
            <span>Серія:</span>
            <OutlinedInput
              name="Series"
              value={seriesValue}
              onChange={(event) => {
                setSeriesValue(event.target.value);
                dispatch(getAggregationReportSuccess([]));
              }}
              sx={viewType === 1 ? disabledInputStyle : inputStyle}
              disabled={viewType === 1}
              inputProps={{ maxLength: 20 }}
            />
          </div>
          <div className={styles.calendarItem}>
            <span>Відобразити список як:</span>
            <Select
              sx={selectStyle}
              onChange={(e: SelectChangeEvent<any>) => {
                setSelectValue(e.target.value);
                dispatch(getAggregationReportSuccess([]));
              }}
              value={selectValue}
            >
              <MenuItem sx={{ fontSize: '13px' }} value={2}>
                Ящики (Level 2)
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px' }} value={3}>
                Палети (Level 3)
              </MenuItem>
            </Select>
          </div>
          <Button
            variant="contained"
            className={styles.btn}
            onClick={showHandler}
            disabled={selectValue === undefined || (viewType > 1 && seriesValue.trim().length === 0)}
          >
            <span className={styles.btnText}>Пошук</span>
          </Button>
        </div>
        <div className={styles.secondRow}>
          <div className={styles.searchGroup}>
            <span>Фільтр по всім полям:</span>
            <InputSearch setTableData={setTableData} data={aggregationReportResponse} clearOnPropsChange={false} />
          </div>
          <div className={styles.searchGroup}>
            <span>Скачати як звіт в форматі:</span>
            <Select
              sx={selectStyle}
              onChange={(e: SelectChangeEvent<any>) => {
                setReportTypeValue(e.target.value);
              }}
              value={selectReportTypeValue}
            >
              <MenuItem sx={{ fontSize: '13px' }} value={1}>
                Таблиця Excel
              </MenuItem>
            </Select>
          </div>
          {tableData.length > 0 && selectValue !== undefined ? (
            <div className={styles.downloadWrapper}>
              {!loading ? (
                <a onClick={onExelDownload}>
                  <DownloadIcon className={styles.downloadIcon} />
                </a>
              ) : (
                <DownloadIcon className={styles.downloadIcon} />
              )}
            </div>
          ) : null}
        </div>
      </div>
      {selectValue !== undefined ? (
        <AgregationReportTable data={tableData} select={selectValue} updateTable={showHandler} />
      ) : null}
    </div>
  );
};
