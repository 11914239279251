import { Box, Button, Modal } from '@mui/material';
import React from 'react';
import styles from './DeleteElementModal.module.scss';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
  fontSize: '13px !important',
};

interface Props {
  title: string;
  isShow: boolean;
  closeModal: () => void;
  deleteHandler: () => void;
}

export const DeleteElementModal: React.FC<Props> = ({ isShow, title, closeModal, deleteHandler }) => {
  return (
    <Modal
      open={isShow}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <span className={styles.title}>{title}</span>
        <div className={styles.btnGroup}>
          <Button variant="contained" className={styles.btn} onClick={closeModal}>
            Ні
          </Button>
          <Button variant="contained" className={styles.btn} onClick={deleteHandler}>
            Так
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
