import { Actions } from './actions';
import {
  GET_SHIPMENT_REPORT_REQUEST,
  GET_SHIPMENT_REPORT_SUCCESS,
  GET_SHIPMENT_REPORT_ERROR,
  GET_EXCEL_FILE_TABLE_REQUEST,
  GET_EXCEL_FILE_TABLE_SUCCESS,
  GET_EXCEL_FILE_TABLE_ERROR,
  GET_GLOBAL_SHIPP_REPORT_REQUEST,
  GET_GLOBAL_SHIPP_REPORT_SUCCESS,
  GET_GLOBAL_SHIPP_REPORT_ERROR,
  CLOSE_ERROR_POPUP_SHIPP_REPORT,
} from './constants';

const initialState = {
  loading: false as boolean,
  error: null as Error | null,

  shipmentReportResponse: [] as any,
  shipmentReportErrorResponses: {} as any,
};

export type ShipmentReportState = typeof initialState;

export const ShipmentReportReducer = (state: ShipmentReportState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_SHIPMENT_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        shipmentReportResponse: [],
        shipmentReportErrorResponses: {},
      };
    }
    case GET_SHIPMENT_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        shipmentReportResponse: action.payload,
      };
    }
    case GET_SHIPMENT_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        shipmentReportErrorResponses: action.payload,
      };
    }
    case GET_EXCEL_FILE_TABLE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_EXCEL_FILE_TABLE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_EXCEL_FILE_TABLE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_GLOBAL_SHIPP_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        shipmentReportErrorResponses: {},
      };
    }
    case GET_GLOBAL_SHIPP_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_GLOBAL_SHIPP_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        shipmentReportErrorResponses: action.payload,
      };
    }

    case CLOSE_ERROR_POPUP_SHIPP_REPORT: {
      return {
        ...state,
        loading: false,
        shipmentReportErrorResponses: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
