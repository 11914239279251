import { OutlinedInput } from '@mui/material';
import styles from './Contacts.module.scss';
import React from 'react';

const largeInputStyle = {
  minWidth: 400,
  height: 30,
  fontSize: '13px !important',
  bgcolor: '#f0eeee',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '.MuiInputBase-root': {
    fontSize: '13px !important',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

export const Contacts = () => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.header}>Контакти служби підтримки</span>
      <section className={styles.main}>
        <div className={styles.largeInputElement}>
          <span className={styles.title}>Version: </span>
          <OutlinedInput
            defaultValue=""
            autoComplete="off"
            sx={largeInputStyle}
            value={process.env.REACT_APP_VERSION_DATA}
          />
        </div>
        <div className={styles.largeInputElement}>
          <span className={styles.title}>Контакт служби підтрики: </span>
          <OutlinedInput
            defaultValue=""
            autoComplete="off"
            sx={largeInputStyle}
            value="тел.: +38 044 225 73 20, e-mail: support@sytecs.com.ua"
          />
        </div>
      </section>
    </div>
  );
};
