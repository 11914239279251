export const GET_ALL_PRINT_CODES_REQUEST = 'GET_ALL_PRINT_CODES_REQUEST' as const;
export const GET_ALL_PRINT_CODES_SUCCESS = 'GET_ALL_PRINT_CODES_SUCCESS' as const;
export const GET_ALL_PRINT_CODES_ERROR = 'GET_ALL_PRINT_CODES_ERROR' as const;

export const POST_CANCEL_PRINT_JOB_REQUEST = 'POST_CANCEL_PRINT_JOB_REQUEST' as const;
export const POST_CANCEL_PRINT_JOB_SUCCESS = 'POST_CANCEL_PRINT_JOB_SUCCESS' as const;
export const POST_CANCEL_PRINT_JOB_ERROR = 'POST_CANCEL_PRINT_JOB_ERROR' as const;

export const POST_PRINT_JOB_DETAILED_REQUEST = 'POST_PRINT_JOB_DETAILED_REQUEST' as const;
export const POST_PRINT_JOB_DETAILED_SUCCESS = 'POST_PRINT_JOB_DETAILED_SUCCESS' as const;
export const POST_PRINT_JOB_DETAILED_ERROR = 'POST_PRINT_JOB_DETAILED_ERROR' as const;

export const POST_FILTER_PRINT_JOB_REQUEST = 'POST_FILTER_PRINT_JOB_REQUEST' as const;
export const POST_FILTER_PRINT_JOB_SUCCESS = 'POST_FILTER_PRINT_JOB_SUCCESS' as const;
export const POST_FILTER_PRINT_JOB_ERROR = 'POST_FILTER_PRINT_JOB_ERROR' as const;

export const POST_UNRESERVE_MARKING_CODES_REQUEST = 'POST_UNRESERVE_MARKING_CODES_REQUEST' as const;
export const POST_UNRESERVE_MARKING_CODES_SUCCESS = 'POST_UNRESERVE_MARKING_CODES_SUCCESS' as const;
export const POST_UNRESERVE_MARKING_CODES_ERROR = 'POST_UNRESERVE_MARKING_CODES_ERROR' as const;

export const CLOSE_ERROR_POPUP_PRINT_JOB = 'CLOSE_ERROR_POPUP_PRINT_JOB' as const;
