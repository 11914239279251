import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { Reports } from '../../../../entities/Reports/service';
import {
  GET_BACKGROUND_PROCESSES_REQUEST,
  GET_SENDERS_INFO_REQUEST,
  GET_SENDERS_LOG_REQUEST,
  UPDATE_SENDERS_STATE_REQUEST,
} from './constants';
import {
  getSendersInfoSuccess,
  getSendersInfoError,
  getSendersInfoRequest,
  getSendersLogRequest,
  getSendersLogError,
  updateSendersStateRequest,
  updateSendersStateSuccess,
  updateSendersStateError,
  getSendersLogSuccess,
  getBackgroundProcessesRequest,
  getBackgroundProcessesSuccess,
  getBackgroundProcessesError,
} from './actions';

function* getSendersInfoRequestSaga(action: ReturnType<typeof getSendersInfoRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.getSendersInfo, action.payload);
    const res = response.data.ResponseBody;
    yield put(getSendersInfoSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getSendersInfoError(error));
  }
}

function* getSendersLogRequestSaga(action: ReturnType<typeof getSendersLogRequest>) {
  try {
    yield call(Reports.getSendersLog, action.payload);
    yield put(getSendersLogSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getSendersLogError(error));
  }
}

function* updateSendersStateRequestSaga(action: ReturnType<typeof updateSendersStateRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.updateSenderState, action.payload);
    const res = response.data.ResponseBody;
    yield put(getSendersInfoRequest({ countryCodeId: action.payload.countryCodeId }));
    yield put(updateSendersStateSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(updateSendersStateError(error));
  }
}

function* getBackgroundProcessesRequestSaga(action: ReturnType<typeof getBackgroundProcessesRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.getBackgroundProcesses, action.payload);
    const res = response.data.ResponseBody;
    yield put(getBackgroundProcessesSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getBackgroundProcessesError(error));
  }
}

export function* SendersInfoSagaWatcher() {
  yield takeLatest(GET_BACKGROUND_PROCESSES_REQUEST, getBackgroundProcessesRequestSaga);
  yield takeLatest(GET_SENDERS_INFO_REQUEST, getSendersInfoRequestSaga);
  yield takeLatest(GET_SENDERS_LOG_REQUEST, getSendersLogRequestSaga);
  yield takeLatest(UPDATE_SENDERS_STATE_REQUEST, updateSendersStateRequestSaga);
}
