import { Actions } from './actions';
import {
  GET_AUDITTRAIL_REPORT_REQUEST,
  GET_AUDITTRAIL_REPORT_SUCCESS,
  GET_AUDITTRAIL_REPORT_ERROR,
  GET_GLOBAL_EXCEL_REPORT_REQUEST,
  GET_GLOBAL_EXCEL_REPORT_SUCCESS,
  GET_GLOBAL_EXCEL_REPORT_ERROR,
  GET_PDF_FILE_REQUEST,
  GET_PDF_FILE_SUCCESS,
  GET_PDF_FILE_ERROR,
  CLOSE_ERROR_POPUP_AUDITTRAIL_REPORT_ERROR,
} from './constants';

const initialState = {
  loading: false as boolean,
  loadingUpdate: false as boolean,
  error: null as Error | null,

  audittrailReportResponse: [] as any,
  audittrailReportErrorResponses: {} as any,

  modalSuccessResponse: '' as string | null,
  modalErrorResponse: '' as string | null,
};

export type AuditTrailReportState = typeof initialState;

export const AuditTrailReportReducer = (state: AuditTrailReportState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_AUDITTRAIL_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        audittrailReportResponse: [],
        audittrailReportErrorResponses: {},
      };
    }
    case GET_AUDITTRAIL_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        audittrailReportResponse: action.payload,
      };
    }
    case GET_AUDITTRAIL_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        audittrailReportErrorResponses: action.payload,
      };
    }

    case GET_GLOBAL_EXCEL_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_GLOBAL_EXCEL_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_GLOBAL_EXCEL_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_PDF_FILE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PDF_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_PDF_FILE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLOSE_ERROR_POPUP_AUDITTRAIL_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        audittrailReportErrorResponses: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
