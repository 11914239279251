import { Actions } from './actions';
import {
  GET_AGGREGATION_REPORT_REQUEST,
  GET_AGGREGATION_REPORT_SUCCESS,
  GET_AGGREGATION_REPORT_ERROR,
  PUT_UPDATE_STATUS_REQUEST,
  PUT_UPDATE_STATUS_SUCCESS,
  PUT_UPDATE_STATUS_ERROR,
  GET_EXCEL_FILE_REQUEST,
  GET_EXCEL_FILE_SUCCESS,
  GET_EXCEL_FILE_ERROR,
  GET_GLOBAL_REPORT_REQUEST,
  GET_GLOBAL_REPORT_SUCCESS,
  GET_GLOBAL_REPORT_ERROR,
  GET_JSON_FILE_REQUEST,
  GET_JSON_FILE_SUCCESS,
  GET_JSON_FILE_ERROR,
  CLOSE_ERROR_POPUP_AGGREGATION_REPORT_ERROR,
} from './constants';

const initialState = {
  loading: false as boolean,
  loadingUpdate: false as boolean,
  error: null as Error | null,

  aggregationReportResponse: [] as any,
  aggregationReportErrorResponses: {} as any,

  modalSuccessResponse: '' as string | null,
  modalErrorResponse: '' as string | null,
};

export type AggregationReportState = typeof initialState;

export const AggregationReportReducer = (state: AggregationReportState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_AGGREGATION_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        aggregationReportResponse: [],
        aggregationReportErrorResponses: {},
      };
    }
    case GET_AGGREGATION_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        aggregationReportResponse: action.payload,
      };
    }
    case GET_AGGREGATION_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        aggregationReportErrorResponses: action.payload,
      };
    }
    case PUT_UPDATE_STATUS_REQUEST: {
      return {
        ...state,
        loadingUpdate: true,
        modalSuccessResponse: {},
      };
    }
    case PUT_UPDATE_STATUS_SUCCESS: {
      return {
        ...state,
        loadingUpdate: false,
        modalSuccessResponse: action.payload,
      };
    }
    case PUT_UPDATE_STATUS_ERROR: {
      return {
        ...state,
        loadingUpdate: false,
        modalSuccessResponse: {},
      };
    }

    case GET_EXCEL_FILE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_EXCEL_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_EXCEL_FILE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_GLOBAL_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_GLOBAL_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_GLOBAL_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_JSON_FILE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_JSON_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_JSON_FILE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLOSE_ERROR_POPUP_AGGREGATION_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        aggregationReportErrorResponses: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
