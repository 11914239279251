import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from 'react-bootstrap';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { closeErrorPopupSearchCodes } from '../../OperationsAndTools/SearchCodes/services/actions';
import styles from './ErrorResponse.module.scss';
import { closeErrorModal } from '../../Bank/ImportCodes/services/actions';
import { closeErrorPopUpBank } from '../../Bank/AvailableCodes/services/actions';
import { closeErrorPopUpPrintJobs } from '../../OperationsAndTools/PrintCodes/services/actions';
import { closeErrorPopUpProducts } from '../../Handbook/Products/services/actions';
import { closeErrorPopUpPacking } from '../../Handbook/Packaging/services/actions';
import { closeErrorPopUpShipping } from '../../Documents/ExportLists/services/actions';
import { useNavigate } from 'react-router-dom';
import { closeErrorPopupMain } from '../../Main/services/actions';
import { closeErrorPopUpBox } from '../../Templates/BoxTemplates/services/actions';
import { closeErrorPopUpPallet } from '../../Templates/PalletTemplates/services/actions';
import { closeErrorPopUpUnit } from '../../Templates/UnitTemplates/services/actions';
import { closeErrorPopUpImportReport } from '../../EventsLog/ImportReport/services/actions';
import { closeErrorPopUpAggregationReport } from '../../EventsLog/CodesReport/services/actions';
import { closeErrorPopUpShippReport } from '../../EventsLog/Shipment/services/actions';
import { closeErrorPopUpLicensedDevices } from '../../EventsLog/LicensedDevices/services/actions';
import { closeErrorPopUpCountryList } from '../../../entities/Countries/actions';
import { closeErrorPopUpSendersInfo } from '../../EventsLog/BackFlowProcesses/services/actions';
import { getDataTrueApiError, getKeyResponseError } from '../../About/Settings/services/actions';
import { closeErrorPopUpAuditTrailReport } from '../../EventsLog/AuditTrail/services/actions';
import { closeErrorPopUpWithdrawalReportReport } from '../../EventsLog/Withdrawal/services/actions';
import { closeSeriesErrorPopUp } from '../../EventsLog/SeriesReport/services/actions';
import { closeErrorPopUpReceipts } from '../../Bank/Receipts/services/actions';

interface Props {
  error: any;
}

const ErrorResponse: React.FC<Props> = ({ error }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onCloseHandler = () => {
    dispatch(closeErrorModal());
    dispatch(closeErrorPopUpBank());
    dispatch(closeErrorPopUpBox());
    dispatch(closeErrorPopUpPallet());
    dispatch(closeErrorPopUpUnit());
    dispatch(closeErrorPopUpImportReport());
    dispatch(closeErrorPopUpAggregationReport());
    dispatch(closeErrorPopUpShippReport());
    dispatch(closeErrorPopUpLicensedDevices());
    dispatch(closeErrorPopUpCountryList());
    dispatch(closeErrorPopUpSendersInfo());
    dispatch(closeErrorPopUpPrintJobs());
    dispatch(closeErrorPopUpProducts());
    dispatch(closeErrorPopUpPacking());
    dispatch(closeErrorPopUpShipping());
    dispatch(closeSeriesErrorPopUp());
    dispatch(closeErrorPopupSearchCodes());
    dispatch(closeErrorPopUpAuditTrailReport());
    dispatch(closeErrorPopUpWithdrawalReportReport());
    dispatch(closeErrorPopupMain());
    dispatch(getKeyResponseError({}));
    dispatch(getDataTrueApiError({}));
    dispatch(closeErrorPopUpReceipts());
    dispatch(getDataTrueApiError({}));
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (error?.status === 451) {
      navigate('/restriction');
    }
  }, [error, navigate]);

  const errorMessageHandler = () => {
    switch (error?.status) {
      case null: {
        return t('ServiceIsUnavailable');
      }
      default:
        return error?.message;
    }
  };

  return (
    <div className={classNames('p-3', styles.wrapper)}>
      <Toast show delay={16000} className={styles.errorBody} onClose={() => onCloseHandler()}>
        <Toast.Header className={styles.toastHeader}>
          <div className="d-flex flex-column">
            <span>{t('PopUpErrorHeader')}</span>
            <span>{errorMessageHandler()}</span>
          </div>
        </Toast.Header>
        <Toast.Body className="d-flex flex-column">
          <span>
            {t('PopUpErrorCode')}: {error?.status}
          </span>
          <span>
            {t('PopUpErrorURI')}: {error?.config?.url}
          </span>
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default ErrorResponse;
