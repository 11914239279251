import { MuiFileInput } from 'mui-file-input';
import React from 'react';
import { useDispatch } from 'react-redux';
import { postImportFileSuccess, postValidateFileSuccess } from '../../Bank/ImportCodes/services/actions';

import styles from './ImportFile.module.scss';

const inputStyle = {
  width: 300,
  height: 30,
  fontsize: 13,
  bgcolor: '#ffffff',
  '.MuiFileInput-TextField': {
    border: 'none',
  },
  '.MuiSvgIcon-root': {
    fontSize: '16px !important',
  },
  '.MuiOutlinedInput-root': {
    height: 30,
    outline: 'none',
    borderColor: '#B0BEC5',
    fontSize: '13px !important',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
  },
  '&.Mui-focused .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
  },
};

const errorStyle = {
  width: 300,
  height: 30,
  fontsize: 13,
  bgcolor: '#ffffff',
  '.MuiFileInput-TextField': {
    border: 'none',
  },
  '.MuiSvgIcon-root': {
    fontSize: '16px !important',
  },
  '.MuiOutlinedInput-root': {
    height: 30,
    outline: 'none',
    fontSize: '13px !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#d32f2f',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
  },
};

interface Props {
  fileHandler: React.Dispatch<React.SetStateAction<any>>;
  setShowApplySection: React.Dispatch<React.SetStateAction<any>>;
  file: any;
}

export const ImportFile: React.FC<Props> = ({ fileHandler, file, setShowApplySection }) => {
  const dispatch = useDispatch();
  const handleChange = (newFile: any) => {
    fileHandler(newFile);
    setShowApplySection(false);
    dispatch(postImportFileSuccess({}));
    dispatch(postValidateFileSuccess({}));
  };

  return (
    <div className={styles.wrapper}>
      <span>Оберіть csv-файл:</span>
      {file === null ? (
        <MuiFileInput className={styles.import} value={file} onChange={handleChange} sx={errorStyle} />
      ) : (
        <MuiFileInput className={styles.import} value={file} onChange={handleChange} sx={inputStyle} />
      )}
    </div>
  );
};
