import { Actions } from './actions';
import {
  CLOSE_ERROR_POPUP_BANK,
  GET_ALL_CODES_REQUEST,
  GET_ALL_CODES_SUCCESS,
  GET_ALL_CODES_ERROR,
  POST_ALLOW_TO_PRINT_ERROR,
  POST_ALLOW_TO_PRINT_SUCCESS,
  POST_DETAILED_INFO_REQUEST,
  POST_DETAILED_INFO_SUCCESS,
  POST_DETAILED_INFO_ERROR,
} from './constants';

const initialState = {
  loading: false as boolean,
  modalLoading: false as boolean,
  error: null as Error | null,
  availableCodes: [] as any,
  allowToPrintResponse: {} as any,
  postDetailedInfoResponse: {} as any,

  getCodesErrorResponses: {} as any,
  postAllowToPrintErrorResponses: {} as any,
  postDetailedInfoErrorResponses: {} as any,
};

export type AvailableCodesState = typeof initialState;

export const AvailableCodesReducer = (state: AvailableCodesState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_ALL_CODES_REQUEST: {
      return {
        ...state,
        loading: true,
        getCodesErrorResponses: {},
        postAllowToPrintErrorResponses: {},
        availableCodes: [],
      };
    }
    case GET_ALL_CODES_SUCCESS: {
      return {
        ...state,
        loading: false,
        availableCodes: action.payload,
      };
    }
    case GET_ALL_CODES_ERROR: {
      return {
        ...state,
        loading: false,
        getCodesErrorResponses: action.payload,
      };
    }

    case POST_DETAILED_INFO_REQUEST: {
      return {
        ...state,
        modalLoading: true,
        postDetailedInfoResponse: {},
        postDetailedInfoErrorResponses: {},
      };
    }
    case POST_DETAILED_INFO_SUCCESS: {
      return {
        ...state,
        modalLoading: false,
        postDetailedInfoResponse: action.payload,
      };
    }
    case POST_DETAILED_INFO_ERROR: {
      return {
        ...state,
        modalLoading: false,
        postDetailedInfoErrorResponses: action.payload,
      };
    }

    case POST_ALLOW_TO_PRINT_SUCCESS: {
      return {
        ...state,
        allowToPrintResponse: action.payload,
      };
    }
    case POST_ALLOW_TO_PRINT_ERROR: {
      return {
        ...state,
        postAllowToPrintErrorResponses: action.payload,
      };
    }
    case CLOSE_ERROR_POPUP_BANK: {
      return {
        ...state,
        getCodesErrorResponses: {},
        postAllowToPrintErrorResponses: {},
        postDetailedInfoErrorResponses: {},
      };
    }
    default: {
      return state;
    }
  }
};
