import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { Reports } from '../../../../entities/Reports/service';
import { GET_WITHDRAWAL_REPORT_REQUEST, GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_REQUEST } from './constants';
import {
  getWithdrawalReportError,
  getWithdrawalReportRequest,
  getWithdrawalReportSuccess,
  getGlobalWithdrawalExcelReportError,
  getGlobalWithdrawalExcelReportRequest,
  getGlobalWithdrawalExcelReportSuccess,
} from './actions';

function* getWithdrawalReportSaga(action: ReturnType<typeof getWithdrawalReportRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.getWithdrawalReport, action.payload);
    const withdrawalReport = response.data.ResponseBody;
    yield put(getWithdrawalReportSuccess(withdrawalReport));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getWithdrawalReportError(error));
  }
}

function* getGlobalWithdrawalExcelReportRequestSaga(action: ReturnType<typeof getGlobalWithdrawalExcelReportRequest>) {
  try {
    yield call(Reports.getGlobalWithdrawalExcelReport, action.payload);

    yield put(getGlobalWithdrawalExcelReportSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getGlobalWithdrawalExcelReportError(error));
  }
}

export function* WithdrawalReportSagaWatcher() {
  yield takeLatest(GET_WITHDRAWAL_REPORT_REQUEST, getWithdrawalReportSaga);
  yield takeLatest(GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_REQUEST, getGlobalWithdrawalExcelReportRequestSaga);
}
