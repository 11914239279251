import {
  CLOSE_ERROR_POPUP_SHIPPING,
  DELETE_FILTER_ERROR,
  DELETE_FILTER_REQUEST,
  DELETE_FILTER_SUCCESS,
  DELETE_PRODUCT_FROM_DOCUMENT_ERROR,
  DELETE_PRODUCT_FROM_DOCUMENT_REQUEST,
  DELETE_PRODUCT_FROM_DOCUMENT_SUCCESS,
  DELETE_SHIPPING_ORDER_BY_ID_ERROR,
  DELETE_SHIPPING_ORDER_BY_ID_REQUEST,
  DELETE_SHIPPING_ORDER_BY_ID_SUCCESS,
  DOWNLOAD_AGGREGATION_REPORT_ERROR,
  DOWNLOAD_AGGREGATION_REPORT_REQUEST,
  DOWNLOAD_AGGREGATION_REPORT_SUCCESS,
  DOWNLOAD_SHIPPING_ORDER_ERROR,
  DOWNLOAD_SHIPPING_ORDER_REQUEST,
  DOWNLOAD_SHIPPING_ORDER_SUCCESS,
  GET_ALL_SHIPPING_ORDERS_ERROR,
  GET_ALL_SHIPPING_ORDERS_REQUEST,
  GET_ALL_SHIPPING_ORDERS_SUCCESS,
  GET_SHIPPING_ORDER_BY_DATE_ERROR,
  GET_SHIPPING_ORDER_BY_DATE_REQUEST,
  GET_SHIPPING_ORDER_BY_DATE_SUCCESS,
  GET_SHIPPING_ORDER_BY_ID_ERROR,
  GET_SHIPPING_ORDER_BY_ID_REQUEST,
  GET_SHIPPING_ORDER_BY_ID_SUCCESS,
  POST_CHECK_PRODUCT_ERROR,
  POST_CHECK_PRODUCT_REQUEST,
  POST_CHECK_PRODUCT_SUCCESS,
  POST_CHECK_SERIES_ERROR,
  POST_CHECK_SERIES_REQUEST,
  POST_CHECK_SERIES_SUCCESS,
  POST_CREATE_SHIPPING_ORDER_ERROR,
  POST_CREATE_SHIPPING_ORDER_REQUEST,
  POST_CREATE_SHIPPING_ORDER_SUCCESS,
  POST_UPDATE_ICON_SERIES_ERROR,
  POST_UPDATE_ICON_SERIES_REQUEST,
  POST_UPDATE_ICON_SERIES_SUCCESS,
  PUT_UPDATE_SHIPPING_ORDER_ERROR,
  PUT_UPDATE_SHIPPING_ORDER_REQUEST,
  PUT_UPDATE_SHIPPING_ORDER_SUCCESS,
  SET_ERROR_MESSAGE_SHIPPINGS,
} from './constants';

export const getAllShippingOrdersRequest = () => ({
  type: GET_ALL_SHIPPING_ORDERS_REQUEST,
});
export const getAllShippingOrdersSuccess = (data: any) => ({
  type: GET_ALL_SHIPPING_ORDERS_SUCCESS,
  payload: data,
});
export const getAllShippingOrdersError = (error: any) => ({
  type: GET_ALL_SHIPPING_ORDERS_ERROR,
  payload: error,
});

export const postCreateShippingOrderRequest = (navigate: any) => ({
  type: POST_CREATE_SHIPPING_ORDER_REQUEST,
  navigate,
});
export const postCreateShippingOrderSuccess = (data: any) => ({
  type: POST_CREATE_SHIPPING_ORDER_SUCCESS,
  payload: data,
});
export const postCreateShippingOrderError = (error: any) => ({
  type: POST_CREATE_SHIPPING_ORDER_ERROR,
  payload: error,
});

export const getShippingOrderByIdRequest = (body: any) => ({
  type: GET_SHIPPING_ORDER_BY_ID_REQUEST,
  payload: body,
});
export const getShippingOrderByIdSuccess = (data: any) => ({
  type: GET_SHIPPING_ORDER_BY_ID_SUCCESS,
  payload: data,
});
export const getShippingOrderByIdError = (error: any) => ({
  type: GET_SHIPPING_ORDER_BY_ID_ERROR,
  payload: error,
});

export const getShippingOrderByDateRequest = (body: any) => ({
  type: GET_SHIPPING_ORDER_BY_DATE_REQUEST,
  payload: body,
});
export const getShippingOrderByDateSuccess = (data: any) => ({
  type: GET_SHIPPING_ORDER_BY_DATE_SUCCESS,
  payload: data,
});
export const getShippingOrderByDateError = (error: any) => ({
  type: GET_SHIPPING_ORDER_BY_DATE_ERROR,
  payload: error,
});

export const putUpdateShippingOrderRequest = (body: any) => ({
  type: PUT_UPDATE_SHIPPING_ORDER_REQUEST,
  payload: body,
});
export const putUpdateShippingOrderSuccess = (data: any) => ({
  type: PUT_UPDATE_SHIPPING_ORDER_SUCCESS,
  payload: data,
});
export const putUpdateShippingOrderError = (error: any) => ({
  type: PUT_UPDATE_SHIPPING_ORDER_ERROR,
  payload: error,
});

export const postCheckSeriesRequest = (body: any) => ({
  type: POST_CHECK_SERIES_REQUEST,
  payload: body,
});
export const postCheckSeriesSuccess = (data: any) => ({
  type: POST_CHECK_SERIES_SUCCESS,
  payload: data,
});
export const postCheckSeriesError = (error: any) => ({
  type: POST_CHECK_SERIES_ERROR,
  payload: error,
});

export const postCheckProductRequest = (body: any) => ({
  type: POST_CHECK_PRODUCT_REQUEST,
  payload: body,
});
export const postCheckProductSuccess = (data: any) => ({
  type: POST_CHECK_PRODUCT_SUCCESS,
  payload: data,
});
export const postCheckProductError = (error: any) => ({
  type: POST_CHECK_PRODUCT_ERROR,
  payload: error,
});

export const deleteShippingOrderByIdRequest = (body: any) => ({
  type: DELETE_SHIPPING_ORDER_BY_ID_REQUEST,
  payload: body,
});
export const deleteShippingOrderByIdSuccess = (data: any) => ({
  type: DELETE_SHIPPING_ORDER_BY_ID_SUCCESS,
  payload: data,
});
export const deleteShippingOrderByIdError = (error: any) => ({
  type: DELETE_SHIPPING_ORDER_BY_ID_ERROR,
  payload: error,
});

export const setErrorMessageShippings = (error: any) => ({
  type: SET_ERROR_MESSAGE_SHIPPINGS,
  payload: error,
});

export const postUpdateIconSeriesRequest = (body: any) => ({
  type: POST_UPDATE_ICON_SERIES_REQUEST,
  payload: body,
});
export const postUpdateIconSeriesSuccess = (data: any) => ({
  type: POST_UPDATE_ICON_SERIES_SUCCESS,
  payload: data,
});
export const postUpdateIconSeriesError = (error: any) => ({
  type: POST_UPDATE_ICON_SERIES_ERROR,
  payload: error,
});

export const deleteFilterRequest = (body: any) => ({
  type: DELETE_FILTER_REQUEST,
  payload: body,
});
export const deleteFilterSuccess = (data: any) => ({
  type: DELETE_FILTER_SUCCESS,
  payload: data,
});
export const deleteFilterError = (error: any) => ({
  type: DELETE_FILTER_ERROR,
  payload: error,
});

export const deleteProductFromDocumentRequest = (body: any) => ({
  type: DELETE_PRODUCT_FROM_DOCUMENT_REQUEST,
  payload: body,
});
export const deleteProductFromDocumentSuccess = (data: any) => ({
  type: DELETE_PRODUCT_FROM_DOCUMENT_SUCCESS,
  payload: data,
});
export const deleteProductFromDocumentError = (error: any) => ({
  type: DELETE_PRODUCT_FROM_DOCUMENT_ERROR,
  payload: error,
});

export const downloadShippingOrderRequest = (body: any) => ({
  type: DOWNLOAD_SHIPPING_ORDER_REQUEST,
  payload: body,
});
export const downloadShippingOrderSuccess = () => ({
  type: DOWNLOAD_SHIPPING_ORDER_SUCCESS,
});
export const downloadShippingOrderError = (error: any) => ({
  type: DOWNLOAD_SHIPPING_ORDER_ERROR,
  payload: error,
});

export const downloadAggregationReportRequest = (body: any) => ({
  type: DOWNLOAD_AGGREGATION_REPORT_REQUEST,
  payload: body,
});
export const downloadAggregationReportSuccess = () => ({
  type: DOWNLOAD_AGGREGATION_REPORT_SUCCESS,
});
export const downloadAggregationReportError = (error: any) => ({
  type: DOWNLOAD_AGGREGATION_REPORT_ERROR,
  payload: error,
});

export const closeErrorPopUpShipping = () => ({
  type: CLOSE_ERROR_POPUP_SHIPPING,
});

export type Actions =
  | ReturnType<typeof downloadAggregationReportRequest>
  | ReturnType<typeof downloadAggregationReportSuccess>
  | ReturnType<typeof downloadAggregationReportError>
  | ReturnType<typeof downloadShippingOrderRequest>
  | ReturnType<typeof downloadShippingOrderSuccess>
  | ReturnType<typeof downloadShippingOrderError>
  | ReturnType<typeof closeErrorPopUpShipping>
  | ReturnType<typeof deleteProductFromDocumentRequest>
  | ReturnType<typeof deleteProductFromDocumentSuccess>
  | ReturnType<typeof deleteProductFromDocumentError>
  | ReturnType<typeof deleteFilterRequest>
  | ReturnType<typeof deleteFilterSuccess>
  | ReturnType<typeof deleteFilterError>
  | ReturnType<typeof postUpdateIconSeriesRequest>
  | ReturnType<typeof postUpdateIconSeriesSuccess>
  | ReturnType<typeof postUpdateIconSeriesError>
  | ReturnType<typeof deleteShippingOrderByIdRequest>
  | ReturnType<typeof deleteShippingOrderByIdSuccess>
  | ReturnType<typeof deleteShippingOrderByIdError>
  | ReturnType<typeof setErrorMessageShippings>
  | ReturnType<typeof postCheckProductRequest>
  | ReturnType<typeof postCheckProductSuccess>
  | ReturnType<typeof postCheckProductError>
  | ReturnType<typeof postCheckSeriesRequest>
  | ReturnType<typeof postCheckSeriesSuccess>
  | ReturnType<typeof postCheckSeriesError>
  | ReturnType<typeof putUpdateShippingOrderRequest>
  | ReturnType<typeof putUpdateShippingOrderSuccess>
  | ReturnType<typeof putUpdateShippingOrderError>
  | ReturnType<typeof getShippingOrderByIdRequest>
  | ReturnType<typeof getShippingOrderByIdSuccess>
  | ReturnType<typeof getShippingOrderByIdError>
  | ReturnType<typeof getShippingOrderByDateRequest>
  | ReturnType<typeof getShippingOrderByDateSuccess>
  | ReturnType<typeof getShippingOrderByDateError>
  | ReturnType<typeof postCreateShippingOrderRequest>
  | ReturnType<typeof postCreateShippingOrderSuccess>
  | ReturnType<typeof postCreateShippingOrderError>
  | ReturnType<typeof getAllShippingOrdersRequest>
  | ReturnType<typeof getAllShippingOrdersSuccess>
  | ReturnType<typeof getAllShippingOrdersError>;
