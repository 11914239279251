import { AxiosResponse } from 'axios';
import { instance as axios } from 'api/axios';

export class AvailableCodes {
  public static async getAvailableCodes() {
    const url = '/MarkingCode/Bank';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async allowToPrint(body: any) {
    const url = '/MarkingCode/AllowToPrint';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async detailedInfo(body: any) {
    const url = '/MarkingCode/Bank/Detailed';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }
}
