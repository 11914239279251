export const GET_TEXT_REPORT_REQUEST = 'GET_TEXT_REPORT_REQUEST' as const;
export const GET_TEXT_REPORT_SUCCESS = 'GET_TEXT_REPORT_SUCCESS' as const;
export const GET_TEXT_REPORT_ERROR = 'GET_TEXT_REPORT_ERROR' as const;

export const GET_TREE_REPORT_REQUEST = 'GET_TREE_REPORT_REQUEST' as const;
export const GET_TREE_REPORT_SUCCESS = 'GET_TREE_REPORT_SUCCESS' as const;
export const GET_TREE_REPORT_ERROR = 'GET_TREE_REPORT_ERROR' as const;

export const GET_DOWNLOAD_REPORT_REQUEST = 'GET_DOWNLOAD_REPORT_REQUEST' as const;
export const GET_DOWNLOAD_REPORT_SUCCESS = 'GET_DOWNLOAD_REPORT_SUCCESS' as const;
export const GET_DOWNLOAD_REPORT_ERROR = 'GET_DOWNLOAD_REPORT_ERROR' as const;

export const CLOSE_ERROR_POPUP_SEARCH_CODES = 'CLOSE_ERROR_POPUP_SEARCH_CODES' as const;
