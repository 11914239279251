import { combineReducers } from 'redux';
import { PrintCodesReducer } from './PrintCodes/services/reducer';
import { SearchCodesReducer } from './SearchCodes/services/reducer';

export const operationsModuleReducer = combineReducers({
  PrintCodesReducer,
  SearchCodesReducer,
});

export type OperationModuleReducer = ReturnType<typeof operationsModuleReducer>;
