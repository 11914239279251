export const GET_USER_REQUEST = 'GET_USER_REQUEST' as const;
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS' as const;
export const GET_USER_ERROR = 'GET_USER_ERROR' as const;

export const POST_AUTHENTICATION_LOGIN_REQUEST = 'POST_AUTHENTICATION_LOGIN_REQUEST' as const;
export const POST_AUTHENTICATION_LOGIN_SUCCESS = 'POST_AUTHENTICATION_LOGIN_SUCCESS' as const;
export const POST_AUTHENTICATION_LOGIN_ERROR = 'POST_AUTHENTICATION_LOGIN_ERROR' as const;

export const POST_AUTHENTICATION_LOGOUT_REQUEST = 'POST_AUTHENTICATION_LOGOUT_REQUEST' as const;
export const POST_AUTHENTICATION_LOGOUT_SUCCESS = 'POST_AUTHENTICATION_LOGOUT_SUCCESS' as const;
export const POST_AUTHENTICATION_LOGOUT_ERROR = 'POST_AUTHENTICATION_LOGOUT_ERROR' as const;

export const CLOSE_ERROR_POPUP_MAIN = 'CLOSE_ERROR_POPUP_MAIN' as const;
