import { AxiosResponse } from 'axios';
import { instance as axios } from 'api/axios';

export class ShippingOrders {
  public static async getAllShippingOrders() {
    const url = '/ShippingOrder/GetAll';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async postCreateShippingOrder() {
    const url = '/ShippingOrder/Create';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async putUpdateShippingOrder(body: any) {
    const url = '/ShippingOrder/Update';
    const response: AxiosResponse = await axios.put(url, body);
    return response;
  }

  public static async getShippingOrderById(body: any) {
    const url = '/ShippingOrder/GetById';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async getShippingOrderByDate(body: any) {
    const url = '/ShippingOrder/GetByDates';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async postCheckSeries(body: any) {
    const url = '/Filter/CheckBySeries';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async postCheckProduct(body: any) {
    const url = '/ShippingOrder/CheckProduct';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async deleteShippingOrder(body: any) {
    const url = '/ShippingOrder/Delete';
    const response: AxiosResponse = await axios.delete(url, { data: body });
    return response;
  }

  public static async deleteFilter(body: any) {
    const url = '/ShippingOrder/Filter/Delete';
    const response: AxiosResponse = await axios.delete(url, { data: body });
    return response;
  }

  public static async deleteProductFromDoc(body: any) {
    const url = '/ShippingOrder/Product/Delete';
    const response: AxiosResponse = await axios.delete(url, { data: body });
    return response;
  }

  public static async getOrderReport(body: any) {
    const requestUrl = '/ShippingOrder/Excel/OrderReport';
    const res = await axios({
      url: `${requestUrl}`,
      method: 'POST',
      responseType: 'blob',
      data: body,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${body?.shippingOrderId}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }

  public static async getAggregationReport(body: any) {
    const requestUrl = '/ShippingOrder/Aggregation/GetFile';
    const res = await axios({
      url: `${requestUrl}`,
      method: 'POST',
      responseType: 'blob',
      data: body,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${body?.shippingOrderId}.json`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }

  public static async getExcelFile(body: any) {
    const requestUrl = 'Report/Aggregation/Excel/File';
    const res = await axios({
      url: `${requestUrl}`,
      method: 'POST',
      responseType: 'blob',
      data: body,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Aggregation Report by SSCC ${body?.SSCC}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }

  public static async getGlobalReport(body: any) {
    const requestUrl = 'Report/Aggregation/Excel/File/BySsccs';
    const res = await axios({
      url: `${requestUrl}`,
      method: 'POST',
      responseType: 'blob',
      data: body,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Aggregation Table.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }

  public static async getGlobalExcelReport(body: any) {
    const requestUrl = 'Report/Audit/File/Excel';
    const res = await axios({
      url: `${requestUrl}`,
      method: 'POST',
      responseType: 'blob',
      data: body,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `AuditTrail.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }

  public static async getJSONFile(body: any) {
    const requestUrl = '/Report/AggregationExternal/File';
    const res = await axios({
      url: `${requestUrl}`,
      method: 'POST',
      responseType: 'blob',
      data: body,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Legacy PAI response ${body?.SSCC}.json`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }

  public static async getPDFFile(body: any) {
    const requestUrl = '/Audit/PDF';
    const res = await axios({
      url: `${requestUrl}`,
      method: 'POST',
      responseType: 'blob',
      data: body,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Report by entry ${body.auditEntryID}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }

  public static async putUpdateStatus(body: any) {
    const url = '/Report/Aggregation/UpdateStatus';
    const response: AxiosResponse = await axios.put(url, body);
    return response;
  }
}
