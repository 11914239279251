import { LogLevel } from '@azure/msal-browser';

const baseUrl = process.env.REACT_APP_PROD_URL;
// const baseUrl = 'http://localhost:3000';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_AUTORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: `${baseUrl}`,
    postLogoutRedirectUri: `${baseUrl}`,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // eslint-disable-next-line default-case
        switch (!level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      },
    },
  },
};
export const loginRequest = {
  scopes: [process.env.REACT_APP_AZURE_READACCESS_SCOPE_ID],
};
