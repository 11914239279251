import { combineReducers } from 'redux';
import { PackingReducer } from './Packaging/services/reducer';
import { ProductReducer } from './Products/services/reducer';
import { WithdrawalReducer } from './Withdrawal/services/reducer';

export const handbookModuleReducer = combineReducers({
  PackingReducer,
  ProductReducer,
  WithdrawalReducer,
});

export type HandbookModuleReducer = ReturnType<typeof handbookModuleReducer>;
