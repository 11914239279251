import { AxiosResponse } from 'axios';
import { instance as axios } from 'api/axios';

export class Templates {
  public static async getTemplates(body: any) {
    const url = '/LabelTemplate/GetByLevel';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async getTemplatesById(body: any) {
    const url = '/LabelTemplate/GetById';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async deleteTemplatesById(body: any) {
    const url = '/LabelTemplate/Delete';
    const response: AxiosResponse = await axios.delete(url, { data: body });
    return response;
  }

  public static async postCreateTemplates() {
    const url = '/LabelTemplate/Create';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async putUpdateTemplates(body: any) {
    const url = '/LabelTemplate/Update';
    const response: AxiosResponse = await axios.put(url, body);
    return response;
  }

  public static async photoUpload(body: any) {
    const url = '/Image/Upload';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async getPhoto(params: any) {
    const url = `/Image/Show?fileName=${params}`;
    const response: AxiosResponse = await axios.get(url, { responseType: 'blob' });
    return response;
  }
}
