import { AxiosResponse } from 'axios';
import { instance as axios } from 'api/axios';

export class CountryList {
  public static async getCountryList() {
    const url = '/CountryCode/GetAll';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }
}
