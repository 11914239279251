import { Actions } from './actions';
import {
  GET_RECEIPTS_REQUEST,
  GET_RECEIPTS_ERROR,
  GET_RECEIPTS_SUCCESS,
  CLOSE_ERROR_POPUP_RECEIPTS,
  DELETE_DEACTIVATE_REQUEST,
  DELETE_DEACTIVATE_SUCCESS,
  DELETE_DEACTIVATE_ERROR,
  GET_PRODUCT_LIST_REQUEST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  POST_CREATE_RECEIPT_REQUEST,
  POST_CREATE_RECEIPT_SUCCESS,
  POST_CREATE_RECEIPT_ERROR,
  GET_RECEIPT_PDF_FILE_REQUEST,
  GET_RECEIPT_PDF_FILE_SUCCESS,
  GET_RECEIPT_PDF_FILE_ERROR,
} from './constants';

const initialState = {
  loading: false as boolean,
  loadingUpdate: false as boolean,
  deactivateLoading: false as boolean,
  createLoading: false as boolean,
  error: null as Error | null,

  receiptsResponse: [] as any,
  receiptsErrorResponses: {} as any,

  deactivateResponse: {} as any,
  deactivateErrorResponses: {} as any,

  productListResponse: [] as any,
  productListErrorResponse: {} as any,

  createReceiptResponse: {} as any,
  createReceiptErrorResponse: {} as any,
};

export type ReceiptsState = typeof initialState;

export const ReceiptsReducer = (state: ReceiptsState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_RECEIPTS_REQUEST: {
      return {
        ...state,
        loading: true,
        receiptsResponse: [],
        receiptsErrorResponses: {},
        deactivateResponse: {},
        deactivateErrorResponses: {},
        createReceiptResponse: {},
        createReceiptErrorResponse: {},
      };
    }
    case GET_RECEIPTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        receiptsResponse: action.payload,
      };
    }
    case GET_RECEIPTS_ERROR: {
      return {
        ...state,
        loading: false,
        receiptsErrorResponses: action.payload,
      };
    }

    case DELETE_DEACTIVATE_REQUEST: {
      return {
        ...state,
        deactivateLoading: true,
        deactivateResponse: [],
        deactivateErrorResponses: {},
      };
    }
    case DELETE_DEACTIVATE_SUCCESS: {
      return {
        ...state,
        deactivateLoading: false,
        deactivateResponse: action.payload,
      };
    }
    case DELETE_DEACTIVATE_ERROR: {
      return {
        ...state,
        deactivateLoading: false,
        deactivateErrorResponses: action.payload,
      };
    }

    case GET_PRODUCT_LIST_REQUEST: {
      return {
        ...state,
        productListResponse: [],
        productListErrorResponse: {},
      };
    }
    case GET_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        productListResponse: action.payload,
      };
    }
    case GET_PRODUCT_LIST_ERROR: {
      return {
        ...state,
        deactivateLoading: false,
        productListErrorResponse: action.payload,
      };
    }

    case POST_CREATE_RECEIPT_REQUEST: {
      return {
        ...state,
        createLoading: true,
        createReceiptResponse: [],
        createReceiptErrorResponse: {},
      };
    }
    case POST_CREATE_RECEIPT_SUCCESS: {
      return {
        ...state,
        createLoading: false,
        createReceiptResponse: action.payload,
      };
    }
    case POST_CREATE_RECEIPT_ERROR: {
      return {
        ...state,
        createLoading: false,
        createReceiptErrorResponse: action.payload,
      };
    }

    case GET_RECEIPT_PDF_FILE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_RECEIPT_PDF_FILE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_RECEIPT_PDF_FILE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case CLOSE_ERROR_POPUP_RECEIPTS: {
      return {
        ...state,
        loading: false,
        receiptsErrorResponses: {},
        deactivateErrorResponses: {},
        productListErrorResponse: {},
        createReceiptErrorResponse: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
