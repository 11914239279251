import { instance as axios } from 'api/axios';
import { AxiosResponse } from 'axios';

export class Products {
  public static async getProductByGtin(body: any) {
    const url = '/Product/GetByGTIN';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async createProduct(body: any) {
    const url = '/Product/Create';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async updateProduct(body: any) {
    const url = '/Product/Update';
    const response: AxiosResponse = await axios.put(url, body);
    return response;
  }

  public static async addProductImage(body: any) {
    const url = '/Product/Image';
    const response: AxiosResponse = await axios.put(url, body);
    return response;
  }

  public static async deleteProductImage(body: any) {
    const url = '/Product/Image';
    const response: AxiosResponse = await axios.delete(url, { data: body });
    return response;
  }

  public static async getPhoto(params: any) {
    const url = `/Image/Show?fileName=${params}`;
    const response: AxiosResponse = await axios.get(url, { responseType: 'blob' });
    return response;
  }

  public static async deleteProduct(body: any) {
    const url = `/Product/Delete`;
    const response: AxiosResponse = await axios.delete(url, { data: body });
    return response;
  }

  public static async getProductList() {
    const url = `/Product/GetAll`;
    const response: AxiosResponse = await axios.post(url);
    return response;
  }
}
