import { OutlinedInput } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import styles from './SimpleField.module.scss';
import { putUpdatePalletTemplatesSuccess } from '../../Templates/PalletTemplates/services/actions';
import { putUpdateUnitTemplatesSuccess } from '../../Templates/UnitTemplates/services/actions';
import { putUpdateBoxTemplatesSuccess } from '../../Templates/BoxTemplates/services/actions';
import { useDispatch } from 'react-redux';

const smallInputStyle = {
  width: 100,
  height: 30,
  fontSize: '13px !important',
  bgcolor: '#ffffff',
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& .Mui-disabled': {
    WebkitTextFillColor: '#333333',
    bgcolor: '#ECEFF1',
  },
};

const inputStyle = {
  width: 400,
  height: 30,
  fontSize: '13px !important',
  bgcolor: '#ffffff',
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& .Mui-disabled': {
    WebkitTextFillColor: '#333333',
    bgcolor: '#ECEFF1',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
};

interface Props {
  name: string;
  size?: string;
  hasMarginTop?: boolean;
  value?: any;
  disabled?: boolean;
  validate?: boolean;
  maxLength?: number;
  type?: string;
}

export const SimpleField: React.FC<Props> = ({
  name,
  size,
  hasMarginTop,
  disabled,
  value,
  maxLength,
  validate,
  type,
}) => {
  const { handleChange, setFieldValue } = useFormikContext<any>();
  const dispatch = useDispatch();

  // const zeroCharValidation = (val: any) => {
  //   let v;
  //   val == 0 ? (v = '') : (v = val);
  //   return v.slice(0, maxLength);
  // };

  useEffect(() => {
    if (name === 'templateName') {
      dispatch(putUpdatePalletTemplatesSuccess({}));
      dispatch(putUpdateUnitTemplatesSuccess({}));
      dispatch(putUpdateBoxTemplatesSuccess({}));
    }
  }, [value]);

  return (
    <div className={hasMarginTop ? styles.inputElement : styles.inputNoMargin}>
      <div className={disabled ? styles.disabledField : null}>
        {validate ? (
          <OutlinedInput
            defaultValue=""
            autoComplete="off"
            sx={size === 'small' ? smallInputStyle : inputStyle}
            className={styles.simpleField}
            name={name}
            onChange={
              type === 'number' ? (event) => setFieldValue(name, event.target.value.slice(0, maxLength)) : handleChange
            }
            disabled={disabled}
            value={value}
            inputProps={{ maxLength, type }}
            error={value === undefined || value === null || value.toString().trim().length === 0}
          />
        ) : (
          <OutlinedInput
            defaultValue=""
            autoComplete="off"
            sx={size === 'small' ? smallInputStyle : inputStyle}
            className={styles.simpleField}
            name={name}
            onChange={
              type === 'number' ? (event) => setFieldValue(name, event.target.value.slice(0, maxLength)) : handleChange
            }
            disabled={disabled}
            value={value}
            inputProps={{ maxLength, type }}
          />
        )}
      </div>
    </div>
  );
};
