import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  deleteWithdrawalError,
  deleteWithdrawalRequest,
  deleteWithdrawalSuccess,
  getAllWithdrawalsError,
  getAllWithdrawalsRequest,
  getAllWithdrawalsSuccess,
  postAddNewWithdrawalError,
  postAddNewWithdrawalRequest,
  postAddNewWithdrawalSuccess,
  putUpdateWithdrawalError,
  putUpdateWithdrawalRequest,
  putUpdateWithdrawalSuccess,
} from './actions';
import {
  DELETE_WITHDRAWAL_REQUEST,
  GET_ALL_WITHDRAWAL_REQUEST,
  POST_ADD_NEW_WITHDRAWAL_REQUEST,
  PUT_UPDATE_WITHDRAWAL_REQUEST,
} from './constants';
import { Withdrawal } from '../../../../entities/Withdrawal/service';

function* getAllWithdrawalsSaga() {
  try {
    const response: AxiosResponse = yield call(Withdrawal.getAllWithdrawals);
    const res = response.data.ResponseBody;
    yield put(getAllWithdrawalsSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getAllWithdrawalsError(error));
  }
}

function* postAddNewWithdrawalSaga(action: ReturnType<typeof postAddNewWithdrawalRequest>) {
  try {
    const response: AxiosResponse = yield call(Withdrawal.addNewWithdrawal, action.payload);
    const res = response.data.ResponseBody;
    yield put(postAddNewWithdrawalSuccess(res));
    yield put(getAllWithdrawalsRequest());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postAddNewWithdrawalError(error));
  }
}

function* putUpdateWithdrawalSaga(action: ReturnType<typeof putUpdateWithdrawalRequest>) {
  try {
    // @ts-expect-error
    const response: any = yield call(Withdrawal.updateWithdrawal, action.payload);
    const res = response.data.ResponseBody;
    yield put(putUpdateWithdrawalSuccess(res));
    yield put(getAllWithdrawalsRequest());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(putUpdateWithdrawalError(error));
  }
}

function* deleteWithdrawalSaga(action: ReturnType<typeof deleteWithdrawalRequest>) {
  try {
    yield call(Withdrawal.deleteWithdrawal, action.payload);

    yield put(deleteWithdrawalSuccess(true));
    yield put(getAllWithdrawalsRequest());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(deleteWithdrawalError(error));
  }
}

export function* WithdrawalSagaWatcher() {
  yield takeLatest(POST_ADD_NEW_WITHDRAWAL_REQUEST, postAddNewWithdrawalSaga);
  yield takeLatest(GET_ALL_WITHDRAWAL_REQUEST, getAllWithdrawalsSaga);
  yield takeLatest(PUT_UPDATE_WITHDRAWAL_REQUEST, putUpdateWithdrawalSaga);
  yield takeLatest(DELETE_WITHDRAWAL_REQUEST, deleteWithdrawalSaga);
}
