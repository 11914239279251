import { Actions } from './actions';
import {
  CLOSE_ERROR_POPUP_COUNTRY_LIST,
  GET_COUNTRY_LIST_ERROR,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_SUCCESS,
} from './constants';

const initialState = {
  loading: false as boolean,
  error: null as Error | null,
  countries: [] as any,

  countriesErrorResponse: {} as any,
};

export type CountryState = typeof initialState;

export const CountryReducer = (state: CountryState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_COUNTRY_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        countriesErrorResponse: {},
        countries: [],
      };
    }
    case GET_COUNTRY_LIST_SUCCESS: {
      return {
        ...state,
        countries: action.payload,
        loading: false,
      };
    }
    case GET_COUNTRY_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        countriesErrorResponse: action.payload,
      };
    }

    case CLOSE_ERROR_POPUP_COUNTRY_LIST: {
      return {
        ...state,
        loading: false,
        countriesErrorResponse: {},
      };
    }
    default: {
      return state;
    }
  }
};
