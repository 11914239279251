export const GET_PALLET_TEMPLATES_REQUEST = 'GET_PALLET_TEMPLATES_REQUEST' as const;
export const GET_PALLET_TEMPLATES_SUCCESS = 'GET_PALLET_TEMPLATES_SUCCESS' as const;
export const GET_PALLET_TEMPLATES_ERROR = 'GET_PALLET_TEMPLATES_ERROR' as const;

export const GET_PALLET_TEMPLATES_BY_ID_REQUEST = 'GET_PALLET_TEMPLATES_BY_ID_REQUEST' as const;
export const GET_PALLET_TEMPLATES_BY_ID_SUCCESS = 'GET_PALLET_TEMPLATES_BY_ID_SUCCESS' as const;
export const GET_PALLET_TEMPLATES_BY_ID_ERROR = 'GET_PALLET_TEMPLATES_BY_ID_ERROR' as const;

export const DELETE_PALLET_TEMPLATES_BY_ID_REQUEST = 'DELETE_PALLET_TEMPLATES_BY_ID_REQUEST' as const;
export const DELETE_PALLET_TEMPLATES_BY_ID_SUCCESS = 'DELETE_PALLET_TEMPLATES_BY_ID_SUCCESS' as const;
export const DELETE_PALLET_TEMPLATES_BY_ID_ERROR = 'DELETE_PALLET_TEMPLATES_BY_ID_ERROR' as const;

export const POST_CREATE_PALLET_TEMPLATES_REQUEST = 'POST_CREATE_PALLET_TEMPLATES_REQUEST' as const;
export const POST_CREATE_PALLET_TEMPLATES_SUCCESS = 'POST_CREATE_PALLET_TEMPLATES_SUCCESS' as const;
export const POST_CREATE_PALLET_TEMPLATES_ERROR = 'POST_CREATE_PALLET_TEMPLATES_ERROR' as const;

export const PUT_UPDATE_PALLET_TEMPLATES_REQUEST = 'PUT_UPDATE_PALLET_TEMPLATES_REQUEST' as const;
export const PUT_UPDATE_PALLET_TEMPLATES_SUCCESS = 'PUT_UPDATE_PALLET_TEMPLATES_SUCCESS' as const;
export const PUT_UPDATE_PALLET_TEMPLATES_ERROR = 'PUT_UPDATE_PALLET_TEMPLATES_ERROR' as const;

export const POST_UPDATE_PHOTO_PALLET_TEMPLATES_REQUEST = 'POST_UPDATE_PHOTO_PALLET_TEMPLATES_REQUEST' as const;
export const POST_UPDATE_PHOTO_PALLET_TEMPLATES_SUCCESS = 'POST_UPDATE_PHOTO_PALLET_TEMPLATES_SUCCESS' as const;
export const POST_UPDATE_PHOTO_PALLET_TEMPLATES_ERROR = 'POST_UPDATE_PHOTO_PALLET_TEMPLATES_ERROR' as const;

export const GET_PHOTO_PALLET_REQUEST = 'GET_PHOTO_PALLET_REQUEST' as const;
export const GET_PHOTO_PALLET_SUCCESS = 'GET_PHOTO_PALLET_SUCCESS' as const;
export const GET_PHOTO_PALLET_ERROR = 'GET_PHOTO_PALLET_ERROR' as const;

export const CLEAR_PHOTO_PALLET = 'CLEAR_PHOTO_PALLET' as const;
export const CLOSE_ERROR_POPUP_PALLET = 'CLOSE_ERROR_POPUP_PALLET' as const;
