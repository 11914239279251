import {
  CLOSE_ERROR_POPUP_AGGREGATION_REPORT_ERROR,
  GET_AGGREGATION_REPORT_ERROR,
  GET_AGGREGATION_REPORT_REQUEST,
  GET_AGGREGATION_REPORT_SUCCESS,
  GET_EXCEL_FILE_ERROR,
  GET_EXCEL_FILE_REQUEST,
  GET_EXCEL_FILE_SUCCESS,
  GET_GLOBAL_REPORT_ERROR,
  GET_GLOBAL_REPORT_REQUEST,
  GET_GLOBAL_REPORT_SUCCESS,
  GET_JSON_FILE_ERROR,
  GET_JSON_FILE_REQUEST,
  GET_JSON_FILE_SUCCESS,
  PUT_UPDATE_STATUS_ERROR,
  PUT_UPDATE_STATUS_REQUEST,
  PUT_UPDATE_STATUS_SUCCESS,
} from './constants';

export const getAggregationReportRequest = (dates: any) => ({
  type: GET_AGGREGATION_REPORT_REQUEST,
  payload: dates,
});
export const getAggregationReportSuccess = (data: any) => ({
  type: GET_AGGREGATION_REPORT_SUCCESS,
  payload: data,
});
export const getAggregationReportError = (error: any) => ({
  type: GET_AGGREGATION_REPORT_ERROR,
  payload: error,
});

export const getJSONFileRequest = (body: any) => ({
  type: GET_JSON_FILE_REQUEST,
  payload: body,
});
export const getJSONFileSuccess = () => ({
  type: GET_JSON_FILE_SUCCESS,
});
export const getJSONFileError = (error: any) => ({
  type: GET_JSON_FILE_ERROR,
  payload: error,
});

export const getExcelFileRequest = (body: any) => ({
  type: GET_EXCEL_FILE_REQUEST,
  payload: body,
});
export const getExcelFileSuccess = () => ({
  type: GET_EXCEL_FILE_SUCCESS,
});
export const getExcelFileError = (error: any) => ({
  type: GET_EXCEL_FILE_ERROR,
  payload: error,
});

export const getGlobalReportRequest = (body: any) => ({
  type: GET_GLOBAL_REPORT_REQUEST,
  payload: body,
});
export const getGlobalReportSuccess = () => ({
  type: GET_GLOBAL_REPORT_SUCCESS,
});
export const getGlobalReportError = (error: any) => ({
  type: GET_GLOBAL_REPORT_ERROR,
  payload: error,
});

export const putUpdateStatusRequest = (body: any) => ({
  type: PUT_UPDATE_STATUS_REQUEST,
  payload: body,
});
export const putUpdateStatusSuccess = (res: any) => ({
  type: PUT_UPDATE_STATUS_SUCCESS,
  payload: res,
});
export const putUpdateStatusError = (error: any) => ({
  type: PUT_UPDATE_STATUS_ERROR,
  payload: error,
});

export const closeErrorPopUpAggregationReport = () => ({
  type: CLOSE_ERROR_POPUP_AGGREGATION_REPORT_ERROR,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpAggregationReport>
  | ReturnType<typeof getGlobalReportRequest>
  | ReturnType<typeof getGlobalReportSuccess>
  | ReturnType<typeof getGlobalReportError>
  | ReturnType<typeof putUpdateStatusRequest>
  | ReturnType<typeof putUpdateStatusSuccess>
  | ReturnType<typeof putUpdateStatusError>
  | ReturnType<typeof getExcelFileRequest>
  | ReturnType<typeof getExcelFileSuccess>
  | ReturnType<typeof getExcelFileError>
  | ReturnType<typeof getJSONFileRequest>
  | ReturnType<typeof getJSONFileSuccess>
  | ReturnType<typeof getJSONFileError>
  | ReturnType<typeof getAggregationReportRequest>
  | ReturnType<typeof getAggregationReportSuccess>
  | ReturnType<typeof getAggregationReportError>;
