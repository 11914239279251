import {
  CLOSE_ERROR_POPUP,
  GET_SERIES_REPORT_ERROR,
  GET_SERIES_REPORT_REQUEST,
  GET_SERIES_REPORT_SUCCESS,
  GET_EXCEL_FILE_SERIES_ERROR,
  GET_EXCEL_FILE_SERIES_REQUEST,
  GET_EXCEL_FILE_SERIES_SUCCESS,
  POST_UNRESERVE_MARKING_CODES_REQUEST,
  POST_UNRESERVE_MARKING_CODES_SUCCESS,
  POST_UNRESERVE_MARKING_CODES_ERROR,
} from './constants';

export const getSeriesReportRequest = (dates: any) => ({
  type: GET_SERIES_REPORT_REQUEST,
  payload: dates,
});
export const getSeriesReportSuccess = (data: any) => ({
  type: GET_SERIES_REPORT_SUCCESS,
  payload: data,
});
export const getSeriesReportError = (error: any) => ({
  type: GET_SERIES_REPORT_ERROR,
  payload: error,
});
export const getExcelFileSeriesRequest = (body: any) => ({
  type: GET_EXCEL_FILE_SERIES_REQUEST,
  payload: body,
});
export const getExcelFileSeriesSuccess = () => ({
  type: GET_EXCEL_FILE_SERIES_SUCCESS,
});
export const getExcelFileSeriesError = (error: any) => ({
  type: GET_EXCEL_FILE_SERIES_ERROR,
  payload: error,
});

export const postUnreserveMarkingCodesRequest = (body: any) => ({
  type: POST_UNRESERVE_MARKING_CODES_REQUEST,
  payload: body,
});
export const postUnreserveMarkingCodesSuccess = (response: any) => ({
  type: POST_UNRESERVE_MARKING_CODES_SUCCESS,
  payload: response,
});
export const postUnreserveMarkingCodesError = (error: any) => ({
  type: POST_UNRESERVE_MARKING_CODES_ERROR,
  payload: error,
});

export const closeSeriesErrorPopUp = () => ({
  type: CLOSE_ERROR_POPUP,
});

export type Actions =
  | ReturnType<typeof postUnreserveMarkingCodesRequest>
  | ReturnType<typeof postUnreserveMarkingCodesSuccess>
  | ReturnType<typeof postUnreserveMarkingCodesError>
  | ReturnType<typeof closeSeriesErrorPopUp>
  | ReturnType<typeof getSeriesReportRequest>
  | ReturnType<typeof getSeriesReportSuccess>
  | ReturnType<typeof getSeriesReportError>
  | ReturnType<typeof getExcelFileSeriesRequest>
  | ReturnType<typeof getExcelFileSeriesSuccess>
  | ReturnType<typeof getExcelFileSeriesError>;
