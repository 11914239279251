import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  getExternalStatusesError,
  getExternalStatusesSuccess,
  postGenerateCodesError,
  postGenerateCodesRequest,
  postGenerateCodesSuccess,
  postImportFileError,
  postImportFileRequest,
  postImportFileSuccess,
  postValidateFileError,
  postValidateFileRequest,
  postValidateFileSuccess,
} from './actions';
import {
  GET_EXTERNAL_STATUSES_REQUEST,
  POST_GENERATE_CODES_REQUEST,
  POST_IMPORT_FILE_REQUEST,
  POST_VALIDATE_FILE_REQUEST,
} from './constants';
import { ImportCodes } from '../../../../entities/ImportCodes/service';
import { objToFormData } from '../../../../utils';

function* getExternalStatusesSaga() {
  try {
    const response: AxiosResponse = yield call(ImportCodes.getExternalStatuses);
    const statusesArray = response.data.ResponseBody;
    yield put(getExternalStatusesSuccess(statusesArray));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getExternalStatusesError(error));
  }
}

function* postValidateFileSaga(action: ReturnType<typeof postValidateFileRequest>) {
  try {
    const body = objToFormData(action.payload);
    // @ts-expect-error
    const response: any = yield call(ImportCodes.postValidateFile, body);
    const res = response.data.ResponseBody;
    yield put(postValidateFileSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postValidateFileError(error));
  }
}

function* postGenerateCodesRequestSaga(action: ReturnType<typeof postGenerateCodesRequest>) {
  try {
    const body = objToFormData(action.payload);
    // @ts-expect-error
    const response: any = yield call(ImportCodes.postValidateFile, body);
    yield put(postGenerateCodesSuccess(response));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postGenerateCodesError(error));
  }
}

function* postImportFileSaga(action: ReturnType<typeof postImportFileRequest>) {
  try {
    const response: AxiosResponse = yield call(ImportCodes.postImportFile, action.payload);
    const res = response.data.ResponseBody;
    yield put(postImportFileSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postImportFileError(error));
  }
}

export function* ImportCodesSagaWatcher() {
  yield takeLatest(POST_GENERATE_CODES_REQUEST, postGenerateCodesRequestSaga);
  yield takeLatest(GET_EXTERNAL_STATUSES_REQUEST, getExternalStatusesSaga);
  yield takeLatest(POST_VALIDATE_FILE_REQUEST, postValidateFileSaga);
  yield takeLatest(POST_IMPORT_FILE_REQUEST, postImportFileSaga);
}
