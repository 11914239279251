import {
  GET_SENDERS_INFO_REQUEST,
  GET_SENDERS_INFO_SUCCESS,
  GET_SENDERS_INFO_ERROR,
  GET_SENDERS_LOG_REQUEST,
  GET_SENDERS_LOG_SUCCESS,
  GET_SENDERS_LOG_ERROR,
  UPDATE_SENDERS_STATE_REQUEST,
  UPDATE_SENDERS_STATE_SUCCESS,
  UPDATE_SENDERS_STATE_ERROR,
  CLOSE_ERROR_POPUP_SENDERS_INFO,
  GET_BACKGROUND_PROCESSES_REQUEST,
  GET_BACKGROUND_PROCESSES_SUCCESS,
  GET_BACKGROUND_PROCESSES_ERROR,
} from './constants';

export const getSendersInfoRequest = (body: any) => ({
  type: GET_SENDERS_INFO_REQUEST,
  payload: body,
});
export const getSendersInfoSuccess = (data: any) => ({
  type: GET_SENDERS_INFO_SUCCESS,
  payload: data,
});
export const getSendersInfoError = (error: any) => ({
  type: GET_SENDERS_INFO_ERROR,
  payload: error,
});

export const getSendersLogRequest = (body: any) => ({
  type: GET_SENDERS_LOG_REQUEST,
  payload: body,
});
export const getSendersLogSuccess = () => ({
  type: GET_SENDERS_LOG_SUCCESS,
});
export const getSendersLogError = (error: any) => ({
  type: GET_SENDERS_LOG_ERROR,
  payload: error,
});

export const updateSendersStateRequest = (body: any) => ({
  type: UPDATE_SENDERS_STATE_REQUEST,
  payload: body,
});
export const updateSendersStateSuccess = (data: any) => ({
  type: UPDATE_SENDERS_STATE_SUCCESS,
  payload: data,
});
export const updateSendersStateError = (error: any) => ({
  type: UPDATE_SENDERS_STATE_ERROR,
  payload: error,
});

export const getBackgroundProcessesRequest = (body: any) => ({
  type: GET_BACKGROUND_PROCESSES_REQUEST,
  payload: body,
});
export const getBackgroundProcessesSuccess = (data: any) => ({
  type: GET_BACKGROUND_PROCESSES_SUCCESS,
  payload: data,
});
export const getBackgroundProcessesError = (error: any) => ({
  type: GET_BACKGROUND_PROCESSES_ERROR,
  payload: error,
});

export const closeErrorPopUpSendersInfo = () => ({
  type: CLOSE_ERROR_POPUP_SENDERS_INFO,
});

export type Actions =
  | ReturnType<typeof getBackgroundProcessesRequest>
  | ReturnType<typeof getBackgroundProcessesSuccess>
  | ReturnType<typeof getBackgroundProcessesError>
  | ReturnType<typeof closeErrorPopUpSendersInfo>
  | ReturnType<typeof updateSendersStateRequest>
  | ReturnType<typeof updateSendersStateSuccess>
  | ReturnType<typeof updateSendersStateError>
  | ReturnType<typeof getSendersLogRequest>
  | ReturnType<typeof getSendersLogSuccess>
  | ReturnType<typeof getSendersLogError>
  | ReturnType<typeof getSendersInfoRequest>
  | ReturnType<typeof getSendersInfoSuccess>
  | ReturnType<typeof getSendersInfoError>;
