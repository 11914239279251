import { Box, Button, Modal } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './DeactivateModal.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { receiptsSelector } from '../../services/selector';
import { Spinner } from '../../../../common/Spinner/Spinner';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 150,
  width: 500,
  bgcolor: '#ffffff !important',
  boxShadow: 24,
  padding: '30px 50px 30px 50px',
  fontSize: '13px !important',
};

interface Props {
  header: string;
  receiptId?: any;
  select?: any;
  isShow: boolean;
  isDeactivated: boolean;
  closeModal: () => void;
  deactivateHandler: () => void;
  updateTable: () => void;
  resetSelected: () => void;
}

export const DeactivateModal: React.FC<Props> = ({
  isShow,
  header,
  deactivateHandler,
  updateTable,
  closeModal,
  resetSelected,
  isDeactivated,
}) => {
  const { deactivateLoading, deactivateResponse, deactivateErrorResponses } = useSelector(receiptsSelector);

  useEffect(() => {
    if (Object.keys(deactivateResponse).length > 0) {
      closeModal();

      updateTable();

      resetSelected();
    }
  }, [deactivateResponse]);

  useEffect(() => {
    if (Object.keys(deactivateErrorResponses).length > 0) {
      closeModal();
    }
  }, [deactivateErrorResponses]);

  return (
    <Modal
      open={isShow}
      onClose={() => {
        closeModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.spinnerWrapper}>{deactivateLoading && <Spinner />}</div>
        <div className={styles.headerButtonsWrapper}>
          <CloseIcon onClick={closeModal} />
        </div>

        <span className={styles.header}>{header}</span>

        <div className={styles.buttonWrapper}>
          <Button className={styles.btn} disabled={deactivateLoading || isDeactivated} onClick={deactivateHandler}>
            Деактивувати
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
