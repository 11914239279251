import { combineReducers } from 'redux';
import { BoxTemplateReducer } from './BoxTemplates/services/reducer';
import { UnitTemplateReducer } from './UnitTemplates/services/reducer';
import { PalletTemplateReducer } from './PalletTemplates/services/reducer';

export const templatesModuleReducer = combineReducers({
  BoxTemplateReducer,
  UnitTemplateReducer,
  PalletTemplateReducer,
});

export type TemplatesModuleReducer = ReturnType<typeof templatesModuleReducer>;
