import { OutlinedInput } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

import styles from './ReadOnlyField.module.scss';

const inputStyle = {
  minWidth: 300,
  height: 30,
  fontSize: '13px !important',
  bgcolor: '#f0eeee',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

const smallInputStyle = {
  minWidth: 200,
  height: 30,
  fontSize: '13px !important',
  bgcolor: '#f0eeee',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

const largeInputStyle = {
  minWidth: 400,
  height: 30,
  fontSize: '13px !important',
  bgcolor: '#f0eeee',
  cursor: 'pointer',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '.MuiInputBase-root': {
    fontSize: '13px !important',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

interface Props {
  title: string;
  name: string;
  size?: string;
  type?: string;
  value?: any;
  validate?: boolean;
  maxLength?: number;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
}

export const ReadOnlyField: React.FC<Props> = ({
  title,
  name,
  size,
  value,
  validate,
  maxLength,
  disabled,
  type,
  required,
  ...rest
}) => {
  const { handleChange, setFieldValue } = useFormikContext<any>();

  const zeroCharValidation = (val: any) => {
    let v;
    val == 0 ? (v = '') : (v = val);
    return v.slice(0, maxLength);
  };

  return (
    <div
      className={
        size === 'small' ? styles.smallInputElement : size === 'large' ? styles.largeInputElement : styles.inputElement
      }
    >
      <span className={styles.title}>
        {title}:{required ? <em className={styles.required}>*</em> : null}
      </span>
      {type !== 'number' ? (
        <OutlinedInput
          defaultValue=""
          autoComplete="off"
          sx={size === 'small' ? smallInputStyle : size === 'large' ? largeInputStyle : inputStyle}
          name={name}
          error={validate && (value === undefined || value === null || value.toString().trim().length === 0)}
          onChange={handleChange}
          value={value}
          inputProps={{ maxLength }}
          disabled={disabled}
          {...rest}
        />
      ) : (
        <OutlinedInput
          defaultValue=""
          autoComplete="off"
          sx={size === 'small' ? smallInputStyle : size === 'large' ? largeInputStyle : inputStyle}
          name={name}
          error={validate && (value === undefined || value === null || value.toString().trim().length === 0)}
          onChange={(event) => setFieldValue(name, zeroCharValidation(event.target.value))}
          value={value}
          inputProps={{ type, min: 1 }}
          disabled={disabled}
          {...rest}
        />
      )}
    </div>
  );
};
