import { AxiosResponse } from 'axios';
import { instance as axios } from 'api/axios';

export class ImportCodes {
  public static async getExternalStatuses() {
    const url = '/MarkingCode/ExternalStatuses/GetAll';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async postValidateFile(body: FormData) {
    const url = '/MarkingCode/Upload';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async postImportFile(body: FormData) {
    const url = '/MarkingCode/Apply';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }
}
