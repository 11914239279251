import { all, fork } from 'redux-saga/effects';
import { codeBankModuleSaga } from './modules/Bank/codeBankModuleSaga';
import { entitiesModuleSaga } from './entities/moduleSaga';
import { handbookModuleSaga } from './modules/Handbook/handbookModuleSaga';
import { templatesModuleSaga } from './modules/Templates/templatesModuleSaga';
import { operationsModuleSaga } from './modules/OperationsAndTools/operationsModuleSaga';
import { documentModuleSaga } from './modules/Documents/documentModuleSaga';
import { eventsLogModuleSaga } from './modules/EventsLog/eventsLogModuleSaga';
import { authModuleSaga } from './modules/Auth/moduleSaga';
import { mainModuleSaga } from './modules/Main/mainModuleSaga';
import { aboutModuleSaga } from './modules/About/aboutModuleSaga';

const sagas: Array<() => Generator> = [
  ...documentModuleSaga,
  ...codeBankModuleSaga,
  ...entitiesModuleSaga,
  ...handbookModuleSaga,
  ...templatesModuleSaga,
  ...operationsModuleSaga,
  ...eventsLogModuleSaga,
  ...authModuleSaga,
  ...mainModuleSaga,
  ...aboutModuleSaga,
];

export default function* rootSaga() {
  const globalSagasForks = sagas.map((saga) => fork(saga));
  yield all([...globalSagasForks]);
}
