export const GET_EXTERNAL_STATUSES_REQUEST = 'GET_EXTERNAL_STATUSES_REQUEST' as const;
export const GET_EXTERNAL_STATUSES_SUCCESS = 'GET_EXTERNAL_STATUSES_SUCCESS' as const;
export const GET_EXTERNAL_STATUSES_ERROR = 'GET_EXTERNAL_STATUSES_ERROR' as const;

export const POST_VALIDATE_FILE_REQUEST = 'POST_VALIDATE_FILE_REQUEST' as const;
export const POST_VALIDATE_FILE_SUCCESS = 'POST_VALIDATE_FILE_SUCCESS' as const;
export const POST_VALIDATE_FILE_ERROR = 'POST_VALIDATE_FILE_ERROR' as const;

export const POST_IMPORT_FILE_REQUEST = 'POST_IMPORT_FILE_REQUEST' as const;
export const POST_IMPORT_FILE_SUCCESS = 'POST_IMPORT_FILE_SUCCESS' as const;
export const POST_IMPORT_FILE_ERROR = 'POST_IMPORT_FILE_ERROR' as const;

export const POST_GENERATE_CODES_REQUEST = 'POST_GENERATE_CODES_REQUEST' as const;
export const POST_GENERATE_CODES_SUCCESS = 'POST_GENERATE_CODES_SUCCESS' as const;
export const POST_GENERATE_CODES_ERROR = 'POST_GENERATE_CODES_ERROR' as const;

export const CLOSE_ERROR_MODAL = 'CLOSE_ERROR_MODAL' as const;
