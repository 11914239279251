import {
  CLOSE_ERROR_MODAL,
  CLOSE_ERROR_POPUP_PACKING,
  DELETE_PACKING_ERROR,
  DELETE_PACKING_REQUEST,
  DELETE_PACKING_SUCCESS,
  GET_ALL_PACKING_ERROR,
  GET_ALL_PACKING_REQUEST,
  GET_ALL_PACKING_SUCCESS,
  GET_PACKING_BY_ID_ERROR,
  GET_PACKING_BY_ID_REQUEST,
  GET_PACKING_BY_ID_SUCCESS,
  POST_ADD_NEW_PACKING_ERROR,
  POST_ADD_NEW_PACKING_REQUEST,
  POST_ADD_NEW_PACKING_SUCCESS,
  PUT_UPDATE_PACKING_ERROR,
  PUT_UPDATE_PACKING_REQUEST,
  PUT_UPDATE_PACKING_SUCCESS,
} from './constants';

export const getAllPackingsRequest = () => ({
  type: GET_ALL_PACKING_REQUEST,
});
export const getAllPackingsSuccess = (data: any) => ({
  type: GET_ALL_PACKING_SUCCESS,
  payload: data,
});
export const getAllPackingsError = (error: any) => ({
  type: GET_ALL_PACKING_ERROR,
  payload: error,
});

export const getPackingByIdRequest = (body: any) => ({
  type: GET_PACKING_BY_ID_REQUEST,
  payload: body,
});
export const getPackingByIdSuccess = (data: any) => ({
  type: GET_PACKING_BY_ID_SUCCESS,
  payload: data,
});
export const getPackingByIdError = (error: any) => ({
  type: GET_PACKING_BY_ID_ERROR,
  payload: error,
});

export const postAddNewPackingRequest = () => ({
  type: POST_ADD_NEW_PACKING_REQUEST,
});
export const postAddNewPackingSuccess = (data: any) => ({
  type: POST_ADD_NEW_PACKING_SUCCESS,
  payload: data,
});
export const postAddNewPackingError = (error: any) => ({
  type: POST_ADD_NEW_PACKING_ERROR,
  payload: error,
});

export const putUpdatePackingRequest = (body: any) => ({
  type: PUT_UPDATE_PACKING_REQUEST,
  payload: body,
});
export const putUpdatePackingSuccess = (data: any) => ({
  type: PUT_UPDATE_PACKING_SUCCESS,
  payload: data,
});
export const putUpdatePackingError = (error: any) => ({
  type: PUT_UPDATE_PACKING_ERROR,
  payload: error,
});

export const deletePackingRequest = (body: any) => ({
  type: DELETE_PACKING_REQUEST,
  payload: body,
});
export const deletePackingSuccess = (data: any) => ({
  type: DELETE_PACKING_SUCCESS,
  payload: data,
});
export const deletePackingError = (error: any) => ({
  type: DELETE_PACKING_ERROR,
  payload: error,
});

export const closeErrorPopUpPacking = () => ({
  type: CLOSE_ERROR_POPUP_PACKING,
});

export const closeErrorModal = () => ({
  type: CLOSE_ERROR_MODAL,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpPacking>
  | ReturnType<typeof closeErrorModal>
  | ReturnType<typeof deletePackingRequest>
  | ReturnType<typeof deletePackingSuccess>
  | ReturnType<typeof deletePackingError>
  | ReturnType<typeof putUpdatePackingRequest>
  | ReturnType<typeof putUpdatePackingSuccess>
  | ReturnType<typeof putUpdatePackingError>
  | ReturnType<typeof getPackingByIdRequest>
  | ReturnType<typeof getPackingByIdSuccess>
  | ReturnType<typeof getPackingByIdError>
  | ReturnType<typeof getAllPackingsRequest>
  | ReturnType<typeof getAllPackingsSuccess>
  | ReturnType<typeof getAllPackingsError>
  | ReturnType<typeof postAddNewPackingRequest>
  | ReturnType<typeof postAddNewPackingSuccess>
  | ReturnType<typeof postAddNewPackingError>;
