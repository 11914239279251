import { Actions } from './actions';
import {
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  GET_USER_REQUEST,
  CLOSE_ERROR_POPUP_MAIN,
  POST_AUTHENTICATION_LOGIN_REQUEST,
  POST_AUTHENTICATION_LOGIN_SUCCESS,
  POST_AUTHENTICATION_LOGIN_ERROR,
  POST_AUTHENTICATION_LOGOUT_REQUEST,
  POST_AUTHENTICATION_LOGOUT_SUCCESS,
  POST_AUTHENTICATION_LOGOUT_ERROR,
} from './constants';

const initialState = {
  loading: false as boolean,
  error: null as Error | null,

  userResponse: [] as any,
  userErrorResponses: {} as any,

  logoutErrorResponses: {} as any,
};

export type UserState = typeof initialState;

export const UserReducer = (state: UserState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_USER_REQUEST: {
      return {
        ...state,
        loading: true,
        userResponse: {},
        userErrorResponses: {},
        logoutErrorResponses: {},
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        userResponse: action.payload,
      };
    }
    case GET_USER_ERROR: {
      return {
        ...state,
        loading: false,
        userErrorResponses: action.payload,
      };
    }

    case POST_AUTHENTICATION_LOGIN_REQUEST: {
      return {
        ...state,
      };
    }
    case POST_AUTHENTICATION_LOGIN_SUCCESS: {
      return {
        ...state,
      };
    }
    case POST_AUTHENTICATION_LOGIN_ERROR: {
      return {
        ...state,
      };
    }

    case POST_AUTHENTICATION_LOGOUT_REQUEST: {
      return {
        ...state,
        logoutErrorResponses: {},
      };
    }
    case POST_AUTHENTICATION_LOGOUT_SUCCESS: {
      return {
        ...state,
      };
    }
    case POST_AUTHENTICATION_LOGOUT_ERROR: {
      return {
        ...state,
        logoutErrorResponses: action.payload,
      };
    }

    case CLOSE_ERROR_POPUP_MAIN: {
      return {
        ...state,
        loading: false,
        userErrorResponses: {},
        logoutErrorResponses: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
