import { Actions } from './actions';
import {
  CLEAR_PHOTO_PALLET,
  CLOSE_ERROR_POPUP_PALLET,
  GET_PALLET_TEMPLATES_BY_ID_REQUEST,
  GET_PALLET_TEMPLATES_BY_ID_SUCCESS,
  GET_PALLET_TEMPLATES_ERROR,
  GET_PALLET_TEMPLATES_REQUEST,
  GET_PALLET_TEMPLATES_SUCCESS,
  GET_PHOTO_PALLET_REQUEST,
  GET_PHOTO_PALLET_SUCCESS,
  POST_CREATE_PALLET_TEMPLATES_SUCCESS,
  PUT_UPDATE_PALLET_TEMPLATES_SUCCESS,
} from './constants';

const initialState = {
  photoLoading: false as boolean,
  loading: false as boolean,
  error: null as Error | null,
  palletTemplates: [] as any,
  createdPalletTemplates: {} as any,
  selectedPalletTemplates: {} as any,
  updatePalletResponse: {} as any,
  getPalletTemplatesError: {} as any,

  photoPallet: null as any,
};

export type PalletTemplateState = typeof initialState;

export const PalletTemplateReducer = (state: PalletTemplateState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_PALLET_TEMPLATES_REQUEST: {
      return {
        ...state,
        loading: true,
        selectedPalletTemplates: {},
        getPalletTemplatesError: {} as any,
      };
    }
    case GET_PALLET_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        palletTemplates: action.payload,
      };
    }
    case GET_PALLET_TEMPLATES_ERROR: {
      return {
        ...state,
        loading: false,
        getPalletTemplatesError: action.payload,
      };
    }
    case POST_CREATE_PALLET_TEMPLATES_SUCCESS: {
      return {
        ...state,
        createdPalletTemplates: action.payload,
      };
    }
    case GET_PALLET_TEMPLATES_BY_ID_REQUEST: {
      return {
        ...state,
        loading: true,
        selectedPalletTemplates: {},
      };
    }
    case GET_PALLET_TEMPLATES_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedPalletTemplates: action.payload,
        photoPallet: null,
      };
    }
    case PUT_UPDATE_PALLET_TEMPLATES_SUCCESS: {
      return {
        ...state,
        updatePalletResponse: action.payload,
      };
    }
    case GET_PHOTO_PALLET_REQUEST: {
      return {
        ...state,
        photoLoading: true,
        photoPallet: null,
      };
    }
    case GET_PHOTO_PALLET_SUCCESS: {
      return {
        ...state,
        photoLoading: false,
        photoPallet: action.payload,
      };
    }
    case CLEAR_PHOTO_PALLET: {
      return {
        ...state,
        photoPallet: null,
      };
    }
    case CLOSE_ERROR_POPUP_PALLET: {
      return {
        ...state,
        getPalletTemplatesError: {} as any,
      };
    }
    default: {
      return { ...state };
    }
  }
};
