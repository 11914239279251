import {
  CLEAR_PHOTO_PALLET,
  CLOSE_ERROR_POPUP_PALLET,
  DELETE_PALLET_TEMPLATES_BY_ID_ERROR,
  DELETE_PALLET_TEMPLATES_BY_ID_REQUEST,
  DELETE_PALLET_TEMPLATES_BY_ID_SUCCESS,
  GET_PALLET_TEMPLATES_BY_ID_ERROR,
  GET_PALLET_TEMPLATES_BY_ID_REQUEST,
  GET_PALLET_TEMPLATES_BY_ID_SUCCESS,
  GET_PALLET_TEMPLATES_ERROR,
  GET_PALLET_TEMPLATES_REQUEST,
  GET_PALLET_TEMPLATES_SUCCESS,
  GET_PHOTO_PALLET_ERROR,
  GET_PHOTO_PALLET_REQUEST,
  GET_PHOTO_PALLET_SUCCESS,
  POST_CREATE_PALLET_TEMPLATES_ERROR,
  POST_CREATE_PALLET_TEMPLATES_REQUEST,
  POST_CREATE_PALLET_TEMPLATES_SUCCESS,
  POST_UPDATE_PHOTO_PALLET_TEMPLATES_ERROR,
  POST_UPDATE_PHOTO_PALLET_TEMPLATES_REQUEST,
  POST_UPDATE_PHOTO_PALLET_TEMPLATES_SUCCESS,
  PUT_UPDATE_PALLET_TEMPLATES_ERROR,
  PUT_UPDATE_PALLET_TEMPLATES_REQUEST,
  PUT_UPDATE_PALLET_TEMPLATES_SUCCESS,
} from './constants';

export const getPalletTemplatesRequest = (body: any) => ({
  type: GET_PALLET_TEMPLATES_REQUEST,
  payload: body,
});
export const getPalletTemplatesSuccess = (data: any) => ({
  type: GET_PALLET_TEMPLATES_SUCCESS,
  payload: data,
});
export const getPalletTemplatesError = (error: any) => ({
  type: GET_PALLET_TEMPLATES_ERROR,
  payload: error,
});

export const postCreatePalletTemplatesRequest = () => ({
  type: POST_CREATE_PALLET_TEMPLATES_REQUEST,
});
export const postCreatePalletTemplatesSuccess = (data: any) => ({
  type: POST_CREATE_PALLET_TEMPLATES_SUCCESS,
  payload: data,
});
export const postCreatePalletTemplatesError = (error: any) => ({
  type: POST_CREATE_PALLET_TEMPLATES_ERROR,
  payload: error,
});

export const putUpdatePalletTemplatesRequest = (data: any) => ({
  type: PUT_UPDATE_PALLET_TEMPLATES_REQUEST,
  payload: data,
});
export const putUpdatePalletTemplatesSuccess = (data: any) => ({
  type: PUT_UPDATE_PALLET_TEMPLATES_SUCCESS,
  payload: data,
});
export const putUpdatePalletTemplatesError = (error: any) => ({
  type: PUT_UPDATE_PALLET_TEMPLATES_ERROR,
  payload: error,
});

export const getPalletTemplatesByIdRequest = (body: any) => ({
  type: GET_PALLET_TEMPLATES_BY_ID_REQUEST,
  payload: body,
});
export const getPalletTemplatesByIdSuccess = (data: any) => ({
  type: GET_PALLET_TEMPLATES_BY_ID_SUCCESS,
  payload: data,
});
export const getPalletTemplatesByIdError = (error: any) => ({
  type: GET_PALLET_TEMPLATES_BY_ID_ERROR,
  payload: error,
});

export const deletePalletTemplatesByIdRequest = (body: any) => ({
  type: DELETE_PALLET_TEMPLATES_BY_ID_REQUEST,
  payload: body,
});
export const deletePalletTemplatesByIdSuccess = (data: any) => ({
  type: DELETE_PALLET_TEMPLATES_BY_ID_SUCCESS,
  payload: data,
});
export const deletePalletTemplatesByIdError = (error: any) => ({
  type: DELETE_PALLET_TEMPLATES_BY_ID_ERROR,
  payload: error,
});

export const postUpdatePhotoPalletTemplatesRequest = (body: any) => ({
  type: POST_UPDATE_PHOTO_PALLET_TEMPLATES_REQUEST,
  payload: body,
});
export const postUpdatePhotoPalletTemplatesSuccess = (data: any) => ({
  type: POST_UPDATE_PHOTO_PALLET_TEMPLATES_SUCCESS,
  payload: data,
});
export const postUpdatePhotoPalletTemplatesError = (error: any) => ({
  type: POST_UPDATE_PHOTO_PALLET_TEMPLATES_ERROR,
  payload: error,
});

export const getPhotoPalletRequest = (body: any) => ({
  type: GET_PHOTO_PALLET_REQUEST,
  payload: body,
});
export const getPhotoPalletSuccess = (body: any) => ({
  type: GET_PHOTO_PALLET_SUCCESS,
  payload: body,
});
export const getPhotoPalletError = (body: any) => ({
  type: GET_PHOTO_PALLET_ERROR,
  payload: body,
});

export const clearPhotoPallet = () => ({
  type: CLEAR_PHOTO_PALLET,
});
export const closeErrorPopUpPallet = () => ({
  type: CLOSE_ERROR_POPUP_PALLET,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpPallet>
  | ReturnType<typeof clearPhotoPallet>
  | ReturnType<typeof getPhotoPalletRequest>
  | ReturnType<typeof getPhotoPalletSuccess>
  | ReturnType<typeof getPhotoPalletError>
  | ReturnType<typeof postUpdatePhotoPalletTemplatesRequest>
  | ReturnType<typeof postUpdatePhotoPalletTemplatesSuccess>
  | ReturnType<typeof postUpdatePhotoPalletTemplatesError>
  | ReturnType<typeof deletePalletTemplatesByIdRequest>
  | ReturnType<typeof deletePalletTemplatesByIdSuccess>
  | ReturnType<typeof deletePalletTemplatesByIdError>
  | ReturnType<typeof getPalletTemplatesByIdRequest>
  | ReturnType<typeof getPalletTemplatesByIdSuccess>
  | ReturnType<typeof getPalletTemplatesByIdError>
  | ReturnType<typeof putUpdatePalletTemplatesRequest>
  | ReturnType<typeof putUpdatePalletTemplatesSuccess>
  | ReturnType<typeof putUpdatePalletTemplatesError>
  | ReturnType<typeof postCreatePalletTemplatesRequest>
  | ReturnType<typeof postCreatePalletTemplatesSuccess>
  | ReturnType<typeof postCreatePalletTemplatesError>
  | ReturnType<typeof getPalletTemplatesRequest>
  | ReturnType<typeof getPalletTemplatesSuccess>
  | ReturnType<typeof getPalletTemplatesError>;
