import DownloadIcon from '@mui/icons-material/Download';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { noRow } from '../../shared/NoRow/NoRow';

import styles from '../DataTable/DataTable.module.scss';

const style = {
  backgroundColor: '#ffffff',
  fontSize: '13px !important',
  '.MuiTablePagination-selectLabel': {
    marginBottom: '0 !important',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    overflow: 'hidden !important',
    lineHeight: '14px !important',
    whiteSpace: 'normal !important',
  },
  '.MuiTablePagination-displayedRows': {
    marginBottom: '0 !important',
  },
  '.MuiDataGrid-root': {
    fontSize: '13px !important',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: ' none',
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: '#ffffff',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#ffffff',
  },
  '& .MuiDataGrid-row:focus': {
    backgroundColor: '#ffffff',
  },
  '& .MuiDataGrid-row.Mui-selected:hover': {
    backgroundColor: '#ffffff',
  },
};

interface Props {
  data: any;
}

export const InventoryListTable = ({ data }: Props) => {
  const [pageSize, setPageSize] = React.useState(10);

  return (
    <div style={{ height: 527, width: '100%', marginTop: '20px' }}>
      <DataGrid
        rows={data}
        rowHeight={40}
        columns={[
          { field: 'EAN13', headerName: 'ID списку', width: 140 },
          { field: 'countryCode', headerName: 'Номер документу', width: 70 },
          { field: 'internalName', headerName: 'Дата створення', minWidth: 100, flex: 0.3 },
          { field: 'internalCode', headerName: 'Загальна кількість одиниць, фактична, ш', width: 145 },
          {
            field: 'importedCodesCount',
            headerName: 'Кількість ящиків, шт',
            width: 165,
          },
          { field: 'availableToPrint', headerName: 'Створено', width: 155 },
          { field: 'allowedToPrint', headerName: 'Місце', width: 155 },
          { field: 'inPrint', headerName: 'Підстава', width: 115 },
          {
            headerName: '',
            field: 'button',
            minWidth: 30,
            sortable: false,
            filterable: false,
            align: 'right',
            flex: 0.4,
            renderCell: () => <DownloadIcon className={styles.icon} />,
          },
        ]}
        getRowId={(row: any) => row.EAN13}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        sx={style}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 50, 100]}
        pagination
        components={{
          NoRowsOverlay: noRow,
        }}
      />
    </div>
  );
};
