import React from 'react';
import { Button } from '@mui/material';
import ComplianceIcon from '../../../icons/compliance.svg';

import styles from './ComplianceModal.module.scss';
import Dialog from '@mui/material/Dialog';

interface Props {
  isShown: boolean;
  closeModal: () => void;
  email: string;
}

export const ComplianceModal: React.FC<Props> = ({ isShown, closeModal, email }) => {
  const onSubmitHandler = async () => {
    localStorage.setItem('isComplianceAccepted', 'accepted');

    closeModal();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { backgroundColor: '#DDDDDD', width: '80%', maxHeight: 550 } }}
      maxWidth="sm"
      open={isShown}
    >
      <div className={styles.wrapper}>
        <img className={styles.icon} src={ComplianceIcon} alt="" />

        <span className={styles.title}>GMP, СFR 21 part 11 compliance</span>

        <span className={styles.text}>Електронна угода з користувачем.</span>

        <ol className={styles.list}>
          <li className={styles.li}>
            Дана система журналює дії користувача, час і інформацію про користувача, дані зберігаються 1 рік.
          </li>
          <li className={styles.li}>
            Свідченням дії користувача є персональний електронний підпис на основі пари логін/пароль; електронний підпис
            еквівалентний ‘живому підпису’.
          </li>
          <li className={styles.li}>
            Дана система веде збір, обробку і зберігання персональних даних, а саме: призвище на ім’я, логін, e-mail.
          </li>
          <li className={styles.li}>
            Функції цієї системи і дані складать корпоративну таємницю і не підлягають розголошенню за межами компанії.
          </li>
        </ol>

        <div className={styles.submitWrapper}>
          <span className={styles.submitText}>Підтвердити угоду і підписати персональним електронним підписом:</span>

          <Button variant="contained" className={styles.btn} onClick={onSubmitHandler}>
            <span className={styles.btnText}>{email}</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
