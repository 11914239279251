export const GET_RECEIPTS_REQUEST = 'GET_RECEIPTS_REQUEST' as const;
export const GET_RECEIPTS_SUCCESS = 'GET_RECEIPTS_SUCCESS' as const;
export const GET_RECEIPTS_ERROR = 'GET_RECEIPTS_ERROR' as const;

export const DELETE_DEACTIVATE_REQUEST = 'DELETE_DEACTIVATE_REQUEST' as const;
export const DELETE_DEACTIVATE_SUCCESS = 'DELETE_DEACTIVATE_SUCCESS' as const;
export const DELETE_DEACTIVATE_ERROR = 'DELETE_DEACTIVATE_ERROR' as const;

export const GET_PRODUCT_LIST_REQUEST = 'GET_PRODUCT_LIST_REQUEST' as const;
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS' as const;
export const GET_PRODUCT_LIST_ERROR = 'GET_PRODUCT_LIST_ERROR' as const;

export const POST_CREATE_RECEIPT_REQUEST = 'POST_CREATE_RECEIPT_REQUEST' as const;
export const POST_CREATE_RECEIPT_SUCCESS = 'POST_CREATE_RECEIPT_SUCCESS' as const;
export const POST_CREATE_RECEIPT_ERROR = 'POST_CREATE_RECEIPT_ERROR' as const;

export const CLOSE_ERROR_POPUP_RECEIPTS = 'CLOSE_ERROR_POPUP_RECEIPTS' as const;

export const GET_RECEIPT_PDF_FILE_REQUEST = 'GET_RECEIPT_PDF_FILE_REQUEST' as const;
export const GET_RECEIPT_PDF_FILE_SUCCESS = 'GET_RECEIPT_PDF_FILE_SUCCESS' as const;
export const GET_RECEIPT_PDF_FILE_ERROR = 'GET_RECEIPT_PDF_FILE_ERROR' as const;
