import { Actions } from './actions';
import {
  GET_LICENSED_DEVICES_REQUEST,
  GET_LICENSED_DEVICES_SUCCESS,
  GET_LICENSED_DEVICES_ERROR,
  CLOSE_ERROR_POPUP_LICENSED_DEVICES,
} from './constants';

const initialState = {
  loading: false as boolean,
  error: null as Error | null,

  licensedDevicesResponse: [] as any,
  licensedDevicesErrorResponses: {} as any,
};

export type LicensedDevicesState = typeof initialState;

export const LicensedDevicesReducer = (state: LicensedDevicesState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_LICENSED_DEVICES_REQUEST: {
      return {
        ...state,
        loading: true,
        licensedDevicesResponse: [],
        licensedDevicesErrorResponses: {},
      };
    }
    case GET_LICENSED_DEVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        licensedDevicesResponse: action.payload,
      };
    }
    case GET_LICENSED_DEVICES_ERROR: {
      return {
        ...state,
        loading: false,
        licensedDevicesErrorResponses: action.payload,
      };
    }

    case CLOSE_ERROR_POPUP_LICENSED_DEVICES: {
      return {
        ...state,
        loading: false,
        licensedDevicesErrorResponses: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
