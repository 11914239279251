import {
  CLOSE_ERROR_MODAL,
  CLOSE_ERROR_POPUP_WITHDRAWAL,
  DELETE_WITHDRAWAL_ERROR,
  DELETE_WITHDRAWAL_REQUEST,
  DELETE_WITHDRAWAL_SUCCESS,
  GET_ALL_WITHDRAWAL_ERROR,
  GET_ALL_WITHDRAWAL_REQUEST,
  GET_ALL_WITHDRAWAL_SUCCESS,
  POST_ADD_NEW_WITHDRAWAL_ERROR,
  POST_ADD_NEW_WITHDRAWAL_REQUEST,
  POST_ADD_NEW_WITHDRAWAL_SUCCESS,
  PUT_UPDATE_WITHDRAWAL_ERROR,
  PUT_UPDATE_WITHDRAWAL_REQUEST,
  PUT_UPDATE_WITHDRAWAL_SUCCESS,
} from './constants';

export const getAllWithdrawalsRequest = () => ({
  type: GET_ALL_WITHDRAWAL_REQUEST,
});
export const getAllWithdrawalsSuccess = (data: any) => ({
  type: GET_ALL_WITHDRAWAL_SUCCESS,
  payload: data,
});
export const getAllWithdrawalsError = (error: any) => ({
  type: GET_ALL_WITHDRAWAL_ERROR,
  payload: error,
});

export const postAddNewWithdrawalRequest = (body: any) => ({
  type: POST_ADD_NEW_WITHDRAWAL_REQUEST,
  payload: body,
});
export const postAddNewWithdrawalSuccess = (data: any) => ({
  type: POST_ADD_NEW_WITHDRAWAL_SUCCESS,
  payload: data,
});
export const postAddNewWithdrawalError = (error: any) => ({
  type: POST_ADD_NEW_WITHDRAWAL_ERROR,
  payload: error,
});

export const putUpdateWithdrawalRequest = (body: any) => ({
  type: PUT_UPDATE_WITHDRAWAL_REQUEST,
  payload: body,
});
export const putUpdateWithdrawalSuccess = (data: any) => ({
  type: PUT_UPDATE_WITHDRAWAL_SUCCESS,
  payload: data,
});
export const putUpdateWithdrawalError = (error: any) => ({
  type: PUT_UPDATE_WITHDRAWAL_ERROR,
  payload: error,
});

export const deleteWithdrawalRequest = (body: any) => ({
  type: DELETE_WITHDRAWAL_REQUEST,
  payload: body,
});
export const deleteWithdrawalSuccess = (data: any) => ({
  type: DELETE_WITHDRAWAL_SUCCESS,
  payload: data,
});
export const deleteWithdrawalError = (error: any) => ({
  type: DELETE_WITHDRAWAL_ERROR,
  payload: error,
});

export const closeErrorPopUpWithdrawal = () => ({
  type: CLOSE_ERROR_POPUP_WITHDRAWAL,
});

export const closeErrorModal = () => ({
  type: CLOSE_ERROR_MODAL,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpWithdrawal>
  | ReturnType<typeof closeErrorModal>
  | ReturnType<typeof deleteWithdrawalRequest>
  | ReturnType<typeof deleteWithdrawalSuccess>
  | ReturnType<typeof deleteWithdrawalError>
  | ReturnType<typeof putUpdateWithdrawalRequest>
  | ReturnType<typeof putUpdateWithdrawalSuccess>
  | ReturnType<typeof putUpdateWithdrawalError>
  | ReturnType<typeof getAllWithdrawalsRequest>
  | ReturnType<typeof getAllWithdrawalsSuccess>
  | ReturnType<typeof getAllWithdrawalsError>
  | ReturnType<typeof postAddNewWithdrawalRequest>
  | ReturnType<typeof postAddNewWithdrawalSuccess>
  | ReturnType<typeof postAddNewWithdrawalError>;
