import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../common/Spinner/Spinner';
import styles from './LicensedDevices.module.scss';
import { getLicensedDevicesRequest } from './services/actions';
import { licensedDevicesSelector } from './services/selector';
import { useNavigate } from 'react-router-dom';
import ErrorResponse from '../../shared/ErrorResponse';
import Enabled from '../../../icons/task_alt.svg';
import Disabled from '../../../icons/do_not_disturb_on.svg';
import InputSearch from '../../common/InputSearch';
import { style } from '../../common/DataTable/style';
import { Tooltip } from '@mui/material';

export const LicensedDevices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { licensedDevicesResponse, licensedDevicesErrorResponses, loading } = useSelector(licensedDevicesSelector);
  const [pageSize, setPageSize] = React.useState(16);

  const [tableData, setTableData] = useState<any[]>(licensedDevicesResponse);

  const renderCustomCell = (element: any) => {
    switch (element.isEnabled) {
      case null:
        return <div>{''}</div>;
      case true:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Доступ дозвалено (Enabled)" placement="top">
              <img src={Enabled} alt="" />
            </Tooltip>
          </div>
        );
      case false:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Доступ заблоковано (Disabled)" placement="top">
              <img src={Disabled} alt="" />
            </Tooltip>
          </div>
        );
      default:
        return <div>{''}</div>;
    }
  };

  useEffect(() => {
    dispatch(getLicensedDevicesRequest());
  }, []);

  useEffect(() => {
    setTableData(licensedDevicesResponse);
  }, [licensedDevicesResponse]);

  useEffect(() => {
    if (Object.keys(licensedDevicesErrorResponses).length !== 0 && licensedDevicesErrorResponses?.status === 403) {
      navigate('/error');
    }
  }, [licensedDevicesErrorResponses]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(licensedDevicesErrorResponses).length !== 0 ? (
        <ErrorResponse error={licensedDevicesErrorResponses} />
      ) : null}
      <div className={styles.loadingGroup}>
        <span className={styles.title}>Ліцензовані підключення</span>
        {loading ? <Spinner /> : null}
      </div>
      <span>
        Список задіяних підключень що вимагають ліцензії, дозволений максимум {''}
        {process.env.REACT_APP_LICENSED_CONNECTIONS}
      </span>
      <section className={styles.searchSection}>
        <div className={styles.searchGroup}>
          <span>Фільтр по всім полям:</span>
          <InputSearch data={licensedDevicesResponse} setTableData={setTableData} />
        </div>
      </section>
      <section className={styles.main}>
        <div style={{ height: 646, width: '100%' }}>
          <DataGrid
            localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
            headerHeight={31}
            rowHeight={35}
            rows={tableData}
            columns={[
              {
                field: 'isEnabled',
                headerName: 'Ліцензія',
                headerAlign: 'center',
                width: 90,
                align: 'center',
                renderCell: (params: GridRenderCellParams<Date>) => renderCustomCell(params.row),
              },
              { field: 'clientId', headerName: 'Station ID', width: 180 },
              { field: 'lastUseVersion', headerName: 'Версія модуля GATEWAY', width: 200 },
              { field: 'clientDescription', headerName: 'Виробнича лінія', minWidth: 200 },
              { field: 'deviceType', headerName: 'Параметри і опис', width: 330 },
              {
                field: 'enter',
                headerName: 'Останній вхід в систему',
                width: 200,
                renderCell: (params: GridRenderCellParams<Date>) => (
                  <span>
                    {params.row.lastUseDate ? moment(params.row.lastUseDate).format('DD-MM-YYYY HH:mm:ss') : ''}
                  </span>
                ),
              },
              { field: 'lastUseLogin', headerName: 'Користувач', minWidth: 300, flex: 0.1 },
            ]}
            sx={style}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            disableColumnMenu
            disableSelectionOnClick
            disableColumnSelector
            componentsProps={{
              pagination: {
                SelectProps: {
                  MenuProps: {
                    sx: {
                      '& .MuiMenuItem-root': {
                        fontSize: 14,
                        bgcolor: '#ffffff',
                      },
                    },
                    PaperProps: {
                      sx: {
                        bgcolor: '#ffffff',
                      },
                    },
                  },
                },
              },
            }}
          />
        </div>
      </section>
    </div>
  );
};
