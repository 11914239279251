import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainPageSelector } from '../../Main/services/selector';
import { instance } from '../../../api/axios';
import { getUserRequest, postAuthenticationLoginRequest } from '../../Main/services/actions';

interface Props {
  open: boolean;
  openComplianceModal: () => void;
  setOpen: React.Dispatch<React.SetStateAction<any>>;
}

export const SelectCidDialog: React.FC<Props> = ({ open, setOpen, openComplianceModal }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const radioGroupRef = useRef<HTMLElement>(null);

  const { userResponse } = useSelector(mainPageSelector);

  useEffect(() => {
    const cid = localStorage.getItem('cid');
    const entraGroupId = localStorage.getItem('entraGroupId');

    if (userResponse.length !== 0 && userResponse.mustUsedId === null && !cid && !entraGroupId) {
      setOpen(true);
    }

    if (Object.keys(userResponse).length !== 0 && userResponse.mustUsedId !== null && !cid && !entraGroupId) {
      applyCompany(userResponse.mustUsedId);
    }
  }, [userResponse, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleSubmit = () => {
    const selectedValue = userResponse.cidList.find((cid: any) => cid.companyName === value);

    // @ts-expect-error
    instance.interceptors.request.use(function (config) {
      return {
        ...config,
        headers: {
          ...config.headers,
          'x-cid': `${selectedValue.cid}`,
          'x-entraGroupId': `${selectedValue.entraGroupId}`,
        },
      };
    });

    localStorage.setItem('cid', selectedValue.cid);
    localStorage.setItem('entraGroupId', selectedValue.entraGroupId);
    localStorage.setItem('account', selectedValue.companyName);

    dispatch(postAuthenticationLoginRequest());

    setOpen(false);

    dispatch(getUserRequest());

    openComplianceModal();
  };

  const applyCompany = (id: number) => {
    const company = userResponse.cidList.find((cid: any) => cid.id === id);

    // @ts-expect-error
    instance.interceptors.request.use(function (config) {
      return {
        ...config,
        headers: {
          ...config.headers,
          'x-cid': `${company.cid}`,
          'x-entraGroupId': `${company.entraGroupId}`,
        },
      };
    });

    localStorage.setItem('cid', company.cid);
    localStorage.setItem('entraGroupId', company.entraGroupId);
    localStorage.setItem('account', company.companyName);

    dispatch(postAuthenticationLoginRequest());
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { backgroundColor: '#DDDDDD', width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogTitle>Оберіть акаунт</DialogTitle>
      <DialogContent dividers>
        <RadioGroup ref={radioGroupRef} aria-label="ringtone" name="ringtone" value={value} onChange={handleChange}>
          {userResponse?.cidList?.map((cid: any) => (
            <FormControlLabel
              value={cid.companyName}
              key={cid.companyName}
              control={<Radio />}
              label={cid.companyName}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} disabled={value.length === 0}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
