import React from 'react';
import Header from '../Header';
import './layout.css';

interface Props {
  children: React.ReactNode;
}

export const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      <div className="layout">{children}</div>
    </>
  );
};
