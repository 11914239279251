import {
  CLOSE_ERROR_POPUP_SHIPP_REPORT,
  GET_EXCEL_FILE_TABLE_ERROR,
  GET_EXCEL_FILE_TABLE_REQUEST,
  GET_EXCEL_FILE_TABLE_SUCCESS,
  GET_GLOBAL_SHIPP_REPORT_ERROR,
  GET_GLOBAL_SHIPP_REPORT_REQUEST,
  GET_GLOBAL_SHIPP_REPORT_SUCCESS,
  GET_SHIPMENT_REPORT_ERROR,
  GET_SHIPMENT_REPORT_REQUEST,
  GET_SHIPMENT_REPORT_SUCCESS,
} from './constants';

export const getShipmentReportRequest = (body: any) => ({
  type: GET_SHIPMENT_REPORT_REQUEST,
  payload: body,
});
export const getShipmentReportSuccess = (data: any) => ({
  type: GET_SHIPMENT_REPORT_SUCCESS,
  payload: data,
});
export const getShipmentReportError = (error: any) => ({
  type: GET_SHIPMENT_REPORT_ERROR,
  payload: error,
});

export const getExcelFileTableRequest = (body: any) => ({
  type: GET_EXCEL_FILE_TABLE_REQUEST,
  payload: body,
});
export const getExcelFileTableSuccess = () => ({
  type: GET_EXCEL_FILE_TABLE_SUCCESS,
});
export const getExcelFileTableError = (error: any) => ({
  type: GET_EXCEL_FILE_TABLE_ERROR,
  payload: error,
});

export const getGlobalShipReportRequest = (body: any) => ({
  type: GET_GLOBAL_SHIPP_REPORT_REQUEST,
  payload: body,
});
export const getGlobalShipReportSuccess = () => ({
  type: GET_GLOBAL_SHIPP_REPORT_SUCCESS,
});
export const getGlobalShipReportError = (error: any) => ({
  type: GET_GLOBAL_SHIPP_REPORT_ERROR,
  payload: error,
});

export const closeErrorPopUpShippReport = () => ({
  type: CLOSE_ERROR_POPUP_SHIPP_REPORT,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpShippReport>
  | ReturnType<typeof getGlobalShipReportRequest>
  | ReturnType<typeof getGlobalShipReportSuccess>
  | ReturnType<typeof getGlobalShipReportError>
  | ReturnType<typeof getExcelFileTableRequest>
  | ReturnType<typeof getExcelFileTableSuccess>
  | ReturnType<typeof getExcelFileTableError>
  | ReturnType<typeof getShipmentReportRequest>
  | ReturnType<typeof getShipmentReportSuccess>
  | ReturnType<typeof getShipmentReportError>;
