export const GET_SHIPMENT_REPORT_REQUEST = 'GET_SHIPMENT_REPORT_REQUEST' as const;
export const GET_SHIPMENT_REPORT_SUCCESS = 'GET_SHIPMENT_REPORT_SUCCESS' as const;
export const GET_SHIPMENT_REPORT_ERROR = 'GET_SHIPMENT_REPORT_ERROR' as const;

export const GET_EXCEL_FILE_TABLE_REQUEST = 'GET_EXCEL_FILE_TABLE_REQUEST' as const;
export const GET_EXCEL_FILE_TABLE_SUCCESS = 'GET_EXCEL_FILE_TABLE_SUCCESS' as const;
export const GET_EXCEL_FILE_TABLE_ERROR = 'GET_EXCEL_FILE_TABLE_ERROR' as const;

export const GET_GLOBAL_SHIPP_REPORT_REQUEST = 'GET_GLOBAL_SHIPP_REPORT_REQUEST' as const;
export const GET_GLOBAL_SHIPP_REPORT_SUCCESS = 'GET_GLOBAL_SHIPP_REPORT_SUCCESS' as const;
export const GET_GLOBAL_SHIPP_REPORT_ERROR = 'GET_GLOBAL_SHIPP_REPORT_ERROR' as const;

export const CLOSE_ERROR_POPUP_SHIPP_REPORT = 'CLOSE_ERROR_POPUP_SHIPP_REPORT' as const;
