import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Divider, MenuItem, OutlinedInput, TextField, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LInput } from '../../../common/LInput/LInput';
import SelectField from '../../../common/SelectField';
import SimpleField from '../../../common/SimpleField';
import { Spinner } from '../../../common/Spinner/Spinner';
import useModal from '../../../shared/hooks/useModal/useModal';
import AddSeriesModal from '../AddSeriesModal';
import { DeleteElementModal } from '../DeleteElementModal/DeleteElementModal';
import {
  deleteFilterRequest,
  deleteProductFromDocumentRequest,
  deleteShippingOrderByIdRequest,
  downloadShippingOrderRequest,
  getShippingOrderByIdRequest,
  postCheckProductRequest,
  postCheckSeriesRequest,
  putUpdateShippingOrderRequest,
  setErrorMessageShippings,
} from '../services/actions';
import { shippingOrdersSelector } from '../services/selector';
import { ProductsSchema } from '../services/validation';
import styles from './EntryEditor.module.scss';
import { ListenChanges } from '../../../common/ListenChanges/ListenChanges';
import { ExitPageModal } from '../../../common/ExitPageModal/ExitPageModal';
import CheckBoxField from '../../../common/CheckBoxField';
import DownloadIcon from '@mui/icons-material/Download';
import { ClearCheckbox } from './ClearCheckbox';
import { getCountryListRequest } from '../../../../entities/Countries/actions';
import { countryListSelector } from '../../../../entities/Countries/selector';

const calendarStyle = {
  '.MuiInputBase-formControl': {
    backgroundColor: '#ffffff !important',
    height: 30,
    fontSize: '13px !important',
    marginTop: '10px !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
    fontSize: '13px !important',
  },
  '.MuiSvgIcon-root': {
    fontSize: '18px !important',
  },
};
const searchStyle = {
  width: 200,
  height: 30,
  fontSize: '13px !important',
  marginRight: 20,
  margin: 0,
  bgcolor: '#ffffff',
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
};

export const EntryEditor = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const shippingId = location.search.slice(1);

  const { isShow, openModal, closeModal } = useModal();
  const { isShow: isShowDeleteElem, openModal: openDeleteElem, closeModal: closeDeleteElem } = useModal();
  const { isShow: isShowDeleteDoc, openModal: openDeleteDoc, closeModal: closeDeleteDoc } = useModal();
  const { isShow: isShowExit, openModal: openExitModal, closeModal: closeExitModal } = useModal();

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [productNum, setProductNum] = useState('');
  const [seriesNum, setSeriesNum] = useState('');
  const [gtinFromInput, setGtinFromInput] = useState('');
  const [duplicateSeries, setDuplicateSeries] = useState('');
  const [deleteData, setDeleteData] = useState<any>(null);
  const [modalTitle, setModalTitle] = useState<any>('');

  const { order, loading, failedResponseEditor } = useSelector(shippingOrdersSelector);
  const { countries } = useSelector(countryListSelector);

  const initialValue = { ...order };

  const navigate = useNavigate();

  const onUpdateHandler = (values: any) => {
    dispatch(putUpdateShippingOrderRequest(values));
    setTimeout(() => {
      navigate('/export_lists');
    }, 1000);
  };

  const downloadShippingOrder = () => {
    dispatch(downloadShippingOrderRequest({ shippingOrderId: order.id }));
  };

  useEffect(() => {
    dispatch(getShippingOrderByIdRequest({ shippingOrderId: shippingId ? shippingId : order.id }));
  }, []);

  useEffect(() => {
    dispatch(getCountryListRequest());
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.closeGroup}>
        {hasChanges ? <span>Зміни не збережено</span> : null}
        <CloseIcon
          className={styles.iconClose}
          onClick={() => {
            hasChanges ? openExitModal() : navigate('/export_lists');
          }}
        />
      </div>
      <div className={styles.loadingGroup}>
        <span className={styles.pageTitle}>Деталі документу {order.number}</span>
        {loading ? <Spinner /> : null}
      </div>
      {Object.keys(initialValue).length !== 0 ? (
        <Formik
          initialValues={order}
          onSubmit={(values) => onUpdateHandler(values)}
          validationSchema={ProductsSchema}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {({ handleSubmit, values, errors, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <ListenChanges values={values} initialValues={initialValue} setHasChanges={setHasChanges} />
              <section className={styles.body}>
                <div className={styles.header}>
                  <div className={styles.column}>
                    <span className={styles.title}>
                      Номер зовнішнього замовлення <em className={styles.required}>*</em>
                    </span>
                    <SimpleField
                      name="externalOrderNumber"
                      type="number"
                      value={values.externalOrderNumber}
                      disabled={loading || values.documentStatus === 2}
                      validate
                    />
                  </div>
                  <div className={styles.column}>
                    <span className={styles.title}>
                      Країна <em className={styles.required}>*</em>
                    </span>
                    <SelectField
                      title=""
                      name="countryCodeId"
                      value={values.countryCodeId || undefined}
                      variant="noLabel"
                      disabled={loading}
                    >
                      <MenuItem value={undefined}> </MenuItem>
                      {countries?.map((country: any) => (
                        <MenuItem value={country.id}>{country.countryName}</MenuItem>
                      ))}
                    </SelectField>
                  </div>
                  <div className={styles.calendarItem}>
                    <span className={styles.title}>Дата створення</span>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={values.createDate}
                        disabled
                        views={['year', 'month']}
                        onChange={() => undefined}
                        renderInput={(params) => <TextField sx={calendarStyle} {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                  <ClearCheckbox resetCheckbox={() => setFieldValue('externallyAggregated', false)} />
                  <div className={styles.column}>
                    <span className={styles.title}>Статус</span>
                    <SelectField
                      title=""
                      name="documentStatus"
                      value={values.documentStatus || 1}
                      variant="noLabel"
                      disabled={loading}
                    >
                      <MenuItem value={1}>Комплектація дозволена</MenuItem>
                      <MenuItem value={2}>Комплектація завершена</MenuItem>
                      {values?.externallyAggregated ? <MenuItem value={3}>Відвантаження дозволено</MenuItem> : null}
                    </SelectField>
                  </div>
                </div>
                <>
                  <div className={styles.checkboxesWrapper}>
                    <div className={styles.checkboxField}>
                      <CheckBoxField
                        name="allowNotSetSeries"
                        checked={values?.allowNotSetSeries}
                        onChange={() => setFieldValue('allowNotSetSeries', !values.allowNotSetSeries)}
                      />
                      <span>Не вказувати серії (буде взято серії по факту скануванн на складі)</span>
                    </div>
                    <div className={styles.checkboxField}>
                      <CheckBoxField
                        checked={values.externallyAggregated}
                        onChange={() => setFieldValue('externallyAggregated', !values.externallyAggregated)}
                        disabled={values.documentStatus === 1}
                      />
                      <span>Зовнішню агрегацію виконано</span>
                    </div>
                  </div>
                  <div className={styles.additionalBlock}>
                    <div className={styles.filterProduct}>
                      <div className={styles.searchSection}>
                        <div className={styles.searchGroup}>
                          <OutlinedInput
                            defaultValue=""
                            autoComplete="off"
                            sx={searchStyle}
                            onChange={(event) => {
                              setProductNum(event.target.value);
                              dispatch(setErrorMessageShippings([]));
                            }}
                            value={productNum}
                            inputProps={{ maxLength: 13 }}
                            disabled={loading || values.documentStatus === 2}
                          />
                          <span className={styles.hint}>Введіть код EAN13</span>
                        </div>
                        <Button
                          variant="contained"
                          className={styles.AddProductBtn}
                          onClick={() => {
                            if (values.products.some((e: any) => e.gtin == productNum)) {
                              return dispatch(setErrorMessageShippings(['Такий продукт вже додано']));
                            }
                            dispatch(postCheckProductRequest({ gtin: productNum, product: values }));
                            setProductNum('');
                          }}
                          disabled={productNum.length === 0 || loading || values.documentStatus === 2}
                        >
                          <span className={styles.btnText}>Додати продукт</span>
                          <AddIcon className={styles.icon} />
                        </Button>
                        <Tooltip
                          title="Список продукції і серій, які будуть додаватись скануванням в даний документ"
                          placement="right"
                        >
                          <HelpOutlineIcon className={styles.helper} />
                        </Tooltip>
                      </div>
                      {failedResponseEditor.length !== 0 ? (
                        <span className={styles.error}>{failedResponseEditor[0]}</span>
                      ) : (
                        <span className={styles.error}> </span>
                      )}
                    </div>
                    <div className={styles.downloadSection}>
                      <div className={styles.downloadWrapper}>
                        <span>Завантажити звіт по документу</span>
                        <a onClick={downloadShippingOrder}>
                          <DownloadIcon className={styles.downloadIcon} />
                        </a>
                      </div>
                    </div>
                  </div>
                  <Divider className={styles.mainDivider} />
                  {values?.products?.map((product: any, index: number) => (
                    <div className={styles.margin}>
                      <div className={styles.main}>
                        <div className={styles.mainHeader}>
                          <div className={styles.mainHeaderIndex}>{index + 1}.</div>
                          <div className={styles.mainHeaderData}>
                            <span className={styles.internalName}>{product.internalName}</span>
                            <span className={styles.gtinNum}>код {product.gtin}</span>
                          </div>
                        </div>

                        <CloseIcon
                          className={styles.deleteProductIcon}
                          onClick={() => {
                            setModalTitle('Всі додані (скановані) коди буде видалено. Продовжити?');
                            openDeleteElem();
                            setDeleteData(() => () => {
                              values.products.splice(index, 1);
                              const filterIds = product.filters.map((item: any) => item.id);
                              dispatch(
                                deleteProductFromDocumentRequest({
                                  productId: product.id,
                                  filterIds,
                                  shippingOrderId: values.id,
                                }),
                              );
                              closeDeleteElem();
                              setDeleteData(null);
                            });
                          }}
                        />
                      </div>
                      <div>
                        <AddSeriesModal
                          isShown={isShow}
                          data={seriesNum}
                          setData={setSeriesNum}
                          closeModal={closeModal}
                          duplicateSeries={duplicateSeries}
                          setDuplicateSeries={setDuplicateSeries}
                          addHandler={() => {
                            if (product?.filters?.some((e: any) => e.series == seriesNum)) {
                              return setDuplicateSeries('Серія з таким номером вже додана');
                            }
                            dispatch(
                              postCheckSeriesRequest({ series: seriesNum, gtin: gtinFromInput, product: values }),
                            );
                            closeModal();
                            setGtinFromInput('');
                            setSeriesNum('');
                          }}
                          internalName={product.internalName}
                        />
                        <table className={styles.table}>
                          {product.filters?.length !== 0 ? (
                            <thead className={styles.tableHeader}>
                              <th>Список дозволених до комплектації серій</th>
                              <th>
                                Кількість планова <br /> Level 0 UNIT
                              </th>
                              <th>
                                Кількість сканована <br /> Level 0 UNIT
                              </th>
                              <th>
                                Кількість сканована <br /> Level 2 BOX
                              </th>
                              <th>
                                Кількість відвантажена <br /> Level 2 BOX
                              </th>
                            </thead>
                          ) : null}
                          <tbody className={styles.tableBody}>
                            {product.filters?.map((o: any, i: number) => (
                              <tr className={styles.filter}>
                                <td>
                                  <LInput name={`products[${index}].filters[${i}].series`} value={o.series} disabled />
                                </td>
                                <td>
                                  <SimpleField
                                    name={`products[${index}].filters[${i}].unitsPlannedCount`}
                                    type="number"
                                    size="small"
                                    value={o.unitsPlannedCount}
                                    disabled={loading || values.documentStatus === 2 || values.allowNotSetSeries}
                                  />
                                </td>
                                <td>
                                  <SimpleField
                                    name={`products[${index}].filters[${i}].unitsActualCount`}
                                    type="number"
                                    size="small"
                                    value={o.unitsActualCount}
                                    disabled
                                  />
                                </td>
                                <td>
                                  <SimpleField
                                    name={`products[${index}].filters[${i}].boxesAvailableCount`}
                                    type="number"
                                    size="small"
                                    value={o.boxesAvailableCount}
                                    disabled
                                  />
                                </td>
                                <td>
                                  <SimpleField
                                    name={`products[${index}].filters[${i}].boxesShippedCount`}
                                    type="number"
                                    size="small"
                                    value={o.boxesShippedCount}
                                    disabled
                                  />
                                </td>
                                <td>
                                  <CloseIcon
                                    className={styles.closeIcon}
                                    onClick={() => {
                                      setModalTitle('Всі додані (скановані) коди буде видалено. Продовжити?');
                                      openDeleteElem();
                                      setDeleteData(() => () => {
                                        values.products[index].filters.splice(i, 1);
                                        dispatch(
                                          deleteFilterRequest({
                                            filterId: o.id,
                                            shippingOrderId: values.id,
                                          }),
                                        );
                                        closeDeleteElem();
                                        setDeleteData(null);
                                      });
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                            {product.filters?.length !== 0 ? (
                              <tr>
                                <td>Разом, шт:</td>
                                <td>
                                  <SimpleField name="name" type="number" size="small" value={null} disabled />
                                </td>
                                <td>
                                  <SimpleField name="name" type="number" size="small" value={null} disabled />
                                </td>
                                <td>
                                  <SimpleField name="name" type="number" size="small" value={null} disabled />
                                </td>
                                <td>
                                  <SimpleField name="name" type="number" size="small" value={null} disabled />
                                </td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>
                        <div className={styles.buttonGroup}>
                          <Button
                            variant="contained"
                            className={styles.addSeriesBtn}
                            onClick={() => {
                              setGtinFromInput(product.gtin);
                              openModal();
                            }}
                            disabled={loading || values.documentStatus === 2 || values.allowNotSetSeries}
                          >
                            <span className={styles.btnText}>Додати серію</span>
                            <AddIcon className={styles.icon} />
                          </Button>
                        </div>
                      </div>
                      <Divider className={styles.divider} />
                    </div>
                  ))}
                </>
                <div className={styles.saveSection}>
                  <div className={styles.editor}>
                    <span className={styles.editorLegend}>
                      Створено: {order?.creatorEmail},{' '}
                      {order.createDate && order.createDate !== null
                        ? format(new Date(order?.createDate), 'yyyy-MM-dd HH:mm:ss')
                        : null}
                    </span>
                    <span className={styles.editorLegend}>
                      Останнє оновлення:{' '}
                      {order.updateDate && order.updateDate !== null
                        ? format(new Date(order?.updateDate), 'yyyy-MM-dd HH:mm:ss')
                        : null}
                    </span>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      className={styles.btn}
                      type="submit"
                      disabled={loading || Object.keys(errors).length !== 0 || !hasChanges}
                    >
                      <span className={styles.btnText}>Зберегти</span>
                      <SaveIcon className={styles.icon} />
                    </Button>
                    <Button
                      variant="contained"
                      className={styles.deleteBtn}
                      onClick={openDeleteDoc}
                      disabled={loading || values.documentStatus === 2}
                    >
                      <span className={styles.deleteBtnText}>Видалити</span>
                      <DeleteForeverIcon className={styles.icon} />
                    </Button>
                  </div>
                </div>
              </section>
              <DeleteElementModal
                title={modalTitle}
                isShow={isShowDeleteElem}
                closeModal={() => {
                  setDeleteData(null);
                  setModalTitle('');
                  closeDeleteElem();
                }}
                deleteHandler={deleteData}
              />
              <DeleteElementModal
                title={`Видалити документ ${order.number}?`}
                isShow={isShowDeleteDoc}
                closeModal={closeDeleteDoc}
                deleteHandler={() => {
                  dispatch(deleteShippingOrderByIdRequest({ shippingOrderId: order.id }));
                  setTimeout(() => {
                    navigate('/export_lists');
                  }, 1000);
                }}
              />
            </form>
          )}
        </Formik>
      ) : null}
      <ExitPageModal
        title={'В документ внесені зміни. Вийти?'}
        isShow={isShowExit}
        closeModal={closeExitModal}
        exitHandler={() => {
          navigate('/export_lists');
          closeExitModal();
        }}
      />
    </div>
  );
};
