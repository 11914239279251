import {
  CLOSE_ERROR_POPUP_AUDITTRAIL_REPORT_ERROR,
  GET_AUDITTRAIL_REPORT_ERROR,
  GET_AUDITTRAIL_REPORT_REQUEST,
  GET_AUDITTRAIL_REPORT_SUCCESS,
  GET_GLOBAL_EXCEL_REPORT_ERROR,
  GET_GLOBAL_EXCEL_REPORT_REQUEST,
  GET_GLOBAL_EXCEL_REPORT_SUCCESS,
  GET_PDF_FILE_ERROR,
  GET_PDF_FILE_REQUEST,
  GET_PDF_FILE_SUCCESS,
} from './constants';

export const getAuditTrailReportRequest = (dates: any) => ({
  type: GET_AUDITTRAIL_REPORT_REQUEST,
  payload: dates,
});
export const getAuditTrailReportSuccess = (data: any) => ({
  type: GET_AUDITTRAIL_REPORT_SUCCESS,
  payload: data,
});
export const getAuditTrailReportError = (error: any) => ({
  type: GET_AUDITTRAIL_REPORT_ERROR,
  payload: error,
});

export const getPDFFileRequest = (body: any) => ({
  type: GET_PDF_FILE_REQUEST,
  payload: body,
});
export const getPDFFileSuccess = () => ({
  type: GET_PDF_FILE_SUCCESS,
});
export const getPDFFileError = (error: any) => ({
  type: GET_PDF_FILE_ERROR,
  payload: error,
});

export const getGlobalExcelReportRequest = (body: any) => ({
  type: GET_GLOBAL_EXCEL_REPORT_REQUEST,
  payload: body,
});
export const getGlobalExcelReportSuccess = () => ({
  type: GET_GLOBAL_EXCEL_REPORT_SUCCESS,
});
export const getGlobalExcelReportError = (error: any) => ({
  type: GET_GLOBAL_EXCEL_REPORT_ERROR,
  payload: error,
});

export const closeErrorPopUpAuditTrailReport = () => ({
  type: CLOSE_ERROR_POPUP_AUDITTRAIL_REPORT_ERROR,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpAuditTrailReport>
  | ReturnType<typeof getGlobalExcelReportRequest>
  | ReturnType<typeof getGlobalExcelReportSuccess>
  | ReturnType<typeof getGlobalExcelReportError>
  | ReturnType<typeof getPDFFileRequest>
  | ReturnType<typeof getPDFFileSuccess>
  | ReturnType<typeof getPDFFileError>
  | ReturnType<typeof getAuditTrailReportRequest>
  | ReturnType<typeof getAuditTrailReportSuccess>
  | ReturnType<typeof getAuditTrailReportError>;
