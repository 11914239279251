import {
  CLOSE_ERROR_POPUP_WITHDRAWAL_REPORT_ERROR,
  GET_WITHDRAWAL_REPORT_ERROR,
  GET_WITHDRAWAL_REPORT_REQUEST,
  GET_WITHDRAWAL_REPORT_SUCCESS,
  GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_ERROR,
  GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_REQUEST,
  GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_SUCCESS,
} from './constants';

export const closeErrorPopUpWithdrawalReportReport = () => ({
  type: CLOSE_ERROR_POPUP_WITHDRAWAL_REPORT_ERROR,
});

export const getWithdrawalReportRequest = (dates: any) => ({
  type: GET_WITHDRAWAL_REPORT_REQUEST,
  payload: dates,
});
export const getWithdrawalReportSuccess = (data: any) => ({
  type: GET_WITHDRAWAL_REPORT_SUCCESS,
  payload: data,
});
export const getWithdrawalReportError = (error: any) => ({
  type: GET_WITHDRAWAL_REPORT_ERROR,
  payload: error,
});

export const getGlobalWithdrawalExcelReportRequest = (body: any) => ({
  type: GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_REQUEST,
  payload: body,
});
export const getGlobalWithdrawalExcelReportSuccess = () => ({
  type: GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_SUCCESS,
});
export const getGlobalWithdrawalExcelReportError = (error: any) => ({
  type: GET_GLOBAL_WITHDRAWAL_EXCEL_REPORT_ERROR,
  payload: error,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpWithdrawalReportReport>
  | ReturnType<typeof getGlobalWithdrawalExcelReportRequest>
  | ReturnType<typeof getGlobalWithdrawalExcelReportSuccess>
  | ReturnType<typeof getGlobalWithdrawalExcelReportError>
  | ReturnType<typeof getWithdrawalReportRequest>
  | ReturnType<typeof getWithdrawalReportSuccess>
  | ReturnType<typeof getWithdrawalReportError>;
