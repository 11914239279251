import { Actions } from './actions';
import {
  GET_SENDERS_INFO_REQUEST,
  GET_SENDERS_INFO_SUCCESS,
  GET_SENDERS_INFO_ERROR,
  GET_SENDERS_LOG_REQUEST,
  GET_SENDERS_LOG_SUCCESS,
  GET_SENDERS_LOG_ERROR,
  CLOSE_ERROR_POPUP_SENDERS_INFO,
  GET_BACKGROUND_PROCESSES_REQUEST,
  GET_BACKGROUND_PROCESSES_SUCCESS,
  GET_BACKGROUND_PROCESSES_ERROR,
} from './constants';

const initialState = {
  loading: false as boolean,
  backgroundProcessesLoading: false as boolean,
  error: null as Error | null,

  backFlowProcessesResponse: [] as any,
  backFlowProcessesErrorResponses: {} as any,

  backgroundProcesses: {} as any,
  backgroundProcessesErrorResponses: {} as any,
};

export type BackFlowProcessesState = typeof initialState;

export const BackFlowProcessesReducer = (state: BackFlowProcessesState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_SENDERS_INFO_REQUEST: {
      return {
        ...state,
        loading: true,
        backFlowProcessesResponse: [],
        backFlowProcessesErrorResponses: {},
      };
    }
    case GET_SENDERS_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        backFlowProcessesResponse: action.payload,
      };
    }
    case GET_SENDERS_INFO_ERROR: {
      return {
        ...state,
        loading: false,
        backFlowProcessesErrorResponses: action.payload,
      };
    }

    case GET_SENDERS_LOG_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SENDERS_LOG_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_SENDERS_LOG_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_BACKGROUND_PROCESSES_REQUEST: {
      return {
        ...state,
        backgroundProcessesLoading: true,
        backgroundProcessesErrorResponses: {},
        backgroundProcesses: {},
      };
    }
    case GET_BACKGROUND_PROCESSES_SUCCESS: {
      return {
        ...state,
        backgroundProcessesLoading: false,
        backgroundProcesses: action.payload,
      };
    }
    case GET_BACKGROUND_PROCESSES_ERROR: {
      return {
        ...state,
        backgroundProcessesLoading: false,
        backgroundProcessesErrorResponses: action.payload,
      };
    }

    case CLOSE_ERROR_POPUP_SENDERS_INFO: {
      return {
        ...state,
        loading: false,
        backFlowProcessesErrorResponses: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
