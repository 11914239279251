export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST' as const;
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS' as const;
export const GET_SETTINGS_ERROR = 'GET_SETTINGS_ERROR' as const;

export const POST_UPDATE_SETTINGS_REQUEST = 'POST_UPDATE_SETTINGS_REQUEST' as const;
export const POST_UPDATE_SETTINGS_SUCCESS = 'POST_UPDATE_SETTINGS_SUCCESS' as const;
export const POST_UPDATE_SETTINGS_ERROR = 'POST_UPDATE_SETTINGS_ERROR' as const;

export const GET_DATA_TRUE_API_REQUEST = 'GET_DATA_TRUE_API_REQUEST' as const;
export const GET_DATA_TRUE_API_SUCCESS = 'GET_DATA_TRUE_API_SUCCESS' as const;
export const GET_DATA_TRUE_API_ERROR = 'GET_DATA_TRUE_API_ERROR' as const;

export const GET_KEY_RESPONSE_ERROR = 'GET_KEY_RESPONSE_ERROR' as const;
