export const selectStyle = {
  width: 300,
  height: 30,
  fontSize: '13px !important',
  bgcolor: '#ffffff',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
};

export const calendarStyleEnabled = {
  '.MuiInputBase-formControl': {
    backgroundColor: '#ffffff !important',
    height: 30,
    fontSize: '13px !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
    fontSize: '13px !important',
  },
  '.MuiSvgIcon-root': {
    fontSize: '18px !important',
  },
};

export const calendarStyleDisabled = {
  '.MuiInputBase-formControl': {
    backgroundColor: '#f0eeee !important',
    height: 30,
    fontSize: '13px !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
    fontSize: '13px !important',
  },
  '.MuiSvgIcon-root': {
    fontSize: '18px !important',
  },
};
