import React, { useEffect } from 'react';

// @ts-expect-error
const customIsEqual = (obj1: any, obj2: any) => {
  const isObject = (obj: any) => obj && typeof obj === 'object';

  const isNullOrEmptyString = (value: any) => value === null || value === '';

  // @ts-expect-error
  const customCompare = (value1: any, value2: any) => {
    if (isNullOrEmptyString(value1) && isNullOrEmptyString(value2)) {
      return true;
    }
    if (isObject(value1) && isObject(value2)) {
      return customIsEqual(value1, value2);
    }
    return value1 === value2;
  };

  if (obj1 === obj2) return true;
  if (!isObject(obj1) || !isObject(obj2)) return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  return keys1.every((key) => customCompare(obj1[key], obj2[key]));
};

interface Props {
  values: any;
  initialValues: any;
  setHasChanges: React.Dispatch<React.SetStateAction<any>>;
}

export const ListenChanges: React.FC<Props> = ({ values, initialValues, setHasChanges }) => {
  useEffect(() => {
    customIsEqual(initialValues, values) ? setHasChanges(false) : setHasChanges(true);
  }, [values, initialValues]);

  return null;
};
