import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { GET_CODES_FOR_IMPORT_REPORT_REQUEST } from './constants';
import {
  getCodesForImportReportError,
  getCodesForImportReportRequest,
  getCodesForImportReportSuccess,
} from './actions';
import { Reports } from '../../../../entities/Reports/service';

function* getCodesForImportReportSaga(action: ReturnType<typeof getCodesForImportReportRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.getImportReport, action.payload);
    const availableCodes = response.data.ResponseBody;
    yield put(getCodesForImportReportSuccess(availableCodes));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getCodesForImportReportError(error));
  }
}

export function* ImportReportSagaWatcher() {
  yield takeLatest(GET_CODES_FOR_IMPORT_REPORT_REQUEST, getCodesForImportReportSaga);
}
