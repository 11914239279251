import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  GET_EXCEL_FILE_TABLE_REQUEST,
  GET_GLOBAL_SHIPP_REPORT_REQUEST,
  GET_SHIPMENT_REPORT_REQUEST,
} from './constants';
import {
  getExcelFileTableError,
  getExcelFileTableRequest,
  getExcelFileTableSuccess,
  getGlobalShipReportError,
  getGlobalShipReportRequest,
  getGlobalShipReportSuccess,
  getShipmentReportError,
  getShipmentReportRequest,
  getShipmentReportSuccess,
} from './actions';
import { Reports } from '../../../../entities/Reports/service';

function* getShipmentReportRequestSaga(action: ReturnType<typeof getShipmentReportRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.getShipmentReport, action.payload);
    const shipmentReport = response.data.ResponseBody;
    yield put(getShipmentReportSuccess(shipmentReport));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getShipmentReportError(error));
  }
}

function* getExcelFileTableRequestSaga(action: ReturnType<typeof getExcelFileTableRequest>) {
  try {
    yield call(Reports.getExcelTableReport, action.payload);
    yield put(getExcelFileTableSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getExcelFileTableError(error));
  }
}

function* getGlobalShipReportRequestSaga(action: ReturnType<typeof getGlobalShipReportRequest>) {
  try {
    // @ts-expect-error
    const response = yield call(Reports.getGlobalShipReport, action.payload);

    if (response.status === 204) {
      yield put(
        getGlobalShipReportError({
          status: 204,
          message: 'дані відсутні.',
          config: { url: '/Report/Shipping/Excel/File/BySsccs' },
        }),
      );
    } else {
      yield put(getGlobalShipReportSuccess());
    }

    yield delay(8000);
    yield put(getGlobalShipReportError({}));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getGlobalShipReportError(error));
  }
}

export function* ShipmentReportSagaWatcher() {
  yield takeLatest(GET_SHIPMENT_REPORT_REQUEST, getShipmentReportRequestSaga);
  yield takeLatest(GET_EXCEL_FILE_TABLE_REQUEST, getExcelFileTableRequestSaga);
  yield takeLatest(GET_GLOBAL_SHIPP_REPORT_REQUEST, getGlobalShipReportRequestSaga);
}
