import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  deleteUnitTemplatesByIdError,
  deleteUnitTemplatesByIdRequest,
  deleteUnitTemplatesByIdSuccess,
  getPhotoUnitError,
  getPhotoUnitRequest,
  getPhotoUnitSuccess,
  getUnitTemplatesByIdError,
  getUnitTemplatesByIdRequest,
  getUnitTemplatesByIdSuccess,
  getUnitTemplatesError,
  getUnitTemplatesRequest,
  getUnitTemplatesSuccess,
  postCreateUnitTemplatesError,
  postCreateUnitTemplatesSuccess,
  postUpdatePhotoUnitTemplatesError,
  postUpdatePhotoUnitTemplatesRequest,
  postUpdatePhotoUnitTemplatesSuccess,
  putUpdateUnitTemplatesError,
  putUpdateUnitTemplatesRequest,
  putUpdateUnitTemplatesSuccess,
} from './actions';
import {
  DELETE_UNIT_TEMPLATES_BY_ID_REQUEST,
  GET_PHOTO_UNIT_REQUEST,
  GET_UNIT_TEMPLATES_BY_ID_REQUEST,
  GET_UNIT_TEMPLATES_REQUEST,
  POST_CREATE_UNIT_TEMPLATES_REQUEST,
  POST_UPDATE_PHOTO_UNIT_TEMPLATES_REQUEST,
  PUT_UPDATE_UNIT_TEMPLATES_REQUEST,
} from './constants';
import { Templates } from '../../../../entities/Templates/service';
import { objToFormData } from '../../../../utils';

function* getUnitTemplatesSaga(action: ReturnType<typeof getUnitTemplatesRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.getTemplates, action.payload);
    const res = response.data.ResponseBody;
    yield put(getUnitTemplatesSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getUnitTemplatesError(error));
  }
}

function* getUnitTemplatesByIdSaga(action: ReturnType<typeof getUnitTemplatesByIdRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.getTemplatesById, action.payload);
    const res = response.data.ResponseBody;
    yield put(getUnitTemplatesByIdSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getUnitTemplatesByIdError(error));
  }
}

function* deleteUnitTemplatesByIdSaga(action: ReturnType<typeof deleteUnitTemplatesByIdRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.deleteTemplatesById, action.payload);
    const res = response.data.ResponseBody;
    yield put(deleteUnitTemplatesByIdSuccess(res));
    yield put(getUnitTemplatesRequest({ level: 0 }));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(deleteUnitTemplatesByIdError(error));
  }
}

function* postCreateUnitTemplatesSaga() {
  try {
    const response: AxiosResponse = yield call(Templates.postCreateTemplates);
    const res = response.data.ResponseBody;
    yield put(postCreateUnitTemplatesSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postCreateUnitTemplatesError(error));
  }
}

function* postUpdatePhotoUnitTemplatesSaga(action: ReturnType<typeof postUpdatePhotoUnitTemplatesRequest>) {
  try {
    const body = objToFormData(action.payload);
    const response: AxiosResponse = yield call(Templates.photoUpload, body);
    const res = response.data.ResponseBody;
    yield put(postUpdatePhotoUnitTemplatesSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postUpdatePhotoUnitTemplatesError(error));
  }
}

function* getPhotoUnitSaga(action: ReturnType<typeof getPhotoUnitRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.getPhoto, action.payload);
    const res = URL.createObjectURL(response.data);
    yield put(getPhotoUnitSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getPhotoUnitError(error));
  }
}

function* putUpdateUnitTemplatesSaga(action: ReturnType<typeof putUpdateUnitTemplatesRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.putUpdateTemplates, action.payload);
    const res = response.data.ResponseBody;
    yield put(putUpdateUnitTemplatesSuccess(res));
    if (res.messages.Successful !== null) {
      yield put(postCreateUnitTemplatesSuccess({}));
      yield put(getUnitTemplatesRequest({ level: 0 }));
    }
  } catch (e: any) {
    const error = e.toJSON();
    yield put(putUpdateUnitTemplatesError(error));
  }
}

export function* UnitTemplatesSagaWatcher() {
  yield takeLatest(POST_UPDATE_PHOTO_UNIT_TEMPLATES_REQUEST, postUpdatePhotoUnitTemplatesSaga);
  yield takeLatest(GET_UNIT_TEMPLATES_REQUEST, getUnitTemplatesSaga);
  yield takeLatest(GET_PHOTO_UNIT_REQUEST, getPhotoUnitSaga);
  yield takeLatest(POST_CREATE_UNIT_TEMPLATES_REQUEST, postCreateUnitTemplatesSaga);
  yield takeLatest(PUT_UPDATE_UNIT_TEMPLATES_REQUEST, putUpdateUnitTemplatesSaga);
  yield takeLatest(GET_UNIT_TEMPLATES_BY_ID_REQUEST, getUnitTemplatesByIdSaga);
  yield takeLatest(DELETE_UNIT_TEMPLATES_BY_ID_REQUEST, deleteUnitTemplatesByIdSaga);
}
