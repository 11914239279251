import { OutlinedInput } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

import styles from './LInput.module.scss';

const inputStyle = {
  width: 300,
  height: 30,
  fontSize: '13px !important',
  margin: 0,
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
};

interface Props {
  name: string;
  size?: string;
  hasMarginTop?: boolean;
  value?: any;
  disabled?: boolean;
  validate?: boolean;
  maxLength?: number;
  type?: string;
}

export const LInput: React.FC<Props> = ({ name, hasMarginTop, disabled, value, maxLength, validate, type }) => {
  const { handleChange, setFieldValue } = useFormikContext<any>();

  const zeroCharValidation = (val: any) => {
    let v;
    val == 0 ? (v = '') : (v = val);
    return v.slice(0, maxLength);
  };

  return (
    <div className={hasMarginTop ? styles.inputElement : styles.inputNoMargin}>
      <div className={disabled ? styles.disabledField : null}>
        {validate ? (
          <OutlinedInput
            defaultValue=""
            autoComplete="off"
            className={styles.linput}
            sx={inputStyle}
            name={name}
            onChange={
              type === 'number' ? (event) => setFieldValue(name, zeroCharValidation(event.target.value)) : handleChange
            }
            disabled={disabled}
            value={value}
            inputProps={{ maxLength, type }}
            error={value === undefined || value === null || value.toString().trim().length === 0}
          />
        ) : (
          <OutlinedInput
            defaultValue=""
            autoComplete="off"
            className={styles.linput}
            sx={inputStyle}
            name={name}
            onChange={
              type === 'number' ? (event) => setFieldValue(name, zeroCharValidation(event.target.value)) : handleChange
            }
            disabled={disabled}
            value={value}
            inputProps={{ maxLength, type }}
          />
        )}
      </div>
    </div>
  );
};
