import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, MenuItem, Modal, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { instance as axios } from '../../../api/axios';
import {
  getDataTrueApiRequest,
  getDataTrueApiSuccess,
  getKeyResponseError,
} from '../../About/Settings/services/actions';
import { Spinner } from '../../common/Spinner/Spinner';
import { selectStyle } from '../../EventsLog/Shipment/muiStyles/muiStyles';

import styles from './TrueAPIModal.module.scss';
import { settingsSelector } from '../../About/Settings/services/selector';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  height: '360px',
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  outline: 'none !important',
  border: 'none !important',
  flexDirection: 'column',
  p: 4,
};

interface Props {
  isShow: boolean;
  onCloseModal: () => void;
}
export const TrueAPIModal: React.FC<Props> = ({ isShow, onCloseModal }) => {
  const requestUrl = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [certificateList, setCertificateList] = useState<any>(null);
  const [getKeyApiError, setGetKeyApiError] = useState<any>(null);
  const [errorText, setErrorText] = useState<any>('  ');
  const [activeCert, setActiveCert] = useState<any>(null);

  const { trueApiLoading, trueApiResponse } = useSelector(settingsSelector);

  const getRestPlusListOfCert = async () => {
    setErrorText(null);
    setLoading(true);
    const ws: any = new WebSocket('ws://127.0.0.1:64646/service/cryptapi');
    ws.onopen = () => {
      ws.send(JSON.stringify({ plugin: 'pfx', name: 'list_all_certificates' }));
    };
    ws.onmessage = (evt: any) => {
      const message = JSON.parse(evt.data);
      if (message && message.success === false) {
        setLoading(false);
        setErrorText(message.reason);
        ws.close();
      } else {
        ws.close();
        setLoading(false);
        setCertificateList(message?.certificates);
      }
    };
    ws.onerror = () => {
      setLoading(false);
      ws.close();
      setErrorText("Відсутній зв'язок програмою з E-IMZO на цьому ПК");
    };
  };

  const getKey = async (cert: any) => {
    setErrorText(' ');
    try {
      const response = await axios.post(`${requestUrl}/TrueApi/Auth/Key`);
      if (response && response?.status === 200 && response?.data?.ResponseBody !== null) {
        const data = await response?.data?.ResponseBody;
        const ws2: any = new WebSocket('ws://127.0.0.1:64646/service/cryptapi');
        const args = cert;
        if (args) {
          ws2.onopen = () => {
            ws2.send(JSON.stringify({ plugin: 'pfx', name: 'load_key', arguments: Object.values(args) }));
          };
          ws2.onmessage = (evt: any) => {
            const message = JSON.parse(evt.data);
            if (message && message.success === false) {
              setLoading(false);
              ws2.close();
              setErrorText(message.reason);
            } else {
              ws2.close();
              signatureHandler(message, data);
            }
          };
          ws2.onerror = () => {
            setLoading(false);
            ws2.close();
            setErrorText("Відсутній зв'язок програмою з E-IMZO на цьому ПК");
          };
        } else {
          setLoading(false);
          ws2.close();
          setErrorText('Помилка читання сертифікату');
        }
      } else {
        setGetKeyApiError(response?.data);
      }
    } catch (error) {
      setLoading(false);
      // @ts-expect-error
      dispatch(getKeyResponseError(error.toJSON()));
    }
  };

  const signatureHandler = (res: any, data: any) => {
    const b64Btoa = btoa(data?.data);
    const ws3: any = new WebSocket('ws://127.0.0.1:64646/service/cryptapi');
    const argument = [b64Btoa, res.keyId, 'no'];
    ws3.onopen = () => {
      ws3.send(JSON.stringify({ plugin: 'pkcs7', name: 'create_pkcs7', arguments: argument }));
    };
    ws3.onmessage = (evt: any) => {
      // listen to data sent from the websocket server
      const message = JSON.parse(evt.data);
      if (message && message.success === false) {
        setLoading(false);
        ws3.close();
        setErrorText('Помилка читання сертифікату або некоректний пароль');
      } else {
        ws3.close();
        dispatch(getDataTrueApiRequest({ uuid: data?.uuid, data: message?.pkcs7_64 }));
      }
    };
    ws3.onerror = () => {
      setLoading(false);
      ws3.close();
      setErrorText("Відсутній зв'язок програмою з E-IMZO на цьому ПК");
    };
  };

  useEffect(() => {
    if (isShow) {
      getRestPlusListOfCert();
    }

    dispatch(getKeyResponseError({}));
    setErrorText(' ');
  }, []);

  return (
    <>
      <Modal
        open={isShow}
        onClose={() => {
          onCloseModal();
          dispatch(getDataTrueApiSuccess({}));
          setGetKeyApiError(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.spinnerWrapper}>{trueApiLoading || loading ? <Spinner /> : null}</div>
          <div className={styles.headerButtonsWrapper}>
            <CloseIcon
              onClick={() => {
                onCloseModal();
                dispatch(getDataTrueApiSuccess({}));
                dispatch(getKeyResponseError({}));
                setGetKeyApiError(null);
              }}
            />
          </div>

          <span className={styles.header}>Утіліта для генерації токена True API</span>
          <span className={styles.modalText}>
            1. Розмістіть pfx-файли сертифікатів (файли ЕЦП) в корені диска С: цього ПК.
            <br />
            2. Зкачайте і встановіть програму E-IMZO (див. розділ 'Інструменти та утіліти').
            <br />
            3. Запустіть програму E-IMZO, увімкніть режим розробника через правий клік.
          </span>
          <div className={styles.contentWrapper}>
            <div className={styles.selectWrapper}>
              <span>Оберіть сертифікат:</span>
              <Select
                name="certificate"
                onChange={(e: SelectChangeEvent<any>) => {
                  setActiveCert(e.target.value);
                  setErrorText(null);
                  setGetKeyApiError(null);
                  dispatch(getDataTrueApiSuccess({}));
                  dispatch(getKeyResponseError({}));
                }}
                defaultValue={null}
                disabled={trueApiLoading || loading}
                value={activeCert}
                sx={selectStyle}
              >
                {certificateList?.map((certificate: any) => (
                  <MenuItem value={certificate} sx={{ fontSize: '13px' }}>
                    {certificate.name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className={styles.buttonWrapper}>
              <Button
                variant="contained"
                onClick={() => getKey(activeCert)}
                className={styles.btn}
                disabled={activeCert === null || trueApiLoading || loading || trueApiResponse.Result === 1}
              >
                <span className={styles.btnText}>Згенерувати</span>
              </Button>
            </div>

            <div className={styles.responseWrapper}>
              {trueApiResponse && trueApiResponse.Result === 1 ? <span>{trueApiResponse.UserText}</span> : <span />}
              {getKeyApiError && getKeyApiError.Result === 1 ? <span>{getKeyApiError.UserText}</span> : <span />}
              {trueApiResponse && trueApiResponse.Result === 0 ? (
                <span className={styles.errorResponse}>{trueApiResponse.UserText}</span>
              ) : (
                <span />
              )}
              {getKeyApiError && getKeyApiError.Result === 0 ? (
                <span className={styles.errorResponse}>{getKeyApiError.UserText}</span>
              ) : (
                <span />
              )}
              {errorText && <span className={styles.errorResponse}>{errorText}</span>}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
