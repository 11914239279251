import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '300px',
    height: '30px',
    marginRight: '20px',
  },
  altericon: {
    fontSize: '16px !important',
    color: '#B03A2E',
  },
  icon: {
    fontSize: '16px !important',
    color: '#535353',
  },
  inputSearch: {
    ml: 1,
    flex: 1,

    '& input[type=number]': {
      '-moz-appearance': 'textfield',
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  buttonClear: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
}));

export { useStyles };
