import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

interface IBasicBars {
  days: string[];
  success: number[];
  errors: number[];
}

export const BasicBars: React.FC<IBasicBars> = ({ days, errors, success }) => {
  return (
    <BarChart
      xAxis={[{ scaleType: 'band', data: days }]}
      series={[
        { data: success, stack: 'true', label: 'Успішно', color: '#27AE60' },
        { data: errors, stack: 'true', label: 'Помилка', color: '#C0392B' },
      ]}
      width={690}
      height={230}
      slotProps={{
        legend: {
          itemMarkWidth: 8,
          itemMarkHeight: 8,
          labelStyle: {
            fontSize: 11,
            fill: '#333333',
          },
        },
      }}
    />
  );
};
