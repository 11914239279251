import { Actions } from './actions';
import {
  POST_CANCEL_PRINT_JOB_REQUEST,
  POST_CANCEL_PRINT_JOB_SUCCESS,
  POST_CANCEL_PRINT_JOB_ERROR,
  POST_FILTER_PRINT_JOB_REQUEST,
  POST_FILTER_PRINT_JOB_SUCCESS,
  POST_FILTER_PRINT_JOB_ERROR,
  CLOSE_ERROR_POPUP_PRINT_JOB,
  GET_ALL_PRINT_CODES_SUCCESS,
  GET_ALL_PRINT_CODES_REQUEST,
  POST_PRINT_JOB_DETAILED_REQUEST,
  POST_PRINT_JOB_DETAILED_SUCCESS,
  POST_PRINT_JOB_DETAILED_ERROR,
  POST_UNRESERVE_MARKING_CODES_REQUEST,
  POST_UNRESERVE_MARKING_CODES_SUCCESS,
  POST_UNRESERVE_MARKING_CODES_ERROR,
} from './constants';

const initialState = {
  innerModalLoading: false as boolean,
  modalLoading: false as boolean,
  loading: false as boolean,
  error: null as Error | null,
  printCodes: [] as any,
  printCodesDetailed: {} as any,

  postUnreserveMarkingCodes: '' as string,

  getAllPrintJobsErrorResponse: {} as any,
  printCodesDetailedErrorResponse: {} as any,
  cancelPrintJobsErrorResponse: {} as any,
  postUnreserveMarkingCodesErrorResponse: {} as any,
};

export type PrintCodesState = typeof initialState;

export const PrintCodesReducer = (state: PrintCodesState = initialState, action: Actions) => {
  switch (action.type) {
    case POST_CANCEL_PRINT_JOB_REQUEST: {
      return {
        ...state,
        modalLoading: true,
      };
    }
    case POST_CANCEL_PRINT_JOB_SUCCESS: {
      return {
        ...state,
        modalLoading: false,
      };
    }
    case POST_CANCEL_PRINT_JOB_ERROR: {
      return {
        ...state,
        modalLoading: false,
        cancelPrintJobsErrorResponse: action.payload,
      };
    }
    case POST_FILTER_PRINT_JOB_REQUEST: {
      return {
        ...state,
        loading: true,
        printCodes: [],
      };
    }
    case POST_FILTER_PRINT_JOB_SUCCESS: {
      return {
        ...state,
        loading: false,
        printCodes: action.payload,
      };
    }

    case POST_PRINT_JOB_DETAILED_REQUEST: {
      return {
        ...state,
        modalLoading: true,
        printCodesDetailed: {},
      };
    }
    case POST_PRINT_JOB_DETAILED_SUCCESS: {
      return {
        ...state,
        modalLoading: false,
        printCodesDetailed: action.payload,
      };
    }
    case POST_PRINT_JOB_DETAILED_ERROR: {
      return {
        ...state,
        modalLoading: false,
        printCodesDetailedErrorResponse: [],
      };
    }

    case GET_ALL_PRINT_CODES_REQUEST: {
      return {
        ...state,
        loading: false,
        printCodes: [],
      };
    }
    case GET_ALL_PRINT_CODES_SUCCESS: {
      return {
        ...state,
        loading: false,
        printCodes: action.payload,
      };
    }
    case POST_FILTER_PRINT_JOB_ERROR: {
      return {
        ...state,
        loading: false,
        getAllPrintJobsErrorResponse: action.payload,
      };
    }

    case POST_UNRESERVE_MARKING_CODES_REQUEST: {
      return {
        ...state,
        innerModalLoading: true,
        postUnreserveMarkingCodes: '',
      };
    }
    case POST_UNRESERVE_MARKING_CODES_SUCCESS: {
      return {
        ...state,
        innerModalLoading: false,
        postUnreserveMarkingCodes: action.payload,
      };
    }
    case POST_UNRESERVE_MARKING_CODES_ERROR: {
      return {
        ...state,
        innerModalLoading: false,
        postUnreserveMarkingCodesErrorResponse: action.payload,
      };
    }

    case CLOSE_ERROR_POPUP_PRINT_JOB: {
      return {
        ...state,
        getAllPrintJobsErrorResponse: {},
        cancelPrintJobsErrorResponse: {},
        printCodesDetailedErrorResponse: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
