import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from '../../authConfig';
import styles from './login.module.scss';
import { msalInstance } from '../../index';

export const Login: React.FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const account = msalInstance.getAllAccounts()[0];

  const handleLogin = (instance: any) => {
    instance.loginRedirect(loginRequest).catch((e: any) => {
      console.error(e);
    });
  };

  useEffect(() => {
    handleLogin(instance);
  }, []);

  useEffect(() => {
    if (account !== null && account !== undefined) {
      navigate('/main');
    }
  }, [account]);
  return <div className={styles.wrapper} />;
};
