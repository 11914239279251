import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputSearch from '../../../common/InputSearch';
import { Spinner } from '../../../common/Spinner/Spinner';
import useModal from '../../../shared/hooks/useModal/useModal';
import AddProductModal from '../AddProductModal';
import { getAllProductsRequest } from '../services/actions';
import { productSelector } from '../services/selector';

import styles from './ProductsList.module.scss';
import ProductsListTable from './ProductsListTable';
import { useNavigate } from 'react-router-dom';
import ErrorResponse from '../../../shared/ErrorResponse';

export const ProductsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isShow, openModal, closeModal } = useModal();

  const { productsList, loading, getProductsErrorResponses } = useSelector(productSelector);

  const [tableData, setTableData] = useState<any[]>(productsList);

  useEffect(() => {
    dispatch(getAllProductsRequest());
  }, []);

  useEffect(() => {
    setTableData(productsList);
  }, [productsList]);

  useEffect(() => {
    if (Object.keys(getProductsErrorResponses).length !== 0 && getProductsErrorResponses.status === 403) {
      navigate('/error');
    }
  }, [getProductsErrorResponses]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(getProductsErrorResponses).length !== 0 ? <ErrorResponse error={getProductsErrorResponses} /> : null}
      <div className={styles.headerGroup}>
        <span className={styles.subtitle}>Продукція</span>
        {loading ? <Spinner /> : null}
      </div>
      <span>Створюйте та редагуйте профілі продукції що маркується</span>
      <section className={styles.searchSection}>
        <div className={styles.searchGroup}>
          <span>Фільтр по всім полям:</span>
          <InputSearch setTableData={setTableData} data={productsList} />
        </div>
        <Button variant="contained" className={styles.btn} onClick={openModal}>
          <span className={styles.btnText}>Створити новий</span>
        </Button>
      </section>
      <ProductsListTable data={tableData} />
      <AddProductModal isShow={isShow} closeModal={closeModal} />
    </div>
  );
};
