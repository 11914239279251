export const productInit = {
  id: null,
  gtin: '',
  internalProductCode: '',
  erpProductCode: '',
  internalName: '',
  marketName: '',
  weightGR: null,
  expirationDays: null,
  countryCode: '',
  aggregationUnitCapacity: null,
  createDate: '',
  creatorEmail: '',
  updateDate: '',
  updaterEmail: '',
  imagePath: '',
  fileData: '',
  externalNames: [],
  labelTemplates: [],
  packingConfigurations: [],
};
