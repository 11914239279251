import {
  CLOSE_ERROR_MODAL,
  GET_EXTERNAL_STATUSES_ERROR,
  GET_EXTERNAL_STATUSES_REQUEST,
  GET_EXTERNAL_STATUSES_SUCCESS,
  POST_GENERATE_CODES_ERROR,
  POST_GENERATE_CODES_REQUEST,
  POST_GENERATE_CODES_SUCCESS,
  POST_IMPORT_FILE_ERROR,
  POST_IMPORT_FILE_REQUEST,
  POST_IMPORT_FILE_SUCCESS,
  POST_VALIDATE_FILE_ERROR,
  POST_VALIDATE_FILE_REQUEST,
  POST_VALIDATE_FILE_SUCCESS,
} from './constants';
import { ExternalStatusesBody, ValidateFile } from '../../../../entities/ImportCodes/interfaces';

export const getExternalStatusesRequest = () => ({
  type: GET_EXTERNAL_STATUSES_REQUEST,
});
export const getExternalStatusesSuccess = (data: ExternalStatusesBody[]) => ({
  type: GET_EXTERNAL_STATUSES_SUCCESS,
  payload: data,
});
export const getExternalStatusesError = (error: any) => ({
  type: GET_EXTERNAL_STATUSES_ERROR,
  payload: error,
});

export const postValidateFileRequest = (data: ValidateFile) => ({
  type: POST_VALIDATE_FILE_REQUEST,
  payload: data,
});
export const postValidateFileSuccess = (data: any) => ({
  type: POST_VALIDATE_FILE_SUCCESS,
  payload: data,
});
export const postValidateFileError = (error: any) => ({
  type: POST_VALIDATE_FILE_ERROR,
  payload: error,
});

export const postImportFileRequest = (data: any) => ({
  type: POST_IMPORT_FILE_REQUEST,
  payload: data,
});
export const postImportFileSuccess = (data: any) => ({
  type: POST_IMPORT_FILE_SUCCESS,
  payload: data,
});
export const postImportFileError = (error: any) => ({
  type: POST_IMPORT_FILE_ERROR,
  payload: error,
});

export const closeErrorModal = () => ({
  type: CLOSE_ERROR_MODAL,
});

export const postGenerateCodesRequest = (data: any) => ({
  type: POST_GENERATE_CODES_REQUEST,
  payload: data,
});
export const postGenerateCodesSuccess = (data: any) => ({
  type: POST_GENERATE_CODES_SUCCESS,
  payload: data,
});
export const postGenerateCodesError = (data: any) => ({
  type: POST_GENERATE_CODES_ERROR,
  payload: data,
});

export type Actions =
  | ReturnType<typeof postGenerateCodesRequest>
  | ReturnType<typeof postGenerateCodesSuccess>
  | ReturnType<typeof postGenerateCodesError>
  | ReturnType<typeof closeErrorModal>
  | ReturnType<typeof postImportFileRequest>
  | ReturnType<typeof postImportFileSuccess>
  | ReturnType<typeof postImportFileError>
  | ReturnType<typeof postValidateFileRequest>
  | ReturnType<typeof postValidateFileSuccess>
  | ReturnType<typeof postValidateFileError>
  | ReturnType<typeof getExternalStatusesRequest>
  | ReturnType<typeof getExternalStatusesSuccess>
  | ReturnType<typeof getExternalStatusesError>;
