import {
  GET_TEXT_REPORT_REQUEST,
  GET_TEXT_REPORT_SUCCESS,
  GET_TEXT_REPORT_ERROR,
  CLOSE_ERROR_POPUP_SEARCH_CODES,
  GET_TREE_REPORT_REQUEST,
  GET_TREE_REPORT_SUCCESS,
  GET_TREE_REPORT_ERROR,
  GET_DOWNLOAD_REPORT_REQUEST,
  GET_DOWNLOAD_REPORT_SUCCESS,
  GET_DOWNLOAD_REPORT_ERROR,
} from './constants';

export const getTextReportRequest = (body: any) => ({
  type: GET_TEXT_REPORT_REQUEST,
  payload: body,
});
export const getTextReportSuccess = (response: any) => ({
  type: GET_TEXT_REPORT_SUCCESS,
  payload: response,
});
export const getTextReportError = (error: any) => ({
  type: GET_TEXT_REPORT_ERROR,
  payload: error,
});

export const getTreeReportRequest = (body: any) => ({
  type: GET_TREE_REPORT_REQUEST,
  payload: body,
});
export const getTreeReportSuccess = (response: any) => ({
  type: GET_TREE_REPORT_SUCCESS,
  payload: response,
});
export const getTreeReportError = (error: any) => ({
  type: GET_TREE_REPORT_ERROR,
  payload: error,
});

export const getDownloadReportRequest = (body: any) => ({
  type: GET_DOWNLOAD_REPORT_REQUEST,
  payload: body,
});
export const getDownloadReportSuccess = () => ({
  type: GET_DOWNLOAD_REPORT_SUCCESS,
});
export const getDownloadReportError = (error: any) => ({
  type: GET_DOWNLOAD_REPORT_ERROR,
  payload: error,
});

export const closeErrorPopupSearchCodes = () => ({
  type: CLOSE_ERROR_POPUP_SEARCH_CODES,
});

export type Actions =
  | ReturnType<typeof getDownloadReportRequest>
  | ReturnType<typeof getDownloadReportSuccess>
  | ReturnType<typeof getDownloadReportError>
  | ReturnType<typeof getTreeReportRequest>
  | ReturnType<typeof getTreeReportSuccess>
  | ReturnType<typeof getTreeReportError>
  | ReturnType<typeof closeErrorPopupSearchCodes>
  | ReturnType<typeof getTextReportRequest>
  | ReturnType<typeof getTextReportSuccess>
  | ReturnType<typeof getTextReportError>;
