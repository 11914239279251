import {
  CLOSE_ERROR_POPUP_COUNTRY_LIST,
  GET_COUNTRY_LIST_ERROR,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNTRY_LIST_SUCCESS,
} from './constants';

export const getCountryListRequest = () => ({
  type: GET_COUNTRY_LIST_REQUEST,
});

export const getCountryListSuccess = (data: any) => ({
  type: GET_COUNTRY_LIST_SUCCESS,
  payload: data,
});

export const getCountryListError = (error: any) => ({
  type: GET_COUNTRY_LIST_ERROR,
  payload: error,
});

export const closeErrorPopUpCountryList = () => ({
  type: CLOSE_ERROR_POPUP_COUNTRY_LIST,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpCountryList>
  | ReturnType<typeof getCountryListRequest>
  | ReturnType<typeof getCountryListSuccess>
  | ReturnType<typeof getCountryListError>;
