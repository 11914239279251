import { Actions } from './actions';
import {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  CLOSE_ERROR_POPUP_REPORT_ERROR,
  GET_GLOBAL_EXCEL_REPORT_INVOICES_ERROR,
  GET_GLOBAL_EXCEL_REPORT_INVOICES_REQUEST,
  GET_GLOBAL_EXCEL_REPORT_INVOICES_SUCCESS,
  GET_DETAILED_EXCEL_REPORT_INVOICES_REQUEST,
  GET_DETAILED_EXCEL_REPORT_INVOICES_SUCCESS,
  GET_DETAILED_EXCEL_REPORT_INVOICES_ERROR,
} from './constants';

const initialState = {
  loading: false as boolean,
  modalLoading: false as boolean,
  error: null as Error | null,

  invoicesReportResponse: [] as any,
  invoicesErrorResponses: {} as any,

  detailedInvoiceReportResponse: {} as any,
  detailedInvoiceReportErrorResponses: {} as any,
};

export type InvoicesReportState = typeof initialState;

export const InvoicesReportReducer = (state: InvoicesReportState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_INVOICES_REQUEST: {
      return {
        ...state,
        loading: true,
        invoicesReportResponse: [],
        invoicesErrorResponses: {},
      };
    }
    case GET_INVOICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        invoicesReportResponse: action.payload,
      };
    }
    case GET_INVOICES_ERROR: {
      return {
        ...state,
        loading: false,
        invoicesErrorResponses: action.payload,
      };
    }

    case GET_GLOBAL_EXCEL_REPORT_INVOICES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_GLOBAL_EXCEL_REPORT_INVOICES_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_GLOBAL_EXCEL_REPORT_INVOICES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_DETAILED_EXCEL_REPORT_INVOICES_REQUEST: {
      return {
        ...state,
        modalLoading: true,
        detailedInvoiceReportResponse: {},
      };
    }
    case GET_DETAILED_EXCEL_REPORT_INVOICES_SUCCESS: {
      return {
        ...state,
        modalLoading: false,
        detailedInvoiceReportResponse: action.payload,
      };
    }
    case GET_DETAILED_EXCEL_REPORT_INVOICES_ERROR: {
      return {
        ...state,
        modalLoading: false,
        detailedInvoiceReportErrorResponses: action.payload,
      };
    }

    case CLOSE_ERROR_POPUP_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        invoicesErrorResponses: {},
        detailedInvoiceReportErrorResponses: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
