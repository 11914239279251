import { Actions } from './actions';
import {
  CLOSE_ERROR_POPUP_SHIPPING,
  DELETE_FILTER_ERROR,
  DELETE_FILTER_REQUEST,
  DELETE_FILTER_SUCCESS,
  DELETE_PRODUCT_FROM_DOCUMENT_ERROR,
  DELETE_PRODUCT_FROM_DOCUMENT_REQUEST,
  DELETE_PRODUCT_FROM_DOCUMENT_SUCCESS,
  DOWNLOAD_AGGREGATION_REPORT_ERROR,
  DOWNLOAD_AGGREGATION_REPORT_REQUEST,
  DOWNLOAD_AGGREGATION_REPORT_SUCCESS,
  DOWNLOAD_SHIPPING_ORDER_ERROR,
  DOWNLOAD_SHIPPING_ORDER_REQUEST,
  DOWNLOAD_SHIPPING_ORDER_SUCCESS,
  GET_ALL_SHIPPING_ORDERS_ERROR,
  GET_ALL_SHIPPING_ORDERS_REQUEST,
  GET_ALL_SHIPPING_ORDERS_SUCCESS,
  GET_SHIPPING_ORDER_BY_DATE_ERROR,
  GET_SHIPPING_ORDER_BY_DATE_REQUEST,
  GET_SHIPPING_ORDER_BY_DATE_SUCCESS,
  GET_SHIPPING_ORDER_BY_ID_ERROR,
  GET_SHIPPING_ORDER_BY_ID_REQUEST,
  GET_SHIPPING_ORDER_BY_ID_SUCCESS,
  POST_CHECK_PRODUCT_ERROR,
  POST_CHECK_PRODUCT_REQUEST,
  POST_CHECK_PRODUCT_SUCCESS,
  POST_CHECK_SERIES_ERROR,
  POST_CHECK_SERIES_REQUEST,
  POST_CHECK_SERIES_SUCCESS,
  POST_CREATE_SHIPPING_ORDER_ERROR,
  POST_CREATE_SHIPPING_ORDER_REQUEST,
  POST_CREATE_SHIPPING_ORDER_SUCCESS,
  POST_UPDATE_ICON_SERIES_SUCCESS,
  PUT_UPDATE_SHIPPING_ORDER_ERROR,
  PUT_UPDATE_SHIPPING_ORDER_REQUEST,
  PUT_UPDATE_SHIPPING_ORDER_SUCCESS,
  SET_ERROR_MESSAGE_SHIPPINGS,
} from './constants';

const initialState = {
  loading: false as boolean,
  error: null as Error | null,
  shippingOrders: [] as any,
  order: {} as any,
  failedResponseEditor: [] as any,
  series: {} as any,

  getShippingErrorResponses: {} as any,
  createShippingErrorResponses: {} as any,
  updateShippingErrorResponses: {} as any,
  getShippingByIdErrorResponses: {} as any,
  checkSeriesErrorResponses: {} as any,
  checkProductErrorResponses: {} as any,
  deleteFilterErrorResponse: {} as any,
  deleteProductDocErrorResponse: {} as any,
};

export type ShippingOrdersState = typeof initialState;

export const ShippingOrdersReducer = (state: ShippingOrdersState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_ALL_SHIPPING_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true,
        shippingOrders: [],
      };
    }
    case GET_ALL_SHIPPING_ORDERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        shippingOrders: action.payload,
      };
    }
    case GET_ALL_SHIPPING_ORDERS_ERROR: {
      return {
        ...state,
        loading: false,
        getShippingErrorResponses: action.payload,
      };
    }

    case GET_SHIPPING_ORDER_BY_DATE_REQUEST: {
      return {
        ...state,
        loading: true,
        shippingOrders: [],
      };
    }
    case GET_SHIPPING_ORDER_BY_DATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        shippingOrders: action.payload,
      };
    }
    case GET_SHIPPING_ORDER_BY_DATE_ERROR: {
      return {
        ...state,
        loading: false,
        getShippingErrorResponses: action.payload,
      };
    }

    case POST_CREATE_SHIPPING_ORDER_REQUEST: {
      return {
        ...state,
        loading: true,
        order: [],
      };
    }
    case POST_CREATE_SHIPPING_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    }
    case POST_CREATE_SHIPPING_ORDER_ERROR: {
      return {
        ...state,
        loading: false,
        createShippingErrorResponses: action.payload,
      };
    }

    case GET_SHIPPING_ORDER_BY_ID_REQUEST: {
      return {
        ...state,
        loading: true,
        failedResponseEditor: [],
      };
    }
    case GET_SHIPPING_ORDER_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    }
    case GET_SHIPPING_ORDER_BY_ID_ERROR: {
      return {
        ...state,
        loading: false,
        getShippingByIdErrorResponses: action.payload,
      };
    }

    case PUT_UPDATE_SHIPPING_ORDER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PUT_UPDATE_SHIPPING_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case PUT_UPDATE_SHIPPING_ORDER_ERROR: {
      return {
        ...state,
        loading: false,
        updateShippingErrorResponses: action.payload,
      };
    }

    case POST_CHECK_SERIES_REQUEST: {
      return {
        ...state,
        loading: true,
        failedResponseEditor: [],
      };
    }
    case POST_CHECK_SERIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    }
    case POST_CHECK_SERIES_ERROR: {
      return {
        ...state,
        loading: false,
        checkSeriesErrorResponses: action.payload,
      };
    }

    case POST_CHECK_PRODUCT_REQUEST: {
      return {
        ...state,
        loading: true,
        failedResponseEditor: [],
      };
    }
    case POST_CHECK_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    }
    case POST_CHECK_PRODUCT_ERROR: {
      return {
        ...state,
        loading: false,
        checkProductErrorResponses: action.payload,
      };
    }

    case SET_ERROR_MESSAGE_SHIPPINGS: {
      return {
        ...state,
        failedResponseEditor: action.payload,
        loading: false,
      };
    }
    case POST_UPDATE_ICON_SERIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    }

    case DELETE_FILTER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_FILTER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_FILTER_ERROR: {
      return {
        ...state,
        loading: false,
        deleteFilterErrorResponse: action.payload,
      };
    }

    case DELETE_PRODUCT_FROM_DOCUMENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_PRODUCT_FROM_DOCUMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_PRODUCT_FROM_DOCUMENT_ERROR: {
      return {
        ...state,
        loading: false,
        deleteProductDocErrorResponse: action.payload,
      };
    }
    case CLOSE_ERROR_POPUP_SHIPPING: {
      return {
        ...state,
        getShippingErrorResponses: {} as any,
        createShippingErrorResponses: {} as any,
        updateShippingErrorResponses: {} as any,
        getShippingByIdErrorResponses: {} as any,
        checkSeriesErrorResponses: {} as any,
        checkProductErrorResponses: {} as any,
        deleteFilterErrorResponse: {} as any,
        deleteProductDocErrorResponse: {} as any,
      };
    }

    case DOWNLOAD_SHIPPING_ORDER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case DOWNLOAD_SHIPPING_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DOWNLOAD_SHIPPING_ORDER_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case DOWNLOAD_AGGREGATION_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case DOWNLOAD_AGGREGATION_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DOWNLOAD_AGGREGATION_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
