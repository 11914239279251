import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InputSearch from '../../common/InputSearch';
import useModal from '../../shared/hooks/useModal/useModal';

import styles from './Withdrawal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWithdrawalsRequest } from './services/actions';
import { withdrawalSelector } from './services/selector';
import { Spinner } from '../../common/Spinner/Spinner';
import ErrorResponse from '../../shared/ErrorResponse';
import { useNavigate } from 'react-router-dom';
import WithdrawalTable from './components/WithdrawalTable';
import AddWithdrawalModal from './components/AddWithdrawalModal';

export const Withdrawal: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isShow, openModal, closeModal } = useModal();

  const {
    withdrawals,
    loading,
    getWithdrawalsErrorResponses,
    addWithdrawalErrorResponses,
    updateWithdrawalErrorResponses,
  } = useSelector(withdrawalSelector);

  const [tableData, setTableData] = useState<any[]>(withdrawals);

  useEffect(() => {
    dispatch(getAllWithdrawalsRequest());
  }, []);

  useEffect(() => {
    setTableData(withdrawals);
  }, [withdrawals]);

  useEffect(() => {
    if (Object.keys(getWithdrawalsErrorResponses).length !== 0 && getWithdrawalsErrorResponses?.status === 403) {
      navigate('/error');
    }
  }, [getWithdrawalsErrorResponses]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(getWithdrawalsErrorResponses).length !== 0 ? (
        <ErrorResponse error={getWithdrawalsErrorResponses} />
      ) : null}
      {Object.keys(addWithdrawalErrorResponses).length !== 0 ? (
        <ErrorResponse error={addWithdrawalErrorResponses} />
      ) : null}
      {Object.keys(updateWithdrawalErrorResponses).length !== 0 ? (
        <ErrorResponse error={updateWithdrawalErrorResponses} />
      ) : null}

      <div className={styles.loadingGroup}>
        <span className={styles.packagingSubTitle}>Причини вилучень</span>
        {loading ? <Spinner /> : null}
      </div>
      <span>Створюйте і редагуйте причини вилучень</span>

      {tableData ? (
        <>
          <section className={styles.header}>
            <div className={styles.searchSection}>
              <div className={styles.searchGroup}>
                <span>Фільтр по всім полям:</span>
                <InputSearch setTableData={setTableData} data={withdrawals} />
              </div>
              <Button variant="contained" className={styles.btn} onClick={openModal}>
                <span className={styles.btnText}>Додати</span>
                <AddIcon className={styles.icon} />
              </Button>
            </div>
          </section>
          <section>
            <WithdrawalTable data={tableData} />
          </section>
        </>
      ) : null}

      <AddWithdrawalModal isShown={isShow} closeModal={closeModal} type="create" />
    </div>
  );
};
