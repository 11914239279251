export const GET_SENDERS_INFO_REQUEST = 'GET_SENDERS_INFO_REQUEST' as const;
export const GET_SENDERS_INFO_SUCCESS = 'GET_SENDERS_INFO_SUCCESS' as const;
export const GET_SENDERS_INFO_ERROR = 'GET_SENDERS_INFO_ERROR' as const;

export const GET_SENDERS_LOG_REQUEST = 'GET_SENDERS_LOG_REQUEST' as const;
export const GET_SENDERS_LOG_SUCCESS = 'GET_SENDERS_LOG_SUCCESS' as const;
export const GET_SENDERS_LOG_ERROR = 'GET_SENDERS_LOG_ERROR' as const;

export const UPDATE_SENDERS_STATE_REQUEST = 'UPDATE_SENDERS_STATE_REQUEST' as const;
export const UPDATE_SENDERS_STATE_SUCCESS = 'UPDATE_SENDERS_STATE_SUCCESS' as const;
export const UPDATE_SENDERS_STATE_ERROR = 'UPDATE_SENDERS_STATE_ERROR' as const;

export const GET_BACKGROUND_PROCESSES_REQUEST = 'GET_BACKGROUND_PROCESSES_REQUEST' as const;
export const GET_BACKGROUND_PROCESSES_SUCCESS = 'GET_BACKGROUND_PROCESSES_SUCCESS' as const;
export const GET_BACKGROUND_PROCESSES_ERROR = 'GET_BACKGROUND_PROCESSES_ERROR' as const;

export const CLOSE_ERROR_POPUP_SENDERS_INFO = 'CLOSE_ERROR_POPUP_SENDERS_INFO' as const;
