import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  deletePackingError,
  deletePackingRequest,
  deletePackingSuccess,
  getAllPackingsError,
  getAllPackingsRequest,
  getAllPackingsSuccess,
  getPackingByIdError,
  getPackingByIdRequest,
  getPackingByIdSuccess,
  postAddNewPackingError,
  postAddNewPackingSuccess,
  putUpdatePackingError,
  putUpdatePackingRequest,
  putUpdatePackingSuccess,
} from './actions';
import {
  DELETE_PACKING_REQUEST,
  GET_ALL_PACKING_REQUEST,
  GET_PACKING_BY_ID_REQUEST,
  POST_ADD_NEW_PACKING_REQUEST,
  PUT_UPDATE_PACKING_REQUEST,
} from './constants';
import { Packing } from '../../../../entities/Packing/service';

function* getAllPackingsSaga() {
  try {
    const response: AxiosResponse = yield call(Packing.getAllPackings);
    const res = response.data.ResponseBody;
    yield put(getAllPackingsSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getAllPackingsError(error));
  }
}

function* postAddNewPackingSaga() {
  try {
    const response: AxiosResponse = yield call(Packing.addNewPacking);
    const res = response.data.ResponseBody;
    yield put(postAddNewPackingSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postAddNewPackingError(error));
  }
}

function* getPackingByIdSaga(action: ReturnType<typeof getPackingByIdRequest>) {
  try {
    const response: AxiosResponse = yield call(Packing.getPackingById, action.payload);
    const res = response.data.ResponseBody;
    yield put(getPackingByIdSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getPackingByIdError(error));
  }
}

function* putUpdatePackingSaga(action: ReturnType<typeof putUpdatePackingRequest>) {
  try {
    const response: AxiosResponse = yield call(Packing.putUpdatePacking, action.payload);
    const res = response.data.ResponseBody;
    yield put(putUpdatePackingSuccess(res));
    if (res.messages.Successful !== null) {
      yield put(getPackingByIdSuccess({}));
      yield put(getAllPackingsRequest());
    }
  } catch (e: any) {
    const error = e.toJSON();
    yield put(putUpdatePackingError(error));
  }
}

function* deletePackingSaga(action: ReturnType<typeof deletePackingRequest>) {
  try {
    const response: AxiosResponse = yield call(Packing.deletePacking, action.payload);
    const res = response.data.ResponseBody;
    yield put(deletePackingSuccess(res));
    yield put(getAllPackingsRequest());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(deletePackingError(error));
  }
}

export function* PackagingSagaWatcher() {
  yield takeLatest(POST_ADD_NEW_PACKING_REQUEST, postAddNewPackingSaga);
  yield takeLatest(GET_PACKING_BY_ID_REQUEST, getPackingByIdSaga);
  yield takeLatest(GET_ALL_PACKING_REQUEST, getAllPackingsSaga);
  yield takeLatest(PUT_UPDATE_PACKING_REQUEST, putUpdatePackingSaga);
  yield takeLatest(DELETE_PACKING_REQUEST, deletePackingSaga);
}
