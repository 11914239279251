import { Box, Button, Modal } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { ModalSpinner } from '../../ModalSpinner/ModalSpinner';
import { postUnreserveMarkingCodesRequest } from '../../../EventsLog/SeriesReport/services/actions';
import { seriesReportSelector } from '../../../EventsLog/SeriesReport/services/selector';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 905,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
};

interface IReprintModalProps {
  isShown: boolean;
  innerModalLoading: boolean;
  closeModal: () => void;
  params: any;
}

export const ReprintInnerModal = ({ isShown, innerModalLoading, closeModal, params }: IReprintModalProps) => {
  const dispatch = useDispatch();

  const { postUnreserveMarkingCodes } = useSelector(seriesReportSelector);

  useEffect(() => {
    if (postUnreserveMarkingCodes?.length > 0) {
      closeModal();
    }
  }, [postUnreserveMarkingCodes]);

  return (
    <Modal
      open={isShown}
      onClose={() => {
        closeModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.innerModalSpinnerWrapper}>{innerModalLoading && <ModalSpinner />}</div>

        <span className={styles.title}>Виконуте із застереженням нижче!</span>
        <div className={styles.text}>
          <span>
            Оперерація скасує статус 'Надруковано' серійним номерам даного завдання, які ще не було агреговано.
          </span>
          <span>Перед виконанням слід фізично утилізувати всі неагреговані маркування за даним завданням.</span>
          <span>Виконати?</span>
        </div>

        <div className={styles.btnGroupInner}>
          <Button
            className={styles.submitBtn}
            disabled={innerModalLoading}
            variant="contained"
            onClick={() => {
              dispatch(postUnreserveMarkingCodesRequest(params));
              closeModal();
            }}
          >
            <span className={styles.btnText}>Так</span>
          </Button>
          <Button className={styles.exitBtn} variant="contained" onClick={closeModal}>
            <span className={styles.btnText}>Ні</span>
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
