import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddTemplateModal from '../../common/AddTemplateModal';
import InputSearch from '../../common/InputSearch';
import { TemplatesTable } from '../../common/TemplatesTable/TemplatesTable';
import useModal from '../../shared/hooks/useModal/useModal';
import { getUnitTemplatesRequest, postCreateUnitTemplatesRequest } from './services/actions';
import { unitTemplateSelector } from './services/selector';
import styles from './UnitTemplates.module.scss';
import { Spinner } from '../../common/Spinner/Spinner';
import ErrorResponse from '../../shared/ErrorResponse';
import { useNavigate } from 'react-router-dom';
import { getCountryListRequest } from '../../../entities/Countries/actions';

export const UnitTemplates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isShow, openModal, closeModal } = useModal();

  useEffect(() => {
    dispatch(getUnitTemplatesRequest({ level: 0 }));
  }, []);

  const onAddHandler = () => {
    dispatch(getCountryListRequest());
    dispatch(postCreateUnitTemplatesRequest());
    openModal();
  };

  const { unitTemplates, createdUnitTemplates, loading, getUnitTemplatesError } = useSelector(unitTemplateSelector);

  const [tableData, setTableData] = useState<any[]>(unitTemplates);

  useEffect(() => {
    setTableData(unitTemplates);
  }, [unitTemplates]);

  useEffect(() => {
    if (Object.keys(getUnitTemplatesError).length !== 0 && getUnitTemplatesError?.status === 403) {
      navigate('/error');
    }
  }, [getUnitTemplatesError]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(getUnitTemplatesError).length !== 0 ? <ErrorResponse error={getUnitTemplatesError} /> : null}
      <section className={styles.templateHeader}>
        <div className={styles.loadingGroup}>
          <span className={styles.templateSubTitle}>Шаблони продукції (Level 0)</span>
          {loading ? <Spinner /> : null}
        </div>
        <span>Створюйте і редагуйте шаблони маркування продукції</span>
      </section>
      <section className={styles.header}>
        <div className={styles.searchSection}>
          <div className={styles.searchGroup}>
            <span>Фільтр по всім полям:</span>
            <InputSearch setTableData={setTableData} data={unitTemplates} />
          </div>
          <Button variant="contained" type="button" className={styles.btn} onClick={onAddHandler}>
            <span className={styles.btnText}>Додати</span>
            <AddIcon className={styles.icon} />
          </Button>
        </div>
      </section>
      <section>
        <TemplatesTable data={tableData} level={0} />
      </section>
      <AddTemplateModal isShown={isShow} closeModal={closeModal} level={0} data={createdUnitTemplates} />
    </div>
  );
};
