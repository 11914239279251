import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  deletePalletTemplatesByIdError,
  deletePalletTemplatesByIdRequest,
  deletePalletTemplatesByIdSuccess,
  getPalletTemplatesByIdError,
  getPalletTemplatesByIdRequest,
  getPalletTemplatesByIdSuccess,
  getPalletTemplatesError,
  getPalletTemplatesRequest,
  getPalletTemplatesSuccess,
  getPhotoPalletError,
  getPhotoPalletRequest,
  getPhotoPalletSuccess,
  postCreatePalletTemplatesError,
  postCreatePalletTemplatesSuccess,
  postUpdatePhotoPalletTemplatesError,
  postUpdatePhotoPalletTemplatesRequest,
  postUpdatePhotoPalletTemplatesSuccess,
  putUpdatePalletTemplatesError,
  putUpdatePalletTemplatesRequest,
  putUpdatePalletTemplatesSuccess,
} from './actions';
import {
  DELETE_PALLET_TEMPLATES_BY_ID_REQUEST,
  GET_PALLET_TEMPLATES_BY_ID_REQUEST,
  GET_PALLET_TEMPLATES_REQUEST,
  GET_PHOTO_PALLET_REQUEST,
  POST_CREATE_PALLET_TEMPLATES_REQUEST,
  POST_UPDATE_PHOTO_PALLET_TEMPLATES_REQUEST,
  PUT_UPDATE_PALLET_TEMPLATES_REQUEST,
} from './constants';
import { Templates } from '../../../../entities/Templates/service';

import { objToFormData } from '../../../../utils';

function* getPalletTemplatesSaga(action: ReturnType<typeof getPalletTemplatesRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.getTemplates, action.payload);
    const res = response.data.ResponseBody;
    yield put(getPalletTemplatesSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getPalletTemplatesError(error));
  }
}

function* getPalletTemplatesByIdSaga(action: ReturnType<typeof getPalletTemplatesByIdRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.getTemplatesById, action.payload);
    const res = response.data.ResponseBody;
    yield put(getPalletTemplatesByIdSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getPalletTemplatesByIdError(error));
  }
}

function* deletePalletTemplatesByIdSaga(action: ReturnType<typeof deletePalletTemplatesByIdRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.deleteTemplatesById, action.payload);
    const res = response.data.ResponseBody;
    yield put(deletePalletTemplatesByIdSuccess(res));
    yield put(getPalletTemplatesRequest({ level: 3 }));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(deletePalletTemplatesByIdError(error));
  }
}

function* postCreatePalletTemplatesSaga() {
  try {
    const response: AxiosResponse = yield call(Templates.postCreateTemplates);
    const res = response.data.ResponseBody;
    yield put(postCreatePalletTemplatesSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postCreatePalletTemplatesError(error));
  }
}

function* putUpdatePalletTemplatesSaga(action: ReturnType<typeof putUpdatePalletTemplatesRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.putUpdateTemplates, action.payload);
    const res = response.data.ResponseBody;
    yield put(putUpdatePalletTemplatesSuccess(res));
    if (res.messages.Successful !== null) {
      yield put(postCreatePalletTemplatesSuccess({}));
      yield put(getPalletTemplatesRequest({ level: 3 }));
    }
  } catch (e: any) {
    const error = e.toJSON();
    yield put(putUpdatePalletTemplatesError(error));
  }
}

function* postUpdatePhotoPalletTemplatesSaga(action: ReturnType<typeof postUpdatePhotoPalletTemplatesRequest>) {
  try {
    const body = objToFormData(action.payload);
    const response: AxiosResponse = yield call(Templates.photoUpload, body);
    const res = response.data.ResponseBody;
    yield put(postUpdatePhotoPalletTemplatesSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postUpdatePhotoPalletTemplatesError(error));
  }
}

function* getPhotoPalletSaga(action: ReturnType<typeof getPhotoPalletRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.getPhoto, action.payload);
    const res = URL.createObjectURL(response.data);
    yield put(getPhotoPalletSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getPhotoPalletError(error));
  }
}

export function* PalletTemplatesSagaWatcher() {
  yield takeLatest(GET_PHOTO_PALLET_REQUEST, getPhotoPalletSaga);
  yield takeLatest(POST_UPDATE_PHOTO_PALLET_TEMPLATES_REQUEST, postUpdatePhotoPalletTemplatesSaga);
  yield takeLatest(GET_PALLET_TEMPLATES_REQUEST, getPalletTemplatesSaga);
  yield takeLatest(POST_CREATE_PALLET_TEMPLATES_REQUEST, postCreatePalletTemplatesSaga);
  yield takeLatest(PUT_UPDATE_PALLET_TEMPLATES_REQUEST, putUpdatePalletTemplatesSaga);
  yield takeLatest(GET_PALLET_TEMPLATES_BY_ID_REQUEST, getPalletTemplatesByIdSaga);
  yield takeLatest(DELETE_PALLET_TEMPLATES_BY_ID_REQUEST, deletePalletTemplatesByIdSaga);
}
