import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import styles from './MenuDropdown.module.scss';
import { useStyles } from './styles';

interface Props {
  value: any;
  handleClick: any;
  handleClose: any;
  title: any;
  items: any;
  isActiveTab: boolean;
}

export const MenuDropdown: React.FC<Props> = ({ title, value, handleClick, handleClose, items, isActiveTab }) => {
  const { menu, list } = useStyles();

  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        className={isActiveTab ? styles.isActive : styles.btn}
        onClick={handleClick}
      >
        <span className={styles.label}>{title}</span>
      </Button>
      <Menu id="simple-menu" className={menu} anchorEl={value} keepMounted open={Boolean(value)} onClose={handleClose}>
        {items?.map((item: any) => (
          <MenuItem
            onClick={() => {
              navigate(`${item.link}`);
              handleClose();
            }}
            className={list}
          >
            <NavLink to={item.link} className={styles.navlink}>
              {item.key}
            </NavLink>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
