import { combineReducers } from 'redux';
import { codeBankModuleReducer } from './modules/Bank/codeBankModuleReducer';
import { entitiesModuleReducer } from './entities/moduleReducer';
import { handbookModuleReducer } from './modules/Handbook/handbookModuleReducer';
import { templatesModuleReducer } from './modules/Templates/templatesModuleReducer';
import { operationsModuleReducer } from './modules/OperationsAndTools/operationsModuleReducer';
import { documentModuleReducer } from './modules/Documents/documentModuleReducer';
import { eventsLogModuleReducer } from './modules/EventsLog/eventsLogModuleReducer';
import { authModuleReducer } from './modules/Auth/moduleReducer';
import { mainModuleReducer } from './modules/Main/mainModuleReducer';
import { aboutModuleReducer } from './modules/About/aboutModuleReducer';

const rootReducer = combineReducers({
  documentModuleReducer,
  codeBankModuleReducer,
  entitiesModuleReducer,
  handbookModuleReducer,
  templatesModuleReducer,
  operationsModuleReducer,
  eventsLogModuleReducer,
  authModuleReducer,
  mainModuleReducer,
  aboutModuleReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
