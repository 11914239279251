import { Actions } from './actions';
import {
  CLEAR_PHOTO_BOX,
  CLOSE_ERROR_POPUP_BOX,
  GET_BOX_TEMPLATES_BY_ID_REQUEST,
  GET_BOX_TEMPLATES_BY_ID_SUCCESS,
  GET_BOX_TEMPLATES_ERROR,
  GET_BOX_TEMPLATES_REQUEST,
  GET_BOX_TEMPLATES_SUCCESS,
  GET_PHOTO_BOX_REQUEST,
  GET_PHOTO_BOX_SUCCESS,
  POST_CREATE_BOX_TEMPLATES_SUCCESS,
  PUT_UPDATE_BOX_TEMPLATES_SUCCESS,
} from './constants';

const initialState = {
  loading: false as boolean,
  photoLoading: false as boolean,
  error: null as Error | null,
  boxTemplates: [] as any,
  createdBoxTemplate: {} as any,
  selectedBoxTemplate: {} as any,
  updateBoxResponse: {} as any,

  getBoxTemplateError: {} as any,

  photoBox: null as any,
};

export type BoxTemplateState = typeof initialState;

export const BoxTemplateReducer = (state: BoxTemplateState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_BOX_TEMPLATES_REQUEST: {
      return {
        ...state,
        selectedBoxTemplate: {},
        loading: true,
        getBoxTemplateError: {} as any,
      };
    }
    case GET_BOX_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        boxTemplates: action.payload,
      };
    }
    case GET_BOX_TEMPLATES_ERROR: {
      return {
        ...state,
        loading: false,
        getBoxTemplateError: action.payload,
      };
    }
    case POST_CREATE_BOX_TEMPLATES_SUCCESS: {
      return {
        ...state,
        createdBoxTemplate: action.payload,
      };
    }
    case GET_BOX_TEMPLATES_BY_ID_REQUEST: {
      return {
        ...state,
        loading: true,
        selectedBoxTemplate: {},
      };
    }
    case GET_BOX_TEMPLATES_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedBoxTemplate: action.payload,
        photoBox: null,
      };
    }
    case PUT_UPDATE_BOX_TEMPLATES_SUCCESS: {
      return {
        ...state,
        updateBoxResponse: action.payload,
      };
    }
    case GET_PHOTO_BOX_REQUEST: {
      return {
        ...state,
        photoLoading: true,
        photoBox: null,
      };
    }
    case GET_PHOTO_BOX_SUCCESS: {
      return {
        ...state,
        photoLoading: false,
        photoBox: action.payload,
      };
    }
    case CLEAR_PHOTO_BOX: {
      return {
        ...state,
        photoBox: null,
      };
    }
    case CLOSE_ERROR_POPUP_BOX: {
      return {
        ...state,
        getBoxTemplateError: {} as any,
      };
    }
    default: {
      return { ...state };
    }
  }
};
