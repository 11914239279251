export const style = {
  backgroundColor: '#F0F2F41',
  fontSize: '13px !important',
  '.MuiTablePagination-selectLabel': {
    marginBottom: '0 !important',
    fontSize: '13px',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    overflow: 'hidden !important',
    lineHeight: '13px !important',
    whiteSpace: 'normal !important',
  },
  '& .MuiDataGrid-row': {
    backgroundColor: '#ffffff !important',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: ' none',
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: '#ffffff !important',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#ECEFF1 !important',
  },
  '& .MuiDataGrid-row:focus': {
    backgroundColor: '#ffffff !important',
  },
  '& .MuiDataGrid-row.Mui-selected:hover': {
    backgroundColor: '#ffffff !important',
  },
  '.MuiTablePagination-displayedRows': {
    marginBottom: '0 !important',
    fontSize: '14px',
  },
};
