import { put, takeLatest } from 'redux-saga/effects';
import { AuthenticationResult } from '@azure/msal-browser';
import { msalInstance } from '../../index';
import { setAccount } from './actions';
import { GET_ACCOUNT, msalScopes } from './constants';

function* getAccountSaga() {
  const account = msalInstance.getAllAccounts()[0];
  if (account) {
    localStorage.setItem('GatewayLogin', account.username);
  }
  try {
    const token: AuthenticationResult = yield msalInstance.acquireTokenSilent({
      // @ts-expect-error
      scopes: msalScopes,
      account,
    });

    yield put(setAccount(token.account));
  } catch (error: any) {
    throw Error(error);
  }
}

export function* authSagaWatcher() {
  yield takeLatest(GET_ACCOUNT, getAccountSaga);
}
