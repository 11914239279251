import { Actions } from './actions';
import {
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_ERROR,
  POST_UPDATE_SETTINGS_REQUEST,
  POST_UPDATE_SETTINGS_SUCCESS,
  POST_UPDATE_SETTINGS_ERROR,
  GET_DATA_TRUE_API_REQUEST,
  GET_DATA_TRUE_API_SUCCESS,
  GET_DATA_TRUE_API_ERROR,
  GET_KEY_RESPONSE_ERROR,
} from './constants';

const initialState = {
  loading: false as boolean,
  trueApiLoading: false as boolean,
  error: null as Error | null,
  settings: {} as any,
  trueApiResponse: {} as any,

  settingsErrorResponses: {} as any,
  trueApiErrorResponse: {} as any,
  getKeyError: {} as any,
};

export type SettingsState = typeof initialState;

export const SettingsReducer = (state: SettingsState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_SETTINGS_REQUEST: {
      return {
        ...state,
        loading: true,
        settingsErrorResponses: {},
        settings: {},
      };
    }
    case GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        settings: action.payload,
      };
    }
    case GET_SETTINGS_ERROR: {
      return {
        ...state,
        loading: false,
        settingsErrorResponses: action.payload,
      };
    }

    case POST_UPDATE_SETTINGS_REQUEST: {
      return {
        ...state,
        loading: true,
        settingsErrorResponses: {},
      };
    }
    case POST_UPDATE_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case POST_UPDATE_SETTINGS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_DATA_TRUE_API_REQUEST: {
      return {
        ...state,
        trueApiLoading: true,
        trueApiResponse: {},
        trueApiErrorResponse: {},
      };
    }
    case GET_DATA_TRUE_API_SUCCESS: {
      return {
        ...state,
        trueApiLoading: false,
        trueApiResponse: action.payload,
      };
    }
    case GET_DATA_TRUE_API_ERROR: {
      return {
        ...state,
        trueApiLoading: false,
        trueApiErrorResponse: action.payload,
      };
    }

    case GET_KEY_RESPONSE_ERROR: {
      return {
        ...state,
        getKeyError: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
