import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { noRow } from '../../shared/NoRow/NoRow';

import styles from '../DataTable/DataTable.module.scss';

const style = {
  backgroundColor: '#ffffff',
  fontSize: '13px !important',
  '.MuiDataGrid-columnHeaderTitle': {
    overflow: 'hidden !important',
    lineHeight: '14px !important',
    whiteSpace: 'normal !important',
  },
  '.MuiTablePagination-selectLabel': {
    marginBottom: '0 !important',
  },
  '.MuiDataGrid-root': {
    fontSize: '13px !important',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: ' none',
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: '#ffffff',
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#ffffff',
  },
  '& .MuiDataGrid-row:focus': {
    backgroundColor: '#ffffff',
  },
  '& .MuiDataGrid-row.Mui-selected:hover': {
    backgroundColor: '#ffffff',
  },
  '.MuiTablePagination-displayedRows': {
    marginBottom: '0 !important',
  },
};

interface Props {
  data: any;
  loading?: boolean;
}

export const ExportListTable: React.FC<Props> = ({ data, loading }) => {
  const [pageSize, setPageSize] = React.useState(10);

  return (
    <div style={{ height: 527, width: '100%', marginTop: '20px' }}>
      <DataGrid
        rows={data}
        rowHeight={40}
        columns={[
          { field: 'number', headerName: 'Номер документу', width: 130 },
          { field: 'externalOrderNumber', headerName: 'Номер зовнішнього замовлення', width: 170 },
          {
            field: 'createDate',
            headerName: 'Дата створення',
            width: 100,
            renderCell: (params: GridRenderCellParams<Date>) => (
              <span>{moment(params.row.createDate).format('DD-MM-YYYY')}</span>
            ),
          },
          {
            field: 'documentStatus',
            headerName: 'Статус',
            width: 200,
            renderCell: (params: GridRenderCellParams<Date>) => (
              <span>
                {params.row.documentStatus === 1
                  ? 'Комплектація дозволена'
                  : params.row.documentStatus === 2
                  ? 'Комплектація завершена'
                  : 'Відвантаження дозволено'}
              </span>
            ),
          },
          {
            field: 'updateDate',
            headerName: 'Дата оновлення',
            width: 140,
            renderCell: (params: GridRenderCellParams<Date>) => (
              <span>{moment(params.row.updateDate).format('DD-MM-YYYY HH:mm')}</span>
            ),
          },
          { field: 'clientId', headerName: 'Загальна кількість одиниць, фактична, шт', width: 170 },
          { field: 'jobQuantity', headerName: 'Кількість пакувань Рівня 2 BOX, шт', width: 170 },
          { field: 'boxesShippedCount', headerName: 'Відвантажено Level 2 BOX, шт', width: 170 },
          {
            field: 'button',
            headerName: '',
            minWidth: 40,
            flex: 0.1,
            align: 'right',
            renderCell: (params: GridRenderCellParams<Date>) => (
              <NavLink className={loading ? styles.disabledLink : ''} to={`/export_lists/entry?${params.row.id}`}>
                <EditIcon className={styles.icon} />
              </NavLink>
            ),
          },
        ]}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        components={{
          NoRowsOverlay: noRow,
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 50, 100]}
        pagination
        sx={style}
      />
    </div>
  );
};
