import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  deleteBoxTemplatesByIdError,
  deleteBoxTemplatesByIdRequest,
  deleteBoxTemplatesByIdSuccess,
  getBoxTemplatesByIdError,
  getBoxTemplatesByIdRequest,
  getBoxTemplatesByIdSuccess,
  getBoxTemplatesError,
  getBoxTemplatesRequest,
  getBoxTemplatesSuccess,
  getPhotoBoxError,
  getPhotoBoxRequest,
  getPhotoBoxSuccess,
  postCreateBoxTemplatesError,
  postCreateBoxTemplatesSuccess,
  postUpdatePhotoBoxTemplatesError,
  postUpdatePhotoBoxTemplatesRequest,
  postUpdatePhotoBoxTemplatesSuccess,
  putUpdateBoxTemplatesError,
  putUpdateBoxTemplatesRequest,
  putUpdateBoxTemplatesSuccess,
} from './actions';
import {
  DELETE_BOX_TEMPLATES_BY_ID_REQUEST,
  GET_BOX_TEMPLATES_BY_ID_REQUEST,
  GET_BOX_TEMPLATES_REQUEST,
  GET_PHOTO_BOX_REQUEST,
  POST_CREATE_BOX_TEMPLATES_REQUEST,
  POST_UPDATE_PHOTO_BOX_TEMPLATES_REQUEST,
  PUT_UPDATE_BOX_TEMPLATES_REQUEST,
} from './constants';
import { Templates } from '../../../../entities/Templates/service';
import { objToFormData } from '../../../../utils';

function* getBoxTemplatesSaga(action: ReturnType<typeof getBoxTemplatesRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.getTemplates, action.payload);
    const res = response.data.ResponseBody;
    yield put(getBoxTemplatesSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getBoxTemplatesError(error));
  }
}

function* getBoxTemplatesByIdSaga(action: ReturnType<typeof getBoxTemplatesByIdRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.getTemplatesById, action.payload);
    const res = response.data.ResponseBody;
    yield put(getBoxTemplatesByIdSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getBoxTemplatesByIdError(error));
  }
}

function* deleteBoxTemplatesByIdSaga(action: ReturnType<typeof deleteBoxTemplatesByIdRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.deleteTemplatesById, action.payload);
    const res = response.data.ResponseBody;
    yield put(deleteBoxTemplatesByIdSuccess(res));
    yield put(getBoxTemplatesRequest({ level: 2 }));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(deleteBoxTemplatesByIdError(error));
  }
}

function* postCreateBoxTemplatesSaga() {
  try {
    const response: AxiosResponse = yield call(Templates.postCreateTemplates);
    const res = response.data.ResponseBody;
    yield put(postCreateBoxTemplatesSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postCreateBoxTemplatesError(error));
  }
}

function* putUpdateBoxTemplatesSaga(action: ReturnType<typeof putUpdateBoxTemplatesRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.putUpdateTemplates, action.payload);
    const res = response.data.ResponseBody;
    yield put(putUpdateBoxTemplatesSuccess(res));
    if (res.messages.Successful !== null) {
      yield put(getBoxTemplatesRequest({ level: 2 }));
      yield put(postCreateBoxTemplatesSuccess({}));
    }
  } catch (e: any) {
    const error = e.toJSON();
    yield put(putUpdateBoxTemplatesError(error));
  }
}

function* postUpdatePhotoBoxTemplatesSaga(action: ReturnType<typeof postUpdatePhotoBoxTemplatesRequest>) {
  try {
    const body = objToFormData(action.payload);
    const response: AxiosResponse = yield call(Templates.photoUpload, body);
    const res = response.data.ResponseBody;
    yield put(postUpdatePhotoBoxTemplatesSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postUpdatePhotoBoxTemplatesError(error));
  }
}

function* getPhotoBoxSaga(action: ReturnType<typeof getPhotoBoxRequest>) {
  try {
    const response: AxiosResponse = yield call(Templates.getPhoto, action.payload);
    const res = URL.createObjectURL(response.data);
    yield put(getPhotoBoxSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getPhotoBoxError(error));
  }
}

export function* BoxTemplatesSagaWatcher() {
  yield takeLatest(POST_UPDATE_PHOTO_BOX_TEMPLATES_REQUEST, postUpdatePhotoBoxTemplatesSaga);
  yield takeLatest(GET_PHOTO_BOX_REQUEST, getPhotoBoxSaga);
  yield takeLatest(GET_BOX_TEMPLATES_REQUEST, getBoxTemplatesSaga);
  yield takeLatest(POST_CREATE_BOX_TEMPLATES_REQUEST, postCreateBoxTemplatesSaga);
  yield takeLatest(PUT_UPDATE_BOX_TEMPLATES_REQUEST, putUpdateBoxTemplatesSaga);
  yield takeLatest(GET_BOX_TEMPLATES_BY_ID_REQUEST, getBoxTemplatesByIdSaga);
  yield takeLatest(DELETE_BOX_TEMPLATES_BY_ID_REQUEST, deleteBoxTemplatesByIdSaga);
}
