export const GET_AUDITTRAIL_REPORT_REQUEST = 'GET_AUDITTRAIL_REPORT_REQUEST' as const;
export const GET_AUDITTRAIL_REPORT_SUCCESS = 'GET_AUDITTRAIL_REPORT_SUCCESS' as const;
export const GET_AUDITTRAIL_REPORT_ERROR = 'GET_AUDITTRAIL_REPORT_ERROR' as const;

export const CLOSE_ERROR_POPUP_AUDITTRAIL_REPORT_ERROR = 'CLOSE_ERROR_POPUP_AUDITTRAIL_REPORT_ERROR' as const;

export const GET_GLOBAL_EXCEL_REPORT_REQUEST = 'GET_GLOBAL_EXCEL_REPORT_REQUEST' as const;
export const GET_GLOBAL_EXCEL_REPORT_SUCCESS = 'GET_GLOBAL_EXCEL_REPORT_SUCCESS' as const;
export const GET_GLOBAL_EXCEL_REPORT_ERROR = 'GET_GLOBAL_EXCEL_REPORT_ERROR' as const;

export const GET_PDF_FILE_REQUEST = 'GET_PDF_FILE_REQUEST' as const;
export const GET_PDF_FILE_SUCCESS = 'GET_PDF_FILE_SUCCESS' as const;
export const GET_PDF_FILE_ERROR = 'GET_PDF_FILE_ERROR' as const;
