import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ErrorResponse from '../../shared/ErrorResponse';
import {
  getDownloadReportRequest,
  getTextReportRequest,
  getTextReportSuccess,
  getTreeReportRequest,
  getTreeReportSuccess,
} from './services/actions';
import { searchCodesSelector } from './services/selector';
import styles from './Tree.module.scss';
import { Button, CardMedia, MenuItem, OutlinedInput, SelectChangeEvent, Tooltip } from '@mui/material';
import SelectField from '../../common/SelectField';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import Lab from './icons/lab.svg';
import Pallet from './icons/pallet.svg';
import Pill from './icons/pill.svg';
import Inventory from './icons/inventory.svg';
import { Spinner } from '../../common/Spinner/Spinner';
import TreeSVG from './Tree/TreeSVG';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import ImageViewerModal from '../../common/ImageViewerModal';
import useModal from '../../shared/hooks/useModal/useModal';

const inputStyle = {
  width: 200,
  height: 30,
  fontSize: '13px !important',
  marginTop: '10px',
  backgroundColor: '#ffffff',
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
};

export const SearchCodes = () => {
  const dispatch = useDispatch();
  const initialValues = {
    level: 2,
    SSCC: '',
    res: 1,
  };

  const { textReport, graphReport, getTextReportErrorResponse, loading, downloadLoading, downloadReportErrorResponse } =
    useSelector(searchCodesSelector);

  const { isShow: isShownViewer, openModal: openViewerModal, closeModal: closeViewerModal } = useModal();
  const [photo, setPhoto] = useState();

  const downloadReport = (fileName: string) => {
    dispatch(getDownloadReportRequest(fileName));
  };

  const onShowHandler = (values: any) => {
    if (values.res === 1) {
      dispatch(getTextReportRequest(values));
    } else {
      dispatch(getTreeReportRequest(values));
    }
  };

  useEffect(() => {
    dispatch(getTextReportSuccess([]));
    dispatch(getTreeReportSuccess([]));
  }, [dispatch]);

  useEffect(() => {
    const levelWithPhoto = textReport.find((text: any) => text.section === 0 && text.reportPhoto);

    if (levelWithPhoto) {
      setPhoto(levelWithPhoto.reportPhoto);
    }
  }, [textReport]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(getTextReportErrorResponse).length !== 0 ? (
        <ErrorResponse error={getTextReportErrorResponse} />
      ) : null}
      {Object.keys(downloadReportErrorResponse).length !== 0 ? (
        <ErrorResponse error={downloadReportErrorResponse} />
      ) : null}
      <section className={styles.header}>
        <div>
          <span className={styles.title}>Пошук по кодам маркувань</span>
          {loading || downloadLoading ? <Spinner /> : null}
        </div>
        <span>Детальна історія коду маркування</span>
      </section>
      <Formik initialValues={initialValues} onSubmit={() => undefined}>
        {({ handleSubmit, handleChange, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <section className={styles.filter}>
              <div className={styles.column}>
                <span className={styles.title}>Параметр пошуку:</span>
                <SelectField
                  title=""
                  name="level"
                  value={values.level}
                  handleChangeSelect={(e: SelectChangeEvent<any>) => {
                    setFieldValue('level', e.target.value);
                    setFieldValue('SSCC', '');
                    dispatch(getTextReportSuccess([]));
                    dispatch(getTreeReportSuccess([]));
                  }}
                  variant="noLabel"
                  disabled={false}
                >
                  <MenuItem value={2} sx={{ fontSize: '13px' }}>
                    SSCC ящика чи палети
                  </MenuItem>
                  <MenuItem value={0} sx={{ fontSize: '13px' }}>
                    Серійний номер продукту
                  </MenuItem>
                </SelectField>
              </div>
              <div className={styles.column}>
                <span className={styles.title}>Значення:</span>
                <OutlinedInput
                  defaultValue=""
                  autoComplete="off"
                  sx={inputStyle}
                  name="SSCC"
                  onChange={(e) => {
                    dispatch(getTextReportSuccess([]));
                    dispatch(getTreeReportSuccess([]));
                    handleChange(e);
                  }}
                  value={values.SSCC}
                  inputProps={{ maxLength: 40 }}
                />
              </div>
              <div className={styles.column}>
                <span className={styles.title}>Показати результат як:</span>
                <SelectField title="" name="res" value={values.res} variant="noLabel" disabled={false}>
                  <MenuItem value={1} sx={{ fontSize: '13px' }}>
                    В текстовому вигляді
                  </MenuItem>
                  <MenuItem value={2} sx={{ fontSize: '13px' }}>
                    В графічному вигляді
                  </MenuItem>
                </SelectField>
              </div>
              <Button
                variant="contained"
                className={styles.btn}
                disabled={values.SSCC.trim().length === 0}
                onClick={() => onShowHandler(values)}
              >
                <span className={styles.btnText}>Пошук</span>
              </Button>
            </section>
            {values.res === 1 && textReport?.length !== 0 && textReport !== null ? (
              <section className={styles.text}>
                {textReport.map((text: any) => (
                  <div className={styles.reportItem}>
                    <span className={styles.reportItemSection}>
                      {text?.section === 0 ? (
                        <img className={styles.img} src={Pill} alt="" />
                      ) : text?.section === 1 ? (
                        <img className={styles.img} src={Inventory} alt="" />
                      ) : text?.section === 2 ? (
                        <img className={styles.img} src={Pallet} alt="" />
                      ) : (
                        <img className={styles.img} src={Lab} alt="" />
                      )}
                    </span>
                    <div className={styles.reportItemDataWrap}>
                      {Object.entries(text?.data).map(([key, value]: any) => (
                        <div className={styles.reportItemData}>
                          <span className={styles.reportItemDataKey}>{key}:</span>
                          <span className={styles.reportItemDataValue}>{value?.toString()}</span>
                        </div>
                      ))}
                    </div>
                    {text?.reportPhoto && (
                      <div className={styles.photoWrapper}>
                        <CardMedia
                          component="img"
                          onClick={openViewerModal}
                          image={text?.reportPhoto}
                          sx={{ objectFit: 'contain', width: '300px', height: '150px', bgcolor: '#D9D9D9' }}
                        />

                        <div className={styles.iconWrapper}>
                          <Tooltip title="Збільшити зображення" placement="right">
                            <ZoomInIcon className={styles.img} onClick={openViewerModal} />
                          </Tooltip>
                          <a onClick={() => downloadReport(text?.reportSignature)}>
                            <Tooltip title="Скачати цифровий звіт" placement="right">
                              <TroubleshootIcon className={styles.itemIcon} />
                            </Tooltip>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </section>
            ) : null}
            {values.res === 2 && graphReport?.length !== 0 && graphReport !== null ? (
              <div className={styles.contentWrapper}>
                <ParentSize>
                  {({ width, height }) => <TreeSVG width={width} height={height} data={graphReport} />}
                </ParentSize>
              </div>
            ) : null}
            {textReport === null || graphReport === null ? (
              <div className={styles.contentWrapper}>
                <span className={styles.error}>Помилка. Даних не знайдено.</span>
              </div>
            ) : null}
          </form>
        )}
      </Formik>

      <ImageViewerModal
        isShownViewer={isShownViewer}
        closeViewerModal={closeViewerModal}
        extended={false}
        img={photo}
      />
    </div>
  );
};
