import {
  CLOSE_ERROR_POPUP_BANK,
  GET_ALL_CODES_ERROR,
  GET_ALL_CODES_REQUEST,
  GET_ALL_CODES_SUCCESS,
  POST_ALLOW_TO_PRINT_ERROR,
  POST_ALLOW_TO_PRINT_REQUEST,
  POST_ALLOW_TO_PRINT_SUCCESS,
  POST_DETAILED_INFO_ERROR,
  POST_DETAILED_INFO_REQUEST,
  POST_DETAILED_INFO_SUCCESS,
} from './constants';

export const getAllCodesRequest = () => ({
  type: GET_ALL_CODES_REQUEST,
});
export const getAllCodesSuccess = (data: any) => ({
  type: GET_ALL_CODES_SUCCESS,
  payload: data,
});
export const getAllCodesError = (error: any) => ({
  type: GET_ALL_CODES_ERROR,
  payload: error,
});

export const postAllowToPrintRequest = (data: any) => ({
  type: POST_ALLOW_TO_PRINT_REQUEST,
  payload: data,
});
export const postAllowToPrintSuccess = (data: any) => ({
  type: POST_ALLOW_TO_PRINT_SUCCESS,
  payload: data,
});
export const postAllowToPrintError = (error: any) => ({
  type: POST_ALLOW_TO_PRINT_ERROR,
  payload: error,
});

export const postDetailedInfoRequest = (data: any) => ({
  type: POST_DETAILED_INFO_REQUEST,
  payload: data,
});
export const postDetailedInfoSuccess = (data: any) => ({
  type: POST_DETAILED_INFO_SUCCESS,
  payload: data,
});
export const postDetailedInfoError = (error: any) => ({
  type: POST_DETAILED_INFO_ERROR,
  payload: error,
});

export const closeErrorPopUpBank = () => ({
  type: CLOSE_ERROR_POPUP_BANK,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpBank>
  | ReturnType<typeof postDetailedInfoRequest>
  | ReturnType<typeof postDetailedInfoSuccess>
  | ReturnType<typeof postDetailedInfoError>
  | ReturnType<typeof postAllowToPrintRequest>
  | ReturnType<typeof postAllowToPrintSuccess>
  | ReturnType<typeof postAllowToPrintError>
  | ReturnType<typeof getAllCodesRequest>
  | ReturnType<typeof getAllCodesSuccess>
  | ReturnType<typeof getAllCodesError>;
