import AddIcon from '@mui/icons-material/Add';
import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExportListTable } from '../../common/ExportListTable/ExportListTable';
import InputSearch from '../../common/InputSearch';
import { Spinner } from '../../common/Spinner/Spinner';
import styles from './ExportLists.module.scss';
import { getShippingOrderByDateRequest, postCreateShippingOrderRequest } from './services/actions';
import { shippingOrdersSelector } from './services/selector';
import ErrorResponse from '../../shared/ErrorResponse';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '../../../icons/arrow_back.svg';
import ArrowForwardIcon from '../../../icons/arrow_forward.svg';

const calendarStyle = {
  '.MuiInputBase-formControl': {
    backgroundColor: '#ffffff !important',
    height: 30,
    fontSize: '13px !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
    fontSize: '13px !important',
  },
  '.MuiSvgIcon-root': {
    fontSize: '18px !important',
  },
};

export const ExportLists: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let timeoutId: NodeJS.Timeout | null = null;

  const [calendarDate, setCalendarDate] = useState<any>(new Date());

  const {
    shippingOrders,
    loading,
    getShippingByIdErrorResponses,
    checkSeriesErrorResponses,
    checkProductErrorResponses,
    deleteFilterErrorResponse,
    deleteProductDocErrorResponse,
    createShippingErrorResponses,
    updateShippingErrorResponses,
    getShippingErrorResponses,
  } = useSelector(shippingOrdersSelector);

  const [tableData, setTableData] = useState<any[]>(shippingOrders);

  const handleMonthChange = (increment: number) => {
    const newDate = new Date(calendarDate);
    newDate.setMonth(newDate.getMonth() + increment);
    setCalendarDate(newDate);
  };

  const onAddShippingOrder = () => {
    dispatch(postCreateShippingOrderRequest(navigate));
  };

  const filterExportList = () => {
    return dispatch(
      getShippingOrderByDateRequest({
        fromDate: moment(calendarDate).format('YYYY-MM-01'),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
      }),
    );
  };

  useEffect(() => {
    timeoutId = setTimeout(() => {
      filterExportList();
      timeoutId = null;
    }, 1000);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [calendarDate]);

  useEffect(() => {
    setTableData(shippingOrders);
  }, [shippingOrders]);

  useEffect(() => {
    dispatch(
      getShippingOrderByDateRequest({
        fromDate: moment(calendarDate).format('YYYY-MM-01'),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
      }),
    );
  }, []);

  useEffect(() => {
    if (Object.keys(getShippingErrorResponses).length !== 0 && getShippingErrorResponses?.status === 403) {
      navigate('/error');
    }
  }, [getShippingErrorResponses]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(getShippingErrorResponses).length !== 0 ? <ErrorResponse error={getShippingErrorResponses} /> : null}
      {Object.keys(createShippingErrorResponses).length !== 0 ? (
        <ErrorResponse error={createShippingErrorResponses} />
      ) : null}
      {Object.keys(updateShippingErrorResponses).length !== 0 ? (
        <ErrorResponse error={updateShippingErrorResponses} />
      ) : null}
      {Object.keys(getShippingByIdErrorResponses).length !== 0 ? (
        <ErrorResponse error={getShippingByIdErrorResponses} />
      ) : null}
      {Object.keys(checkSeriesErrorResponses).length !== 0 ? <ErrorResponse error={checkSeriesErrorResponses} /> : null}
      {Object.keys(checkProductErrorResponses).length !== 0 ? (
        <ErrorResponse error={checkProductErrorResponses} />
      ) : null}
      {Object.keys(deleteFilterErrorResponse).length !== 0 ? <ErrorResponse error={deleteFilterErrorResponse} /> : null}
      {Object.keys(deleteProductDocErrorResponse).length !== 0 ? (
        <ErrorResponse error={deleteProductDocErrorResponse} />
      ) : null}

      <div className={styles.documentGroup}>
        <span className={styles.subtitle}>Комплетація замовлень</span>
        {loading ? <Spinner /> : null}
      </div>
      <span>Створюйте списки продуктів і додайте серії, які можна сканувати і додавати в даний документ</span>
      <div className={styles.sectionsWrapper}>
        <section className={styles.tools}>
          <div className={styles.calendarBlock}>
            <div className={styles.calendarItem}>
              <span>Дані за пeріод:</span>
              <div className={styles.calendarRow}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="MM/YYYY"
                    value={calendarDate}
                    views={['year', 'month']}
                    onChange={() => undefined}
                    readOnly
                    renderInput={(params) => <TextField sx={calendarStyle} {...params} />}
                  />
                  <img src={ArrowBackIcon} onClick={() => handleMonthChange(-1)} />
                  <img src={ArrowForwardIcon} onClick={() => handleMonthChange(1)} />
                </LocalizationProvider>
              </div>
            </div>
            <Button variant="contained" className={styles.btn} onClick={filterExportList}>
              <span className={styles.btnText}>Пошук</span>
            </Button>
          </div>
        </section>
        <section className={styles.searchSection}>
          <div className={styles.searchGroup}>
            <span>Фільтр по всім полям:</span>
            <InputSearch setTableData={setTableData} data={shippingOrders} />
          </div>
          <Button variant="contained" className={styles.btn} onClick={onAddShippingOrder}>
            <span className={styles.addBtnText}>Додати</span>
            <AddIcon className={styles.icon} />
          </Button>
        </section>
      </div>
      <ExportListTable data={tableData} loading={loading} />
    </div>
  );
};
