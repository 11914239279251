import {
  CLOSE_ERROR_POPUP_REPORT_ERROR,
  GET_DETAILED_EXCEL_REPORT_INVOICES_ERROR,
  GET_DETAILED_EXCEL_REPORT_INVOICES_REQUEST,
  GET_DETAILED_EXCEL_REPORT_INVOICES_SUCCESS,
  GET_GLOBAL_EXCEL_REPORT_INVOICES_ERROR,
  GET_GLOBAL_EXCEL_REPORT_INVOICES_REQUEST,
  GET_GLOBAL_EXCEL_REPORT_INVOICES_SUCCESS,
  GET_INVOICES_ERROR,
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
} from './constants';

export const getInvoicesRequest = (dates: any) => ({
  type: GET_INVOICES_REQUEST,
  payload: dates,
});
export const getInvoicesSuccess = (data: any) => ({
  type: GET_INVOICES_SUCCESS,
  payload: data,
});
export const getInvoicesError = (error: any) => ({
  type: GET_INVOICES_ERROR,
  payload: error,
});

export const getGlobalExcelReportInvoicesRequest = (body: any) => ({
  type: GET_GLOBAL_EXCEL_REPORT_INVOICES_REQUEST,
  payload: body,
});
export const getGlobalExcelReportInvoicesSuccess = () => ({
  type: GET_GLOBAL_EXCEL_REPORT_INVOICES_SUCCESS,
});
export const getGlobalExcelReportInvoicesError = (error: any) => ({
  type: GET_GLOBAL_EXCEL_REPORT_INVOICES_ERROR,
  payload: error,
});

export const getDetailedExcelReportInvoicesRequest = (body: any) => ({
  type: GET_DETAILED_EXCEL_REPORT_INVOICES_REQUEST,
  payload: body,
});
export const getDetailedExcelReportInvoicesSuccess = (response: any) => ({
  type: GET_DETAILED_EXCEL_REPORT_INVOICES_SUCCESS,
  payload: response,
});
export const getDetailedExcelReportInvoicesError = (error: any) => ({
  type: GET_DETAILED_EXCEL_REPORT_INVOICES_ERROR,
  payload: error,
});

export const closeErrorPopUpInvoicesReport = () => ({
  type: CLOSE_ERROR_POPUP_REPORT_ERROR,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpInvoicesReport>
  | ReturnType<typeof getDetailedExcelReportInvoicesRequest>
  | ReturnType<typeof getDetailedExcelReportInvoicesSuccess>
  | ReturnType<typeof getDetailedExcelReportInvoicesError>
  | ReturnType<typeof getGlobalExcelReportInvoicesRequest>
  | ReturnType<typeof getGlobalExcelReportInvoicesSuccess>
  | ReturnType<typeof getGlobalExcelReportInvoicesError>
  | ReturnType<typeof getInvoicesRequest>
  | ReturnType<typeof getInvoicesSuccess>
  | ReturnType<typeof getInvoicesError>;
