import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEnglish from './translations/English/translation.json';
import translationUkraine from './translations/Ukrainian/translation.json';

const resources = {
  en: {
    translation: translationEnglish,
  },
  ua: {
    translation: translationUkraine,
  },
};

void i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'ua',
    debug: true,
  });

export default i18n;
