import { Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
// import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import MenuDropdown from '../../common/MenuDropdown';

import Icon from '../../../icons/IconSVG.svg';

import styles from './Header.module.scss';
import { msalInstance } from '../../../index';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount } from '../../Auth/actions';
import { postAuthenticationLogoutRequest } from '../../Main/services/actions';
import { mainPageSelector } from '../../Main/services/selector';
import ErrorResponse from '../ErrorResponse';

export const Header: React.FC = () => {
  // const { i18n } = useTranslation();
  const location = useLocation();
  const account = msalInstance.getAllAccounts()[0];

  const { logoutErrorResponses } = useSelector(mainPageSelector);

  const { instance } = useMsal();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!account) {
      dispatch(getAccount());
    }
  }, [account, dispatch, instance]);

  // const onClickLanguageChange = (lang: string) => {
  //   i18n.changeLanguage(lang);
  // };

  const [anchorElBank, setAnchorElBank] = useState(null);
  const [bankTabActive, setBankTabActive] = useState(false);

  const [anchorElHandbook, setAnchorElHandbook] = useState(null);
  const [handbookTabActive, setHandbookTabActive] = useState(false);

  const [anchorElEventLog, setAnchorElEventLog] = useState(null);
  const [eventLogTabActive, setEventLogTabActive] = useState(false);

  const [anchorElDocs, setAnchorElDocs] = useState(null);
  const [docsTabActive, setDocsTabActive] = useState(false);

  const handleClickBank = (event: any) => {
    setAnchorElBank(event.currentTarget);
  };
  const handleClickHandbook = (event: any) => {
    setAnchorElHandbook(event.currentTarget);
  };
  const handleClickEventLog = (event: any) => {
    setAnchorElEventLog(event.currentTarget);
  };
  const handleClickDocs = (event: any) => {
    setAnchorElDocs(event.currentTarget);
  };

  const handleCloseBank = () => {
    setAnchorElBank(null);
  };
  const handleCloseHandbook = () => {
    setAnchorElHandbook(null);
  };
  const handleCloseEventLog = () => {
    setAnchorElEventLog(null);
  };
  const handleCloseDocs = () => {
    setAnchorElDocs(null);
  };

  useEffect(() => {
    location.pathname === '/import' || location.pathname === '/available' || location.pathname === '/printCodes'
      ? setBankTabActive(true)
      : setBankTabActive(false);
    location.pathname === '/products' ||
    location.pathname === '/packaging' ||
    location.pathname === '/withdrawals' ||
    location.pathname === '/unit_templates' ||
    location.pathname === '/box_templates' ||
    location.pathname === '/pallet_templates'
      ? setHandbookTabActive(true)
      : setHandbookTabActive(false);
    location.pathname === '/licensedDevices' || location.pathname === '/processes' || location.pathname === '/settings'
      ? setEventLogTabActive(true)
      : setEventLogTabActive(false);
    location.pathname === '/importCodesReport' ||
    location.pathname === '/codes_report' ||
    location.pathname === '/shipment' ||
    location.pathname === '/export_lists' ||
    location.pathname === '/inventory' ||
    location.pathname === '/invoices_report' ||
    location.pathname === '/searchCodes'
      ? setDocsTabActive(true)
      : setDocsTabActive(false);
  }, [location]);

  useEffect(() => {
    if (location.pathname === '/main') {
      setBankTabActive(false);
      setHandbookTabActive(false);
      setEventLogTabActive(false);
      setDocsTabActive(false);
    }
  }, [location]);

  return (
    <div className={styles.navWrapper}>
      {Object.keys(logoutErrorResponses).length !== 0 ? <ErrorResponse error={logoutErrorResponses} /> : null}

      <img className={styles.icon} src={Icon} alt="Gateway" />
      <nav className={styles.navigation}>
        <NavLink to="/main" className={({ isActive }) => (isActive ? `${styles.isActive}` : `${styles.notActive}`)}>
          <span className={styles.label}>Головна</span>
        </NavLink>

        <MenuDropdown
          value={anchorElBank}
          handleClick={handleClickBank}
          handleClose={handleCloseBank}
          title="Коди маркування"
          items={[
            { key: 'Імпорт нових кодів', link: '/import' },
            { key: 'Банк кодів', link: '/available' },
            { key: 'Рецепти', link: '/receipts' },
            { key: 'Завдання на друк', link: '/printCodes' },
          ]}
          isActiveTab={bankTabActive}
        />
        <MenuDropdown
          value={anchorElHandbook}
          handleClick={handleClickHandbook}
          handleClose={handleCloseHandbook}
          title="Довідники"
          items={[
            { key: 'Продукція', link: '/products' },
            { key: 'Пакування', link: '/packaging' },
            { key: 'Причини вилучень', link: '/withdrawals' },
            { key: 'Шаблони продукції (Level 0)', link: '/unit_templates' },
            { key: 'Шаблони ящиків (Level 2)', link: '/box_templates' },
            { key: 'Шаблони палет (Level 3)', link: '/pallet_templates' },
          ]}
          isActiveTab={handbookTabActive}
        />
        <MenuDropdown
          value={anchorElDocs}
          handleClick={handleClickDocs}
          handleClose={handleCloseDocs}
          title="Звіти"
          items={[
            { key: 'Пошук по кодам маркувань', link: '/searchCodes' },
            { key: 'Звіт з імпорту кодів', link: '/importCodesReport' },
            { key: 'Звіт з виробництва', link: '/codes_report' },
            { key: 'Звіт з відвантаження', link: '/shipment' },
            { key: 'Звіт по серіям', link: '/series_report' },
            { key: 'Звіт з вилучень', link: '/withdrawal' },
            { key: 'Звіт Audit Trail', link: '/audittrail_report' },
            { key: 'Звіт по накладних', link: '/invoices_report' },
          ]}
          isActiveTab={docsTabActive}
        />
        <MenuDropdown
          value={anchorElEventLog}
          handleClick={handleClickEventLog}
          handleClose={handleCloseEventLog}
          title="Службові"
          items={[
            { key: 'Ліцензовані підключення', link: '/licensedDevices' },
            { key: 'Фонові процеси', link: '/processes' },
            { key: 'Системні параметри', link: '/settings' },
          ]}
          isActiveTab={eventLogTabActive}
        />
      </nav>

      <div className={styles.languages}>
        {/* <Button className={styles.language} onClick={() => onClickLanguageChange('ua')}> */}
        {/*  UA */}
        {/* </Button> */}
        {/* <Button className={styles.language} onClick={() => onClickLanguageChange('en')}> */}
        {/*  EN */}
        {/* </Button> */}
      </div>

      <div className={styles.login}>
        <span className={styles.email}>{account.username}</span>
        <Tooltip title="Вийти з системи" placement="top">
          <Button
            className={styles.logoutIcon}
            onClick={() => {
              dispatch(
                postAuthenticationLogoutRequest(() => {
                  instance.logoutRedirect().catch((e: any) => {
                    console.error(e);
                  });
                }),
              );
            }}
          >
            <LogoutIcon />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};
