import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { ShippingOrders } from '../../../../entities/ShippingOrders/service';
import { GET_AUDITTRAIL_REPORT_REQUEST, GET_GLOBAL_EXCEL_REPORT_REQUEST, GET_PDF_FILE_REQUEST } from './constants';
import {
  getAuditTrailReportError,
  getAuditTrailReportRequest,
  getAuditTrailReportSuccess,
  getGlobalExcelReportError,
  getGlobalExcelReportRequest,
  getGlobalExcelReportSuccess,
  getPDFFileError,
  getPDFFileRequest,
  getPDFFileSuccess,
} from './actions';
import { Reports } from '../../../../entities/Reports/service';

function* getAuditTrailReportSaga(action: ReturnType<typeof getAuditTrailReportRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.getAuditTrailReport, action.payload);
    const audittrailReport = response.data.ResponseBody;
    yield put(getAuditTrailReportSuccess(audittrailReport));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getAuditTrailReportError(error));
  }
}

function* getGlobalExcelReportRequestSaga(action: ReturnType<typeof getGlobalExcelReportRequest>) {
  try {
    yield call(ShippingOrders.getGlobalExcelReport, action.payload);

    yield put(getGlobalExcelReportSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getGlobalExcelReportError(error));
  }
}

function* getPDFFileRequestSaga(action: ReturnType<typeof getPDFFileRequest>) {
  try {
    yield call(ShippingOrders.getPDFFile, action.payload);

    yield put(getPDFFileSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getPDFFileError(error));
  }
}

export function* AuditTrailReportSagaWatcher() {
  yield takeLatest(GET_PDF_FILE_REQUEST, getPDFFileRequestSaga);
  yield takeLatest(GET_AUDITTRAIL_REPORT_REQUEST, getAuditTrailReportSaga);
  yield takeLatest(GET_GLOBAL_EXCEL_REPORT_REQUEST, getGlobalExcelReportRequestSaga);
}
