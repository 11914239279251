import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { getPDFFileRequest } from '../../EventsLog/AuditTrail/services/actions';
import styles from '../DataTable/DataTable.module.scss';
import { style } from '../DataTable/style';

import CarIcon from '../../../icons/car.svg';
import RedCarIcon from '../../../icons/redcar.svg';

interface Props {
  data: any;
}

export const AuditTrailReportTable: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = React.useState(50);

  const renderActionSeverity = (element: any) => {
    switch (element.actionSeverity) {
      case null:
        return <div>{''}</div>;
      case 1:
        return (
          <div className={styles.customCell}>
            <img src={CarIcon} alt="" />
            <span>INFO</span>
          </div>
        );
      case 2:
        return (
          <div className={styles.customCell}>
            <img src={RedCarIcon} alt="" />
            <span>WARN</span>
          </div>
        );
      case 3:
        return (
          <div className={styles.customCell}>
            <img src={RedCarIcon} alt="" />
            <span>ERROR</span>
          </div>
        );
      default:
        return <div>{''}</div>;
    }
  };

  const seriesGrid = [
    { field: 'auditEntryID', headerName: 'Ідентифікатор', width: 120 },
    { field: 'module', headerName: 'Модуль', width: 200 },
    { field: 'section', headerName: 'Розділ', width: 200 },
    { field: 'subject', headerName: 'Предмет операції', width: 200 },
    { field: 'actionType', headerName: 'Операція', width: 200 },
    { field: 'eventDate', headerName: 'Дата і час', width: 160 },
    {
      field: 'actionSeverity',
      headerName: 'Мітка',
      width: 80,
      renderCell: (params: GridRenderCellParams<Date>) => renderActionSeverity(params.row),
    },
    { field: 'userLogin', headerName: 'Користувач', width: 240 },
    { field: 'comment', headerName: 'Повідомлення', minWidth: 200, flex: 0.9 },
    {
      field: 'button',
      headerName: 'Файли',
      width: 70,
      align: 'right',
      renderCell: (params: GridRenderCellParams<Date>) => (
        <Tooltip title="Скачати звіт про подію в PDF" placement="top">
          <a
            onClick={() =>
              dispatch(
                getPDFFileRequest({
                  auditEntryID: params.row.auditEntryID,
                }),
              )
            }
          >
            <DownloadIcon className={styles.icon} />
          </a>
        </Tooltip>
      ),
    },
  ];

  return (
    <div style={{ height: 599, width: '100%', marginTop: '20px' }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        headerHeight={34}
        rowHeight={34}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        // @ts-expect-error
        columns={seriesGrid}
        getRowId={() => Math.random()}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        sx={style}
        componentsProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: 14,
                    bgcolor: '#ffffff',
                  },
                },
                PaperProps: {
                  sx: {
                    bgcolor: '#ffffff',
                  },
                },
              },
            },
          },
        }}
      />
    </div>
  );
};
