import { Box, Button, Modal, OutlinedInput } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { postCreateProductRequest, setErrorMessage } from '../services/actions';
import { productSelector } from '../services/selector';
import CloseIcon from '@mui/icons-material/Close';

import styles from './AddProductModal.module.scss';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
  fontSize: '13px !important',
};

const searchStyle = {
  width: 200,
  height: 30,
  fontSize: '13px !important',
  marginRight: 20,
  margin: 0,
  bgcolor: '#ffffff',
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
};

interface Props {
  isShow: boolean;
  closeModal: () => void;
}

export const AddProductModal: React.FC<Props> = ({ isShow, closeModal }) => {
  const dispatch = useDispatch();

  const [ean, setEan] = useState<any>();

  const navigate = useNavigate();

  const location = useLocation();

  const { failedResponse } = useSelector(productSelector);

  useEffect(() => {
    if (location.pathname !== '/products') {
      setEan('');
      dispatch(setErrorMessage([]));
      closeModal();
    }
  }, [location]);

  return (
    <Modal
      open={isShow}
      onClose={() => {
        closeModal();
        setEan('');
        dispatch(setErrorMessage([]));
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.headerButtonsWrapper}>
          <CloseIcon
            onClick={() => {
              closeModal();
              setEan('');
              dispatch(setErrorMessage([]));
            }}
          />
        </div>
        <div className={styles.searchGroup}>
          <span>Введіть код GTIN (13 знаків, без першого 0):</span>
          <OutlinedInput
            defaultValue=""
            autoComplete="off"
            sx={searchStyle}
            onChange={(event) => {
              setEan(event.target.value);
            }}
            value={ean}
            inputProps={{ maxLength: 13 }}
          />
        </div>
        {failedResponse.length !== 0 ? (
          <span className={styles.error}>{failedResponse[0]}</span>
        ) : (
          <span className={styles.error}> </span>
        )}
        <div className={styles.btnGroup}>
          <Button
            className={styles.submitBtn}
            variant="contained"
            onClick={() => dispatch(postCreateProductRequest({ gtin: ean }, navigate))}
          >
            <span className={styles.btnText}>Створити</span>
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
