import {
  GET_LICENSED_DEVICES_REQUEST,
  GET_LICENSED_DEVICES_SUCCESS,
  GET_LICENSED_DEVICES_ERROR,
  CLOSE_ERROR_POPUP_LICENSED_DEVICES,
} from './constants';

export const getLicensedDevicesRequest = () => ({
  type: GET_LICENSED_DEVICES_REQUEST,
});
export const getLicensedDevicesSuccess = (data: any) => ({
  type: GET_LICENSED_DEVICES_SUCCESS,
  payload: data,
});
export const getLicensedDevicesError = (error: any) => ({
  type: GET_LICENSED_DEVICES_ERROR,
  payload: error,
});

export const closeErrorPopUpLicensedDevices = () => ({
  type: CLOSE_ERROR_POPUP_LICENSED_DEVICES,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpLicensedDevices>
  | ReturnType<typeof getLicensedDevicesRequest>
  | ReturnType<typeof getLicensedDevicesSuccess>
  | ReturnType<typeof getLicensedDevicesError>;
