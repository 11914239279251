import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import * as React from 'react';
import moment from 'moment';
import AlertIcon from '../../../icons/alert.svg';
import CarIcon from '../../../icons/done_all.svg';
import styles from '../DataTable/DataTable.module.scss';
import { style } from '../DataTable/style';

interface Props {
  data: any;
}

export const ImportReportTable: React.FC<Props> = ({ data }) => {
  const [pageSize, setPageSize] = React.useState(25);

  const renderCustomCell = (element: any) => {
    switch (element.isSuccess) {
      case null:
        return <div>{''}</div>;
      case true:
        return (
          <div className={styles.customCell}>
            <img src={CarIcon} alt="" />
            <span>успішно</span>
          </div>
        );
      case false:
        return (
          <div className={styles.customCell}>
            <img src={AlertIcon} alt="" />
            <span>не виконано</span>
          </div>
        );
      default:
        return <div>{''}</div>;
    }
  };

  return (
    <div style={{ height: 580, width: '100%', marginTop: '20px' }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        headerHeight={40}
        rowHeight={36}
        columns={[
          { field: 'orderNumber', headerName: 'Номер замовлення', width: 180 },
          { field: 'countryCode', headerName: 'Країна', width: 80 },
          { field: 'EAN13', headerName: 'Код GTIN', width: 140 },
          {
            field: 'internalProductName',
            headerName: 'Внутрішня назва продукції',
            minWidth: 330,
            flex: 0.1,
          },
          { field: 'importedCodesCount', headerName: 'Кількість кодів, шт', width: 160 },
          { field: 'initialExternalStatusDescription', headerName: 'Початковий статус', width: 260 },
          {
            field: 'isSuccessIcon',
            headerName: 'Результат операції',
            width: 160,
            renderCell: (params: GridRenderCellParams<Date>) => renderCustomCell(params.row),
          },
          {
            field: 'importDate',
            headerName: 'Дата і час імпорту',
            width: 165,
            renderCell: (params: GridRenderCellParams<Date>) => (
              <span>{moment(params.row.importDate).format('DD-MM-YYYY HH:mm:ss')}</span>
            ),
          },
          { field: 'performedByLogin', headerName: 'Користувач', minWidth: 100, flex: 0.9 },
        ]}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        sx={style}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        componentsProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: 14,
                    bgcolor: '#ffffff',
                  },
                },
                PaperProps: {
                  sx: {
                    bgcolor: '#ffffff',
                  },
                },
              },
            },
          },
        }}
      />
    </div>
  );
};
