export const POST_ADD_NEW_WITHDRAWAL_REQUEST = 'POST_ADD_NEW_WITHDRAWAL_REQUEST' as const;
export const POST_ADD_NEW_WITHDRAWAL_SUCCESS = 'POST_ADD_NEW_WITHDRAWAL_SUCCESS' as const;
export const POST_ADD_NEW_WITHDRAWAL_ERROR = 'POST_ADD_NEW_WITHDRAWAL_ERROR' as const;

export const PUT_UPDATE_WITHDRAWAL_REQUEST = 'PUT_UPDATE_WITHDRAWAL_REQUEST' as const;
export const PUT_UPDATE_WITHDRAWAL_SUCCESS = 'PUT_UPDATE_WITHDRAWAL_SUCCESS' as const;
export const PUT_UPDATE_WITHDRAWAL_ERROR = 'PUT_UPDATE_WITHDRAWAL_ERROR' as const;

export const GET_ALL_WITHDRAWAL_REQUEST = 'GET_ALL_WITHDRAWAL_REQUEST' as const;
export const GET_ALL_WITHDRAWAL_SUCCESS = 'GET_ALL_WITHDRAWAL_SUCCESS' as const;
export const GET_ALL_WITHDRAWAL_ERROR = 'GET_ALL_WITHDRAWAL_ERROR' as const;

export const DELETE_WITHDRAWAL_REQUEST = 'DELETE_WITHDRAWAL_REQUEST' as const;
export const DELETE_WITHDRAWAL_SUCCESS = 'DELETE_WITHDRAWAL_SUCCESS' as const;
export const DELETE_WITHDRAWAL_ERROR = 'DELETE_WITHDRAWAL_ERROR' as const;

export const CLOSE_ERROR_MODAL = 'CLOSE_ERROR_MODAL' as const;
export const CLOSE_ERROR_POPUP_WITHDRAWAL = 'CLOSE_ERROR_POPUP_WITHDRAWAL' as const;
