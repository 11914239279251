import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, TextField } from '@mui/material';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
// import { ModalSpinner } from '../../common/ModalSpinner/ModalSpinner';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InputSearch from '../../common/InputSearch';
import { InventoryListTable } from '../../common/InventoryListTable/InventoryListTable';

import styles from '../InventoryList/InventoryList.module.scss';

const calendarStyle = {
  '.MuiInputBase-formControl': {
    backgroundColor: '#ffffff !important',
    height: 30,
    fontSize: '13px !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
    fontSize: '13px !important',
  },
  '.MuiSvgIcon-root': {
    fontSize: '18px !important',
  },
};

export const InventoryList = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>(moment());
  const [endDate, setEndDate] = useState<Moment | null>(moment());

  const handleChangeStartDate = (newDate: Moment | null) => {
    setStartDate(newDate);
  };

  const handleChangeEndDate = (newDate: Moment | null) => {
    setEndDate(newDate);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.documentGroup}>
        <span className={styles.subtitle}>Інвентаризації</span>
      </div>

      <span>Звіти з проведених інвентаризацій</span>
      <div className={styles.sectionsWrapper}>
        <section className={styles.tools}>
          <div className={styles.calendarBlock}>
            <div className={styles.calendarItem}>
              <span>Дата з, місяць:</span>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  inputFormat="MM/YYYY"
                  value={startDate}
                  views={['year', 'month']}
                  onChange={handleChangeStartDate}
                  renderInput={(params) => <TextField sx={calendarStyle} {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className={styles.calendarItem}>
              <span>Дата по, місяць:</span>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  inputFormat="MM/YYYY"
                  value={endDate}
                  views={['year', 'month']}
                  minDate={startDate}
                  maxDate={moment(startDate).add(3, 'M')}
                  onChange={handleChangeEndDate}
                  renderInput={(params) => <TextField sx={calendarStyle} {...params} />}
                />
              </LocalizationProvider>
            </div>
            <Button
              variant="contained"
              disabled={
                !moment(startDate, 'DD-MM-YYYY').isSame(moment(endDate, 'DD-MM-YYYY')) &&
                !moment(startDate, 'DD-MM-YYYY').isBefore(moment(endDate, 'DD-MM-YYYY'))
              }
              className={styles.btn}
              onClick={() => undefined}
            >
              <span className={styles.btnText}>Пошук</span>
            </Button>
          </div>
        </section>
        <section className={styles.searchSection}>
          <div className={styles.searchGroup}>
            <span>Фільтр по всім полям:</span>
            <InputSearch setTableData={setTableData} data={[]} />
          </div>
        </section>
      </div>
      <InventoryListTable data={tableData} />
    </div>
  );
};
