import { Box, Button, Modal } from '@mui/material';
import React, { useEffect } from 'react';

import styles from './PrintCodesInfoModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { postPrintJobDetailedRequest, postUnreserveMarkingCodesRequest } from '../services/actions';
import { printCodesSelector } from '../services/selector';
import { ModalSpinner } from '../../../common/ModalSpinner/ModalSpinner';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 280,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
  fontSize: '13px !important',
};

interface Props {
  isShown: boolean;
  params: any;
  closeModal: () => void;
}

export const PrintCodeInnerModal: React.FC<Props> = ({ isShown, params, closeModal }) => {
  const dispatch = useDispatch();
  const { postUnreserveMarkingCodes, innerModalLoading } = useSelector(printCodesSelector);

  useEffect(() => {
    if (postUnreserveMarkingCodes?.length > 0) {
      closeModal();
      dispatch(postPrintJobDetailedRequest(params));
    }
  }, [postUnreserveMarkingCodes]);

  return (
    <Modal
      open={isShown}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.innerModalSpinnerWrapper}>{innerModalLoading ? <ModalSpinner /> : null}</div>
        <span className={styles.title}>Виконуте із застереженням нижче!</span>
        <div className={styles.text}>
          <span>
            Оперерація скасує статус 'Надруковано' серійним номерам даного завдання, які ще не було агреговано.
          </span>
          <span>Перед виконанням слід фізично утилізувати всі неагреговані маркування за даним завданням.</span>
          <span>Виконати?</span>
        </div>

        <div className={styles.btnGroupInner}>
          <Button
            className={styles.submitBtn}
            disabled={innerModalLoading}
            variant="contained"
            onClick={() => {
              dispatch(postUnreserveMarkingCodesRequest(params));
              closeModal();
            }}
          >
            <span className={styles.btnText}>Так</span>
          </Button>
          <Button className={styles.exitBtn} variant="contained" onClick={closeModal}>
            <span className={styles.btnText}>Ні</span>
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
