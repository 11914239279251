import { Actions } from './actions';
import {
  CLOSE_ERROR_MODAL,
  CLOSE_ERROR_POPUP_WITHDRAWAL,
  GET_ALL_WITHDRAWAL_ERROR,
  GET_ALL_WITHDRAWAL_REQUEST,
  GET_ALL_WITHDRAWAL_SUCCESS,
  POST_ADD_NEW_WITHDRAWAL_ERROR,
  POST_ADD_NEW_WITHDRAWAL_SUCCESS,
  PUT_UPDATE_WITHDRAWAL_ERROR,
  PUT_UPDATE_WITHDRAWAL_SUCCESS,
  POST_ADD_NEW_WITHDRAWAL_REQUEST,
  DELETE_WITHDRAWAL_SUCCESS,
  DELETE_WITHDRAWAL_ERROR,
  DELETE_WITHDRAWAL_REQUEST,
  PUT_UPDATE_WITHDRAWAL_REQUEST,
} from './constants';
import { IWithdrawalReason } from './interfaces';

const initialState = {
  loading: false as boolean,
  modalLoading: false as boolean,
  error: null as Error | null,
  withdrawals: [] as IWithdrawalReason[] | null,

  newWithdrawal: null as any | IWithdrawalReason,
  updateWithdrawalResponse: null as any,
  deleteWithdrawalResponse: false as boolean,

  getWithdrawalsErrorResponses: {} as any,
  addWithdrawalErrorResponses: {} as any,
  updateWithdrawalErrorResponses: {} as any,
  deleteWithdrawalErrorResponses: {} as any,
};

export type WithdrawalState = typeof initialState;

export const WithdrawalReducer = (state: WithdrawalState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_ALL_WITHDRAWAL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ALL_WITHDRAWAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        withdrawals: action.payload,
      };
    }
    case GET_ALL_WITHDRAWAL_ERROR: {
      return {
        ...state,
        loading: false,
        getWithdrawalsErrorResponses: action.payload,
      };
    }

    case CLOSE_ERROR_MODAL: {
      return {
        ...state,
        getWithdrawalsErrorResponses: {},
      };
    }

    case POST_ADD_NEW_WITHDRAWAL_REQUEST: {
      return {
        ...state,
        modalLoading: true,
        newWithdrawal: null,
      };
    }
    case POST_ADD_NEW_WITHDRAWAL_SUCCESS: {
      return {
        ...state,
        modalLoading: false,
        newWithdrawal: action.payload,
      };
    }
    case POST_ADD_NEW_WITHDRAWAL_ERROR: {
      return {
        ...state,
        modalLoading: false,
        addWithdrawalErrorResponses: action.payload,
      };
    }

    case PUT_UPDATE_WITHDRAWAL_REQUEST: {
      return {
        ...state,
        modalLoading: true,
      };
    }
    case PUT_UPDATE_WITHDRAWAL_SUCCESS: {
      return {
        ...state,
        modalLoading: false,
        updateWithdrawalResponse: action.payload,
      };
    }
    case PUT_UPDATE_WITHDRAWAL_ERROR: {
      return {
        ...state,
        modalLoading: false,
        updateWithdrawalErrorResponses: action.payload,
      };
    }

    case DELETE_WITHDRAWAL_REQUEST: {
      return {
        ...state,
        modalLoading: true,
      };
    }
    case DELETE_WITHDRAWAL_SUCCESS: {
      return {
        ...state,
        modalLoading: false,
        deleteWithdrawalResponse: action.payload,
      };
    }
    case DELETE_WITHDRAWAL_ERROR: {
      return {
        ...state,
        modalLoading: false,
        deleteWithdrawalErrorResponses: action.payload,
      };
    }

    case CLOSE_ERROR_POPUP_WITHDRAWAL: {
      return {
        ...state,
        getWithdrawalsErrorResponses: {} as any,
        addWithdrawalErrorResponses: {} as any,
        updateWithdrawalErrorResponses: {} as any,
        deleteWithdrawalErrorResponses: {} as any,
      };
    }

    default: {
      return state;
    }
  }
};
