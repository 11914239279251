import React, { useEffect, useState } from 'react';
import { Group } from '@visx/group';
import { hierarchy, Tree } from '@visx/hierarchy';
import { LinearGradient } from '@visx/gradient';
import { pointRadial } from 'd3-shape';
import useForceUpdate from './useForceUpdate';
import LinkControls from './LinkControls';
import getLinkComponent from './getLinkComponent';
import { findIndex } from 'lodash';

import styles from '../Tree.module.scss';

const defaultMargin = { top: 20, left: 200, right: 200, bottom: 20 };

export interface LinkTypesProps {
  width: number;
  height: number;
  data: any;
  margin?: { top: number; right: number; bottom: number; left: number };
}

export default function TreeSVG({
  width: totalWidth,
  height: totalHeight,
  margin = defaultMargin,
  data: graphData,
}: LinkTypesProps) {
  const [layout, setLayout] = useState<string>('cartesian');
  const [orientation, setOrientation] = useState<string>('horizontal');
  const [linkType, setLinkType] = useState<string>('diagonal');
  const [stepPercent, setStepPercent] = useState<number>(0.5);
  const [treeHeight, setTreeHeight] = useState<number>(0);

  const forceUpdate = useForceUpdate();

  const innerWidth = totalWidth - margin.left - margin.right;
  const innerHeight = totalHeight - margin.top - margin.bottom;

  let origin: { x: number; y: number };
  let sizeWidth: number;
  let sizeHeight: number;

  if (layout === 'polar') {
    origin = {
      x: innerWidth / 2,
      y: innerHeight / 2,
    };
    sizeWidth = 2 * Math.PI;
    sizeHeight = Math.min(innerWidth, innerHeight) / 2;
  } else {
    origin = { x: 0, y: 0 };
    if (orientation === 'vertical') {
      sizeWidth = innerWidth;
      sizeHeight = innerHeight;
    } else {
      sizeWidth = innerHeight;
      sizeHeight = innerWidth;
    }
  }

  const LinkComponent = getLinkComponent({ layout, linkType, orientation });

  const heightCalc = (graphData: any) => {
    if (graphData) {
      const amountOfChildren = graphData?.children?.length;

      if (!graphData?.children && !graphData?.isExpended) {
        setTreeHeight(500);

        return;
      }

      let totalChildrenCount = 0;

      graphData?.children?.forEach((parent: any) => {
        if (parent?.children && !parent?.children?.isExpanded) {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          totalChildrenCount += parent?.children?.length;
        }
      });

      const totalH = 2 * ((30 * totalChildrenCount) / 2) + 30 * amountOfChildren;

      setTreeHeight(totalH);
    }
  };

  const heightCalcAfterUpdate = (treeData: any) => {
    if (treeData) {
      const amountOfChildren = treeData?.data?.children?.length;

      let totalChildrenCount = 0;

      treeData?.data?.children?.forEach((parent: any) => {
        if (parent?.children && !parent?.isExpanded) {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          totalChildrenCount += parent?.children?.length;
        }
      });

      const totalH = 2 * ((30 * totalChildrenCount) / 2) + 30 * amountOfChildren;

      setTreeHeight(totalH);
    }
  };

  useEffect(() => {
    heightCalc(graphData);
  }, [graphData]);

  const toggleExpansion = (node: any, tree: any) => {
    if (node.data.isExpanded === false) {
      node.data.isExpanded = true;
    } else {
      const desc = node.descendants()[0]?.parent?.children;
      const index = findIndex(node.descendants()[0]?.parent?.children, node);
      desc?.splice(index, 1);
      desc?.map((nod: any) => (nod.data.isExpanded = true));
      node.data.isExpanded = false;
    }
    forceUpdate();
    heightCalcAfterUpdate(tree);
  };

  return totalWidth < 10 ? null : (
    <section className={styles.tree} style={{ height: `${treeHeight}px` }}>
      <div>
        <div className={styles.controls}>
          <LinkControls
            layout={layout}
            orientation={orientation}
            linkType={linkType}
            stepPercent={stepPercent}
            setLayout={setLayout}
            setOrientation={setOrientation}
            setLinkType={setLinkType}
            setStepPercent={setStepPercent}
          />
        </div>
        <svg width={totalWidth} height={totalHeight}>
          <LinearGradient id="links-gradient" from="#fd9b93" to="#fe6e9e" />
          <rect width={totalWidth} height={totalHeight} rx={14} fill="#eceff1" />
          <Group top={margin.top} left={margin.left}>
            <Tree
              root={hierarchy(graphData, (d) => (d.isExpanded ? null : d.children))}
              size={[sizeWidth, sizeHeight]}
              separation={(a, b) => (a.parent === b.parent ? 1 : 0.5) / a.depth}
            >
              {(tree) => (
                <Group top={origin.y} left={origin.x}>
                  {tree.links().map((link, i) => (
                    <LinkComponent
                      key={i}
                      data={link}
                      percent={stepPercent}
                      stroke="rgb(254,110,158,0.6)"
                      strokeWidth="1"
                      fill="none"
                    />
                  ))}

                  {tree.descendants().map((node, key) => {
                    const width = 170;
                    const height = 20;

                    let top: number;
                    let left: number;
                    if (layout === 'polar') {
                      const [radialX, radialY] = pointRadial(node.x, node.y);
                      top = radialY;
                      left = radialX;
                    } else if (orientation === 'vertical') {
                      top = node.y;
                      left = node.x;
                    } else {
                      top = node.x;
                      left = node.y;
                    }

                    return (
                      <Group height={totalHeight} width={totalWidth} top={top} left={left} key={key}>
                        <rect
                          height={height}
                          width={width}
                          y={-height / 2}
                          x={-width / 2}
                          fill="#535353"
                          strokeWidth={0}
                          strokeOpacity={node.data.children ? 1 : 0.6}
                          rx={node.data.children ? 0 : 0}
                          onClick={() => {
                            toggleExpansion(node, tree);
                          }}
                        />
                        <text
                          dy=".33em"
                          fontSize={13}
                          fontFamily="Arial"
                          textAnchor="middle"
                          style={{
                            pointerEvents: 'none',
                          }}
                          fill={node.depth === 0 ? 'white' : node.children ? 'white' : 'white'}
                        >
                          {node.data.name}
                        </text>
                        <text
                          dy=".33em"
                          fontSize={13}
                          fontFamily="Arial"
                          textAnchor="middle"
                          y={node.depth === 0 ? 0 : -30}
                          x={node.depth === 2 ? 110 : node.depth === 1 ? 0 : 0}
                          style={{
                            pointerEvents: 'none',
                          }}
                          fill={node.depth === 0 ? 'red' : node.children ? 'red' : 'red'}
                        >
                          {node.data?.legend}
                        </text>
                      </Group>
                    );
                  })}
                </Group>
              )}
            </Tree>
          </Group>
        </svg>
      </div>
    </section>
  );
}
