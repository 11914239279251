import { AccountInfo } from '@azure/msal-browser';
import { Actions } from './actions';

import { SET_ACCOUNT } from './constants';

const initialState = {
  account: null as null | AccountInfo,
  loading: false as boolean,
  errorResponse: {} as any,
  role: {} as any,
};

type RoleState = typeof initialState;

export const authReducer = (state: RoleState = initialState, action: Actions) => {
  switch (action.type) {
    case SET_ACCOUNT: {
      return {
        ...state,
        account: action.payload.account,
      };
    }
    default: {
      return state;
    }
  }
};
