import {
  GET_ALL_PRINT_CODES_ERROR,
  GET_ALL_PRINT_CODES_SUCCESS,
  GET_ALL_PRINT_CODES_REQUEST,
  POST_CANCEL_PRINT_JOB_REQUEST,
  POST_CANCEL_PRINT_JOB_SUCCESS,
  POST_CANCEL_PRINT_JOB_ERROR,
  POST_FILTER_PRINT_JOB_REQUEST,
  POST_FILTER_PRINT_JOB_SUCCESS,
  POST_FILTER_PRINT_JOB_ERROR,
  CLOSE_ERROR_POPUP_PRINT_JOB,
  POST_PRINT_JOB_DETAILED_REQUEST,
  POST_PRINT_JOB_DETAILED_SUCCESS,
  POST_PRINT_JOB_DETAILED_ERROR,
  POST_UNRESERVE_MARKING_CODES_REQUEST,
  POST_UNRESERVE_MARKING_CODES_SUCCESS,
  POST_UNRESERVE_MARKING_CODES_ERROR,
} from './constants';

export const getAllPrintCodesRequest = () => ({
  type: GET_ALL_PRINT_CODES_REQUEST,
});
export const getAllPrintCodesSuccess = (response: any) => ({
  type: GET_ALL_PRINT_CODES_SUCCESS,
  payload: response,
});
export const getAllPrintCodesError = (error: any) => ({
  type: GET_ALL_PRINT_CODES_ERROR,
  payload: error,
});

export const postCancelPrintJobRequest = (body: any) => ({
  type: POST_CANCEL_PRINT_JOB_REQUEST,
  payload: body,
});
export const postCancelPrintJobSuccess = () => ({
  type: POST_CANCEL_PRINT_JOB_SUCCESS,
});
export const postCancelPrintJobError = (error: any) => ({
  type: POST_CANCEL_PRINT_JOB_ERROR,
  payload: error,
});

export const postPrintJobDetailedRequest = (body: any) => ({
  type: POST_PRINT_JOB_DETAILED_REQUEST,
  payload: body,
});
export const postPrintJobDetailedSuccess = (response: any) => ({
  type: POST_PRINT_JOB_DETAILED_SUCCESS,
  payload: response,
});
export const postPrintJobDetailedError = (error: any) => ({
  type: POST_PRINT_JOB_DETAILED_ERROR,
  payload: error,
});

export const postFilterPrintJobRequest = (body: any) => ({
  type: POST_FILTER_PRINT_JOB_REQUEST,
  payload: body,
});
export const postFilterPrintJobSuccess = (response: any) => ({
  type: POST_FILTER_PRINT_JOB_SUCCESS,
  payload: response,
});
export const postFilterPrintJobError = (error: any) => ({
  type: POST_FILTER_PRINT_JOB_ERROR,
  payload: error,
});

export const postUnreserveMarkingCodesRequest = (body: any) => ({
  type: POST_UNRESERVE_MARKING_CODES_REQUEST,
  payload: body,
});
export const postUnreserveMarkingCodesSuccess = (response: any) => ({
  type: POST_UNRESERVE_MARKING_CODES_SUCCESS,
  payload: response,
});
export const postUnreserveMarkingCodesError = (error: any) => ({
  type: POST_UNRESERVE_MARKING_CODES_ERROR,
  payload: error,
});

export const closeErrorPopUpPrintJobs = () => ({
  type: CLOSE_ERROR_POPUP_PRINT_JOB,
});

export type Actions =
  | ReturnType<typeof postUnreserveMarkingCodesRequest>
  | ReturnType<typeof postUnreserveMarkingCodesSuccess>
  | ReturnType<typeof postUnreserveMarkingCodesError>
  | ReturnType<typeof postPrintJobDetailedRequest>
  | ReturnType<typeof postPrintJobDetailedSuccess>
  | ReturnType<typeof postPrintJobDetailedError>
  | ReturnType<typeof closeErrorPopUpPrintJobs>
  | ReturnType<typeof postFilterPrintJobRequest>
  | ReturnType<typeof postFilterPrintJobSuccess>
  | ReturnType<typeof postFilterPrintJobError>
  | ReturnType<typeof postCancelPrintJobRequest>
  | ReturnType<typeof postCancelPrintJobSuccess>
  | ReturnType<typeof postCancelPrintJobError>
  | ReturnType<typeof getAllPrintCodesRequest>
  | ReturnType<typeof getAllPrintCodesSuccess>
  | ReturnType<typeof getAllPrintCodesError>;
