import {
  GET_DATA_TRUE_API_ERROR,
  GET_DATA_TRUE_API_REQUEST,
  GET_DATA_TRUE_API_SUCCESS,
  GET_KEY_RESPONSE_ERROR,
  GET_SETTINGS_ERROR,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  POST_UPDATE_SETTINGS_ERROR,
  POST_UPDATE_SETTINGS_REQUEST,
  POST_UPDATE_SETTINGS_SUCCESS,
} from './constants';

export const getSettingsRequest = () => ({
  type: GET_SETTINGS_REQUEST,
});
export const getSettingsSuccess = (data: any) => ({
  type: GET_SETTINGS_SUCCESS,
  payload: data,
});
export const getSettingsError = (error: any) => ({
  type: GET_SETTINGS_ERROR,
  payload: error,
});

export const postUpdateSettingsRequest = (body: any, countryDrop: any) => ({
  type: POST_UPDATE_SETTINGS_REQUEST,
  payload: body,
  countryId: countryDrop,
});
export const postUpdateSettingsSuccess = (data: any) => ({
  type: POST_UPDATE_SETTINGS_SUCCESS,
  payload: data,
});
export const postUpdateSettingsError = (error: any) => ({
  type: POST_UPDATE_SETTINGS_ERROR,
  payload: error,
});

export const getDataTrueApiRequest = (data: any) => ({
  type: GET_DATA_TRUE_API_REQUEST,
  payload: data,
});
export const getDataTrueApiSuccess = (data: any) => ({
  type: GET_DATA_TRUE_API_SUCCESS,
  payload: data,
});
export const getDataTrueApiError = (error: any) => ({
  type: GET_DATA_TRUE_API_ERROR,
  payload: error,
});

export const getKeyResponseError = (error: any) => ({
  type: GET_KEY_RESPONSE_ERROR,
  payload: error,
});

export type Actions =
  | ReturnType<typeof getKeyResponseError>
  | ReturnType<typeof getDataTrueApiRequest>
  | ReturnType<typeof getDataTrueApiSuccess>
  | ReturnType<typeof getDataTrueApiError>
  | ReturnType<typeof postUpdateSettingsRequest>
  | ReturnType<typeof postUpdateSettingsSuccess>
  | ReturnType<typeof postUpdateSettingsError>
  | ReturnType<typeof getSettingsRequest>
  | ReturnType<typeof getSettingsSuccess>
  | ReturnType<typeof getSettingsError>;
