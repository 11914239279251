import { Actions } from './actions';
import {
  GET_CODES_FOR_IMPORT_REPORT_REQUEST,
  GET_CODES_FOR_IMPORT_REPORT_SUCCESS,
  GET_CODES_FOR_IMPORT_REPORT_ERROR,
  CLOSE_ERROR_POPUP_REPORT_ERROR,
} from './constants';

const initialState = {
  loading: false as boolean,
  error: null as Error | null,

  codesForImportReportResponse: [] as any,
  codesForImportReportErrorResponses: {} as any,
};

export type ImportReportCodesState = typeof initialState;

export const ImportReportCodesReducer = (state: ImportReportCodesState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_CODES_FOR_IMPORT_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        codesForImportReportResponse: [],
        codesForImportReportErrorResponses: {},
      };
    }
    case GET_CODES_FOR_IMPORT_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        codesForImportReportResponse: action.payload,
      };
    }
    case GET_CODES_FOR_IMPORT_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        codesForImportReportErrorResponses: action.payload,
      };
    }

    case CLOSE_ERROR_POPUP_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        codesForImportReportErrorResponses: {},
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
