export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST' as const;
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS' as const;
export const GET_INVOICES_ERROR = 'GET_INVOICES_ERROR' as const;

export const GET_GLOBAL_EXCEL_REPORT_INVOICES_REQUEST = 'GET_GLOBAL_EXCEL_REPORT_INVOICES_REQUEST' as const;
export const GET_GLOBAL_EXCEL_REPORT_INVOICES_SUCCESS = 'GET_GLOBAL_EXCEL_REPORT_INVOICES_SUCCESS' as const;
export const GET_GLOBAL_EXCEL_REPORT_INVOICES_ERROR = 'GET_GLOBAL_EXCEL_REPORT_INVOICES_ERROR' as const;

export const GET_DETAILED_EXCEL_REPORT_INVOICES_REQUEST = 'GET_DETAILED_EXCEL_REPORT_INVOICES_REQUEST' as const;
export const GET_DETAILED_EXCEL_REPORT_INVOICES_SUCCESS = 'GET_DETAILED_EXCEL_REPORT_INVOICES_SUCCESS' as const;
export const GET_DETAILED_EXCEL_REPORT_INVOICES_ERROR = 'GET_DETAILED_EXCEL_REPORT_INVOICES_ERROR' as const;

export const CLOSE_ERROR_POPUP_REPORT_ERROR = 'CLOSE_ERROR_POPUP_REPORT_ERROR' as const;
