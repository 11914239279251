import { AxiosResponse } from 'axios';
import { merge } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ShippingOrders } from '../../../../entities/ShippingOrders/service';
import {
  deleteFilterError,
  deleteFilterRequest,
  deleteFilterSuccess,
  deleteProductFromDocumentError,
  deleteProductFromDocumentRequest,
  deleteProductFromDocumentSuccess,
  deleteShippingOrderByIdError,
  deleteShippingOrderByIdRequest,
  downloadAggregationReportError,
  downloadAggregationReportRequest,
  downloadAggregationReportSuccess,
  downloadShippingOrderError,
  downloadShippingOrderRequest,
  downloadShippingOrderSuccess,
  getAllShippingOrdersError,
  getAllShippingOrdersSuccess,
  getShippingOrderByDateError,
  getShippingOrderByDateRequest,
  getShippingOrderByDateSuccess,
  getShippingOrderByIdError,
  getShippingOrderByIdRequest,
  getShippingOrderByIdSuccess,
  postCheckProductError,
  postCheckProductRequest,
  postCheckProductSuccess,
  postCheckSeriesError,
  postCheckSeriesRequest,
  postCheckSeriesSuccess,
  postCreateShippingOrderError,
  postCreateShippingOrderRequest,
  postCreateShippingOrderSuccess,
  postUpdateIconSeriesError,
  postUpdateIconSeriesRequest,
  postUpdateIconSeriesSuccess,
  putUpdateShippingOrderError,
  putUpdateShippingOrderRequest,
  putUpdateShippingOrderSuccess,
  setErrorMessageShippings,
} from './actions';
import {
  DELETE_FILTER_REQUEST,
  DELETE_PRODUCT_FROM_DOCUMENT_REQUEST,
  DELETE_SHIPPING_ORDER_BY_ID_REQUEST,
  DOWNLOAD_AGGREGATION_REPORT_REQUEST,
  DOWNLOAD_SHIPPING_ORDER_REQUEST,
  GET_ALL_SHIPPING_ORDERS_REQUEST,
  GET_SHIPPING_ORDER_BY_DATE_REQUEST,
  GET_SHIPPING_ORDER_BY_ID_REQUEST,
  POST_CHECK_PRODUCT_REQUEST,
  POST_CHECK_SERIES_REQUEST,
  POST_CREATE_SHIPPING_ORDER_REQUEST,
  POST_UPDATE_ICON_SERIES_REQUEST,
  PUT_UPDATE_SHIPPING_ORDER_REQUEST,
} from './constants';
import { shippingOrdersSelector } from './selector';

function* getAllShippingOrdersSaga() {
  try {
    const response: AxiosResponse = yield call(ShippingOrders.getAllShippingOrders);
    const shippings = response.data.ResponseBody;
    yield put(getAllShippingOrdersSuccess(shippings));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getAllShippingOrdersError(error));
  }
}

function* postCreateShippingOrderSaga(action: ReturnType<typeof postCreateShippingOrderRequest>) {
  try {
    const response: AxiosResponse = yield call(ShippingOrders.postCreateShippingOrder);
    const shippings = response.data.ResponseBody;
    action.navigate(`/export_lists/entry?${shippings.id}`);
    yield put(postCreateShippingOrderSuccess(shippings));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postCreateShippingOrderError(error));
  }
}

function* putUpdateShippingOrderSaga(action: ReturnType<typeof putUpdateShippingOrderRequest>) {
  try {
    const response: AxiosResponse = yield call(ShippingOrders.putUpdateShippingOrder, action.payload);
    const shippings = response.data.ResponseBody;
    yield put(putUpdateShippingOrderSuccess(shippings));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(putUpdateShippingOrderError(error));
  }
}

function* getShippingOrderByIdSaga(action: ReturnType<typeof getShippingOrderByIdRequest>) {
  try {
    const response: AxiosResponse = yield call(ShippingOrders.getShippingOrderById, action.payload);
    const shippings = response.data.ResponseBody;
    yield put(getShippingOrderByIdSuccess(shippings));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getShippingOrderByIdError(error));
  }
}

function* getShippingOrderByDateSaga(action: ReturnType<typeof getShippingOrderByDateRequest>) {
  try {
    const response: AxiosResponse = yield call(ShippingOrders.getShippingOrderByDate, action.payload);
    const shippings = response.data.ResponseBody;
    yield put(getShippingOrderByDateSuccess(shippings));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getShippingOrderByDateError(error));
  }
}

function* postCheckSeriesSaga(action: ReturnType<typeof postCheckSeriesRequest>) {
  try {
    // @ts-expect-error
    const order: any = yield select(shippingOrdersSelector);
    const response: AxiosResponse = yield call(ShippingOrders.postCheckSeries, {
      series: action.payload.series,
      gtin: action.payload.gtin,
    });
    const gtin = action.payload.gtin;
    const selectedProduct = action.payload.product.products.find((item: any) => item.gtin === gtin);
    const series = response.data.ResponseBody;
    selectedProduct.filters.unshift({ ...series });
    yield put(postCheckSeriesSuccess(order.order));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postCheckSeriesError(error));
  }
}

function* postCheckProductSaga(action: ReturnType<typeof postCheckProductRequest>) {
  try {
    const response: AxiosResponse = yield call(ShippingOrders.postCheckProduct, {
      gtin: action.payload.gtin,
    });
    const res = response.data.ResponseBody;
    if (response.data.ResponseBody.messages.Failed.length === 0) {
      action.payload.product.products.unshift({ ...response.data.ResponseBody.messages.Successful });
      yield put(postCheckProductSuccess({ ...action.payload.product }));
    } else {
      yield put(setErrorMessageShippings(res.messages?.Failed));
    }
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postCheckProductError(error));
  }
}

function* deleteShippingOrderByIdSaga(action: ReturnType<typeof deleteShippingOrderByIdRequest>) {
  try {
    yield call(ShippingOrders.deleteShippingOrder, action.payload);
  } catch (e: any) {
    const error = e.toJSON();
    yield put(deleteShippingOrderByIdError(error));
  }
}

function* postUpdateIconSeriesSaga(action: ReturnType<typeof postUpdateIconSeriesRequest>) {
  try {
    const response: AxiosResponse = yield call(ShippingOrders.postCheckSeries, {
      series: action.payload.series,
      gtin: action.payload.gtin,
    });
    const res = response.data.ResponseBody;
    const selectedProduct = action.payload.product.products.find((item: any) => item.gtin === action.payload.gtin);
    const selectedFilter = selectedProduct.filters.find((i: any) => i.series === res.series);
    merge(res, selectedFilter);
    yield put(postUpdateIconSeriesSuccess({ ...action.payload.product }));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postUpdateIconSeriesError(error));
  }
}

function* deleteFilterSaga(action: ReturnType<typeof deleteFilterRequest>) {
  try {
    yield call(ShippingOrders.deleteFilter, action.payload);

    yield put(deleteFilterSuccess({ ...action.payload.product }));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(deleteFilterError(error));
  }
}

function* deleteProductFromDocumentSaga(action: ReturnType<typeof deleteProductFromDocumentRequest>) {
  try {
    yield call(ShippingOrders.deleteProductFromDoc, action.payload);

    yield put(deleteProductFromDocumentSuccess({ ...action.payload.product }));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(deleteProductFromDocumentError(error));
  }
}

function* downloadFilesSaga(action: ReturnType<typeof downloadShippingOrderRequest>) {
  try {
    yield call(ShippingOrders.getOrderReport, action.payload);

    yield put(downloadShippingOrderSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(downloadShippingOrderError(error));
  }
}

function* downloadAggregationReportSaga(action: ReturnType<typeof downloadAggregationReportRequest>) {
  try {
    yield call(ShippingOrders.getAggregationReport, action.payload);

    yield put(downloadAggregationReportSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(downloadAggregationReportError(error));
  }
}

export function* ShippingOrdersSagaWatcher() {
  yield takeLatest(DOWNLOAD_AGGREGATION_REPORT_REQUEST, downloadAggregationReportSaga);
  yield takeLatest(DOWNLOAD_SHIPPING_ORDER_REQUEST, downloadFilesSaga);
  yield takeLatest(GET_ALL_SHIPPING_ORDERS_REQUEST, getAllShippingOrdersSaga);
  yield takeLatest(POST_CREATE_SHIPPING_ORDER_REQUEST, postCreateShippingOrderSaga);
  yield takeLatest(PUT_UPDATE_SHIPPING_ORDER_REQUEST, putUpdateShippingOrderSaga);
  yield takeLatest(GET_SHIPPING_ORDER_BY_ID_REQUEST, getShippingOrderByIdSaga);
  yield takeLatest(POST_CHECK_SERIES_REQUEST, postCheckSeriesSaga);
  yield takeLatest(POST_CHECK_PRODUCT_REQUEST, postCheckProductSaga);
  yield takeLatest(DELETE_SHIPPING_ORDER_BY_ID_REQUEST, deleteShippingOrderByIdSaga);
  yield takeLatest(POST_UPDATE_ICON_SERIES_REQUEST, postUpdateIconSeriesSaga);
  yield takeLatest(DELETE_FILTER_REQUEST, deleteFilterSaga);
  yield takeLatest(DELETE_PRODUCT_FROM_DOCUMENT_REQUEST, deleteProductFromDocumentSaga);
  yield takeLatest(GET_SHIPPING_ORDER_BY_DATE_REQUEST, getShippingOrderByDateSaga);
}
