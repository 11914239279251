import { Actions } from './actions';
import {
  CLOSE_ERROR_POPUP_PRODUCTS,
  DELETE_PRODUCT_ERROR,
  DELETE_PRODUCT_SUCCESS,
  GET_ALL_PRODUCTS_ERROR,
  GET_ALL_PRODUCTS_REQUEST,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_PHOTO_ERROR,
  GET_PHOTO_REQUEST,
  GET_PHOTO_SUCCESS,
  GET_PRODUCT_BY_GTIN_ERROR,
  GET_PRODUCT_BY_GTIN_REQUEST,
  GET_PRODUCT_BY_GTIN_SUCCESS,
  POST_CREATE_PRODUCT_ERROR,
  POST_CREATE_PRODUCT_REQUEST,
  POST_CREATE_PRODUCT_SUCCESS,
  PUT_ADD_PRODUCT_IMAGE_ERROR,
  PUT_ADD_PRODUCT_IMAGE_REQUEST,
  PUT_ADD_PRODUCT_IMAGE_SUCCESS,
  PUT_UPDATE_PRODUCT_ERROR,
  PUT_UPDATE_PRODUCT_REQUEST,
  PUT_UPDATE_PRODUCT_SUCCESS,
  SET_ERROR_MESSAGE,
} from './constants';

const initialState = {
  loading: false as boolean,
  error: null as Error | null,
  product: {} as any,
  photo: null as any,
  productsList: [] as any,
  failedResponse: [] as any,
  mainPhoto: null as any | string,

  getProductsErrorResponses: {} as any,
  getProductByGtinErrorResponses: {} as any,
  createProductErrorResponses: {} as any,
  updateProductErrorResponses: {} as any,
  deleteProductErrorResponses: {} as any,
  getPhotoErrorResponses: {} as any,
  updateProductSuccessResponse: {} as any,
};

export type ProductState = typeof initialState;

export const ProductReducer = (state: ProductState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS_REQUEST: {
      return {
        ...state,
        failedResponse: [],
        productsList: [],
        product: {},
        loading: true,
      };
    }
    case GET_ALL_PRODUCTS_SUCCESS: {
      return {
        ...state,
        productsList: action.payload,
        loading: false,
      };
    }
    case GET_ALL_PRODUCTS_ERROR: {
      return {
        ...state,
        loading: false,
        getProductsErrorResponses: action.payload,
      };
    }

    case GET_PRODUCT_BY_GTIN_REQUEST: {
      return {
        ...state,
        failedResponse: [],
        loading: true,
      };
    }
    case GET_PRODUCT_BY_GTIN_SUCCESS: {
      return {
        ...state,
        product: action.payload,
        loading: false,
      };
    }
    case GET_PRODUCT_BY_GTIN_ERROR: {
      return {
        ...state,
        loading: false,
        getProductByGtinErrorResponses: action.payload,
      };
    }

    case POST_CREATE_PRODUCT_REQUEST: {
      return {
        ...state,
        failedResponse: [],
        loading: true,
      };
    }
    case POST_CREATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        product: action.payload,
        loading: false,
      };
    }
    case POST_CREATE_PRODUCT_ERROR: {
      return {
        ...state,
        loading: false,
        createProductErrorResponses: action.payload,
      };
    }

    case PUT_UPDATE_PRODUCT_REQUEST: {
      return {
        ...state,
        failedResponse: [],
        loading: true,
      };
    }
    case PUT_UPDATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        updateProductSuccessResponse: action.payload,
      };
    }
    case PUT_UPDATE_PRODUCT_ERROR: {
      return {
        ...state,
        loading: false,
        updateProductErrorResponses: action.payload,
      };
    }

    case GET_PHOTO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PHOTO_SUCCESS: {
      return {
        ...state,
        photo: action.payload,
        loading: false,
      };
    }
    case GET_PHOTO_ERROR: {
      return {
        ...state,
        loading: false,
        getPhotoErrorResponses: action.payload,
      };
    }

    case PUT_ADD_PRODUCT_IMAGE_REQUEST: {
      return {
        ...state,
        mainPhoto: null,
      };
    }
    case PUT_ADD_PRODUCT_IMAGE_SUCCESS: {
      return {
        ...state,
        mainPhoto: action.payload,
        loading: false,
      };
    }
    case PUT_ADD_PRODUCT_IMAGE_ERROR: {
      return {
        ...state,
        loading: false,
        mainPhoto: null,
      };
    }

    case DELETE_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        product: {},
      };
    }
    case DELETE_PRODUCT_ERROR: {
      return {
        ...state,
        loading: false,
        deleteProductErrorResponses: action.payload,
      };
    }
    case SET_ERROR_MESSAGE: {
      return {
        ...state,
        failedResponse: action.payload,
        loading: false,
      };
    }
    case CLOSE_ERROR_POPUP_PRODUCTS: {
      return {
        ...state,
        deleteProductErrorResponses: {},
        getProductsErrorResponses: {} as any,
        getProductByGtinErrorResponses: {} as any,
        createProductErrorResponses: {} as any,
        updateProductErrorResponses: {} as any,
        getPhotoErrorResponses: {} as any,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
