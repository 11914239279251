import { useNavigate } from 'react-router-dom';
import ErrorResponse from '../../shared/ErrorResponse';
import styles from './Settings.module.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingsRequest } from './services/actions';
import { settingsSelector } from './services/selector';
import { Formik } from 'formik';
import { ReadOnlyField } from './components/ReadOnlyField';
import { TextField } from '@mui/material';

export const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { settings, settingsErrorResponses } = useSelector(settingsSelector);

  useEffect(() => {
    dispatch(getSettingsRequest());
  }, []);

  useEffect(() => {
    if (Object.keys(settingsErrorResponses).length !== 0 && settingsErrorResponses?.status === 403) {
      navigate('/error');
    }
  }, [settingsErrorResponses]);

  const textFieldStyle = {
    bgcolor: '#f0eeee',
    width: '100%',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#B0BEC5',
      // height: '65%',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#B0BEC5',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#B0BEC5',
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '13px !important',
      '& fieldset': {
        borderColor: '#B0BEC5',
      },
      '&:hover fieldset': {
        borderColor: '#B0BEC5',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#B0BEC5',
      },
    },
  };

  return (
    <div className={styles.wrapper}>
      {Object.keys(settingsErrorResponses).length !== 0 ? <ErrorResponse error={settingsErrorResponses} /> : null}
      <span className={styles.title}>Системні параметри</span>
      <span>Список значень глобальних системних налаштувань.</span>

      <div className={styles.shippingCountry}>
        <Formik initialValues={{}} onSubmit={() => undefined}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ReadOnlyField name="CID" title="CID" value={settings?.company?.cid} size="large" readOnly />
              <ReadOnlyField
                name="companyName"
                title="Назва компанії"
                value={settings?.company?.companyName}
                size="large"
                readOnly
              />
              <ReadOnlyField
                name="entraGroupId"
                title="Ідентифікатор групи"
                value={settings?.company?.entraGroupId}
                size="large"
                readOnly
              />
            </form>
          )}
        </Formik>
      </div>
      <span className={styles.fieldname}>Конфігурація системи:</span>
      <div>
        <TextField
          name="value"
          id="outlined-multiline-static"
          label=""
          inputProps={{ style: { fontSize: 14, fontFamily: 'monospace', lineHeight: 1 } }}
          multiline
          rows={30}
          sx={textFieldStyle}
          value={settings?.countryConfig}
        />
      </div>
    </div>
  );
};
