import { AxiosResponse } from 'axios';
import { instance as axios } from 'api/axios';

export class Invoices {
  public static async getInvoices(body: any) {
    const url = '/DocumentShipping/GetAll';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async getGlobalExcelReport(body: any) {
    const requestUrl = '/DocumentShipping/Excel/File/ByShippingDocumentIds';
    const res = await axios({
      url: `${requestUrl}`,
      method: 'POST',
      responseType: 'blob',
      data: body,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `DocumentShipping.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
    return res;
  }

  public static async geDetailedExcelReport(body: any) {
    const requestUrl = '/DocumentShipping/Excel/File';

    // eslint-disable-next-line no-useless-catch
    try {
      const res = await axios({
        url: `${requestUrl}`,
        method: 'POST',
        responseType: 'blob',
        data: body,
      });

      if (res.status !== 204) {
        const blobUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `DocumentShipping.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return res;
      } else {
        return res;
      }
    } catch (error) {
      throw error;
    }
  }
}
