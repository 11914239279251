import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { Settings } from '../../../../entities/Settings/service';
import { GET_LICENSED_DEVICES_REQUEST } from './constants';
import { getLicensedDevicesError, getLicensedDevicesSuccess } from './actions';

function* getLicensedDevicesRequestSaga() {
  try {
    const response: AxiosResponse = yield call(Settings.getStation);
    const res = response.data.ResponseBody;
    yield put(getLicensedDevicesSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getLicensedDevicesError(error));
  }
}

export function* LicensedDevicesSagaWatcher() {
  yield takeLatest(GET_LICENSED_DEVICES_REQUEST, getLicensedDevicesRequestSaga);
}
