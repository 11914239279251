import { Checkbox } from '@mui/material';
import { useFormikContext } from 'formik';

const checkboxStyle = {
  marginTop: '10px !important',
  height: '30px',
  '& .MuiSvgIcon-root': { color: '#333333' },
};
const checkboxNomarginStyle = {
  height: '30px',
  '& .MuiSvgIcon-root': { color: '#333333' },
};
interface Props {
  name?: string;
  checked: boolean;
  hasMargin?: boolean;
  disabled?: boolean;
  onChange: any;
}
export const CheckBoxField = ({ checked, onChange, hasMargin, disabled, name }: Props) => {
  const { handleBlur } = useFormikContext<any>();

  return (
    <Checkbox
      name={name}
      sx={hasMargin ? checkboxStyle : checkboxNomarginStyle}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      onBlur={handleBlur}
    />
  );
};
