import styles from './ErrorPage.module.scss';

export const ErrorPage = () => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Помилка доступу</span>
      <span>
        Ви не є учасником групи з доступом до системи.
        <br /> Зверністься в підтримку: service@sytecs.com.ua
      </span>
    </div>
  );
};
