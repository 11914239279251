import { Actions } from './actions';
import {
  GET_TEXT_REPORT_REQUEST,
  GET_TEXT_REPORT_SUCCESS,
  GET_TEXT_REPORT_ERROR,
  CLOSE_ERROR_POPUP_SEARCH_CODES,
  GET_TREE_REPORT_REQUEST,
  GET_TREE_REPORT_SUCCESS,
  GET_TREE_REPORT_ERROR,
  GET_DOWNLOAD_REPORT_REQUEST,
  GET_DOWNLOAD_REPORT_SUCCESS,
  GET_DOWNLOAD_REPORT_ERROR,
} from './constants';

const initialState = {
  loading: false as boolean,
  downloadLoading: false as boolean,
  error: null as Error | null,
  textReport: [] as any,
  graphReport: [] as any,
  downloadResponse: [] as any,

  getTextReportErrorResponse: {} as any,
  downloadReportErrorResponse: {} as any,
};

export type SearchCodesState = typeof initialState;

export const SearchCodesReducer = (state: SearchCodesState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_TEXT_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        getTextReportErrorResponse: {},
        textReport: [],
        graphReport: [],
      };
    }
    case GET_TEXT_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        textReport: action.payload,
      };
    }
    case GET_TEXT_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        getTextReportErrorResponse: action.payload,
      };
    }
    case CLOSE_ERROR_POPUP_SEARCH_CODES: {
      return {
        ...state,
        loading: false,
        getTextReportErrorResponse: {},
        downloadReportErrorResponse: {},
      };
    }
    case GET_TREE_REPORT_REQUEST: {
      return {
        ...state,
        loading: true,
        getTextReportErrorResponse: {},
        textReport: [],
        graphReport: [],
      };
    }
    case GET_TREE_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        graphReport: action.payload,
      };
    }
    case GET_TREE_REPORT_ERROR: {
      return {
        ...state,
        loading: false,
        getTextReportErrorResponse: action.payload,
      };
    }

    case GET_DOWNLOAD_REPORT_REQUEST: {
      return {
        ...state,
        downloadLoading: true,
        downloadReportErrorResponse: {},
        downloadResponse: [],
      };
    }
    case GET_DOWNLOAD_REPORT_SUCCESS: {
      return {
        ...state,
        downloadLoading: false,
      };
    }
    case GET_DOWNLOAD_REPORT_ERROR: {
      return {
        ...state,
        downloadLoading: false,
        downloadReportErrorResponse: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
