import { AxiosResponse } from 'axios';
import { instance as axios } from 'api/axios';

export class Packing {
  public static async getAllPackings() {
    const url = '/Packing/GetAll';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async addNewPacking() {
    const url = '/Packing/Create';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async getPackingById(body: any) {
    const url = '/Packing/GetById';
    const response: AxiosResponse = await axios.post(url, body);
    return response;
  }

  public static async putUpdatePacking(body: any) {
    const url = '/Packing/Update';
    const response: AxiosResponse = await axios.put(url, body);
    return response;
  }

  public static async deletePacking(body: any) {
    const url = '/Packing/Delete';
    const response: AxiosResponse = await axios.delete(url, { data: body });
    return response;
  }
}
