import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { getCountryListError, getCountryListSuccess } from './actions';

import { CountryList } from './service';
import { GET_COUNTRY_LIST_REQUEST } from './constants';

function* getCountryListSaga() {
  try {
    const response: AxiosResponse = yield call(CountryList.getCountryList);
    const countryArray = response.data.ResponseBody;
    yield put(getCountryListSuccess(countryArray));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getCountryListError(error));
  }
}

export function* CountryListSagaWatcher() {
  yield takeLatest(GET_COUNTRY_LIST_REQUEST, getCountryListSaga);
}
