import { isObject, isArray, isBoolean, isUndefined, isNull, mapValues, isString, trim, shuffle } from 'lodash';

export const ucFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const isBlob = (value: any) =>
  value && typeof value.size === 'number' && typeof value.type === 'string' && typeof value.slice === 'function';

export const isFile = (value: any) =>
  isBlob(value) &&
  typeof value.name === 'string' &&
  (typeof value.lastModifiedDate === 'object' || typeof value.lastModified === 'number');

export const objToFormData = (obj: any, fd?: FormData, pre: string = '') => {
  const fData = fd || new FormData();

  if (isUndefined(obj) || isNull(obj)) {
    return fData;
  }

  if (isBoolean(obj)) {
    // @ts-expect-error
    fData.append(ucFirst(pre), obj);
  } else if (isArray(obj)) {
    if (obj.length) {
      obj.forEach((value: any, index: number) => {
        if (isFile(value) || isBlob(value)) {
          objToFormData(value, fData, ucFirst(pre));
        } else {
          const key = `${ucFirst(pre)}[${index}]`;
          objToFormData(value, fData, key);
        }
      });
    }
  } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
    Object.keys(obj).forEach((prop) => {
      // @ts-expect-error
      const value = obj[prop];
      let propName = prop;

      if (isArray(value)) {
        while (propName.length > 2 && propName.lastIndexOf('[]') === propName.length - 2) {
          propName = propName.substring(0, propName.length - 2);
        }
      }

      const key = pre ? `${ucFirst(pre)}.${ucFirst(propName)}` : propName;

      objToFormData(value, fData, key);
    });
  } else if (isString(obj)) {
    if (trim(obj).length) {
      fData.append(ucFirst(pre), trim(obj));
    } else {
      return fData;
    }
  } else {
    fData.append(ucFirst(pre), obj);
  }

  return fData;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const callPrint = (Component: {} | undefined) => {
  if (Component) {
    window.print();
  }
};

export const randomInteger = (min: number, max: number) => {
  const rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
};

export const convertUTCDateToLocal = (date: string) => {
  const dateFormat = new Date(date);
  const newDate = new Date(dateFormat.getTime() + dateFormat.getTimezoneOffset() * 60 * 1000);

  const offset = dateFormat.getTimezoneOffset() / 60;
  const hours = dateFormat.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const trimingObjString = (obj: any, newObj?: Object) => {
  const trimObj = newObj || {};

  mapValues(obj, (v: any) => {
    if (isObject(v)) {
      Object.keys(v).forEach((prop) => {
        // @ts-expect-error
        const value = v[prop];

        if (isString(value)) trim(value);

        if (isArray(value)) {
          if (value.length) {
            value.forEach((valArr: any) => trimingObjString(valArr, newObj));
          }
        }
      });
    }
    if (isString(v)) trim(v);
  });

  return trimObj;
};

export const random4Digit = () => shuffle('0123456789'.split('')).join('').substring(0, 4);

export const trims = (str: string = '') => str.replaceAll(' ', '');
