import styles from './Page451.module.scss';
import { useMsal } from '@azure/msal-react';

export const Page451 = () => {
  const { instance } = useMsal();

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Помилка доступу (код 451)</span>
      <span className={styles.subtitle}>
        Ви не є учасником групи з доступом до системи.
        <br /> Зверністься в підтримку: service@sytecs.com.ua
      </span>
      <span
        className={styles.exit}
        onClick={() => {
          instance.logoutRedirect().catch((e: any) => {
            console.error(e);
          });
        }}
      >
        Вихід з системи
      </span>
    </div>
  );
};
