import { useState } from 'react';

const useModal = (defaultStatus = false) => {
  const [isShow, setIsShowing] = useState(defaultStatus);

  function openModal() {
    setIsShowing(true);
  }

  function closeModal() {
    setIsShowing(false);
  }

  return { isShow, openModal, closeModal };
};

export default useModal;
