import { AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-duplicates
import { instance as axios } from 'api/axios';
// eslint-disable-next-line import/no-duplicates

export class Settings {
  public static async getSettings() {
    const url = '/System/Parameters';
    const response: AxiosResponse = await axios.post(url, {});
    return response;
  }

  public static async getStation() {
    const url = '/System/Station/GetAll';
    const response: AxiosResponse = await axios.post(url);
    return response;
  }

  public static async getDataTrueApi(body: any) {
    const url = '/TrueApi/Auth/SimpleSingIn';
    const response: any = await axios.post(url, body);
    return response;
  }
}
