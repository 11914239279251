import {
  GET_RECEIPTS_ERROR,
  GET_RECEIPTS_SUCCESS,
  GET_RECEIPTS_REQUEST,
  CLOSE_ERROR_POPUP_RECEIPTS,
  DELETE_DEACTIVATE_ERROR,
  DELETE_DEACTIVATE_REQUEST,
  DELETE_DEACTIVATE_SUCCESS,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  GET_PRODUCT_LIST_REQUEST,
  POST_CREATE_RECEIPT_REQUEST,
  POST_CREATE_RECEIPT_SUCCESS,
  POST_CREATE_RECEIPT_ERROR,
  GET_RECEIPT_PDF_FILE_ERROR,
  GET_RECEIPT_PDF_FILE_REQUEST,
  GET_RECEIPT_PDF_FILE_SUCCESS,
} from './constants';

export const getReceiptsRequest = (body: any) => ({
  type: GET_RECEIPTS_REQUEST,
  payload: body,
});
export const getReceiptsSuccess = (data: any) => ({
  type: GET_RECEIPTS_SUCCESS,
  payload: data,
});
export const getReceiptsError = (error: any) => ({
  type: GET_RECEIPTS_ERROR,
  payload: error,
});

export const deleteDeactivateRequest = (body: any) => ({
  type: DELETE_DEACTIVATE_REQUEST,
  payload: body,
});
export const deleteDeactivateSuccess = (data: any) => ({
  type: DELETE_DEACTIVATE_SUCCESS,
  payload: data,
});
export const deleteDeactivateError = (error: any) => ({
  type: DELETE_DEACTIVATE_ERROR,
  payload: error,
});

export const getProductListRequest = () => ({
  type: GET_PRODUCT_LIST_REQUEST,
});
export const getProductListSuccess = (data: any) => ({
  type: GET_PRODUCT_LIST_SUCCESS,
  payload: data,
});
export const getProductListError = (error: any) => ({
  type: GET_PRODUCT_LIST_ERROR,
  payload: error,
});

export const postCreateReceiptRequest = (body: any) => ({
  type: POST_CREATE_RECEIPT_REQUEST,
  payload: body,
});
export const postCreateReceiptSuccess = (data: any) => ({
  type: POST_CREATE_RECEIPT_SUCCESS,
  payload: data,
});
export const postCreateReceiptError = (error: any) => ({
  type: POST_CREATE_RECEIPT_ERROR,
  payload: error,
});

export const closeErrorPopUpReceipts = () => ({
  type: CLOSE_ERROR_POPUP_RECEIPTS,
});

export const getReceiptPDFFileRequest = (body: any) => ({
  type: GET_RECEIPT_PDF_FILE_REQUEST,
  payload: body,
});
export const getReceiptPDFFileSuccess = () => ({
  type: GET_RECEIPT_PDF_FILE_SUCCESS,
});
export const getReceiptPDFFileError = (error: any) => ({
  type: GET_RECEIPT_PDF_FILE_ERROR,
  payload: error,
});

export type Actions =
  | ReturnType<typeof postCreateReceiptRequest>
  | ReturnType<typeof postCreateReceiptSuccess>
  | ReturnType<typeof postCreateReceiptError>
  | ReturnType<typeof getProductListRequest>
  | ReturnType<typeof getProductListSuccess>
  | ReturnType<typeof getProductListError>
  | ReturnType<typeof closeErrorPopUpReceipts>
  | ReturnType<typeof deleteDeactivateRequest>
  | ReturnType<typeof deleteDeactivateSuccess>
  | ReturnType<typeof deleteDeactivateError>
  | ReturnType<typeof getReceiptsRequest>
  | ReturnType<typeof getReceiptsSuccess>
  | ReturnType<typeof getReceiptsError>
  | ReturnType<typeof getReceiptPDFFileRequest>
  | ReturnType<typeof getReceiptPDFFileSuccess>
  | ReturnType<typeof getReceiptPDFFileError>;
