export const GET_UNIT_TEMPLATES_REQUEST = 'GET_UNIT_TEMPLATES_REQUEST' as const;
export const GET_UNIT_TEMPLATES_SUCCESS = 'GET_UNIT_TEMPLATES_SUCCESS' as const;
export const GET_UNIT_TEMPLATES_ERROR = 'GET_UNIT_TEMPLATES_ERROR' as const;

export const GET_UNIT_TEMPLATES_BY_ID_REQUEST = 'GET_UNIT_TEMPLATES_BY_ID_REQUEST' as const;
export const GET_UNIT_TEMPLATES_BY_ID_SUCCESS = 'GET_UNIT_TEMPLATES_BY_ID_SUCCESS' as const;
export const GET_UNIT_TEMPLATES_BY_ID_ERROR = 'GET_UNIT_TEMPLATES_BY_ID_ERROR' as const;

export const DELETE_UNIT_TEMPLATES_BY_ID_REQUEST = 'DELETE_UNIT_TEMPLATES_BY_ID_REQUEST' as const;
export const DELETE_UNIT_TEMPLATES_BY_ID_SUCCESS = 'DELETE_UNIT_TEMPLATES_BY_ID_SUCCESS' as const;
export const DELETE_UNIT_TEMPLATES_BY_ID_ERROR = 'DELETE_UNIT_TEMPLATES_BY_ID_ERROR' as const;

export const POST_CREATE_UNIT_TEMPLATES_REQUEST = 'POST_CREATE_UNIT_TEMPLATES_REQUEST' as const;
export const POST_CREATE_UNIT_TEMPLATES_SUCCESS = 'POST_CREATE_UNIT_TEMPLATES_SUCCESS' as const;
export const POST_CREATE_UNIT_TEMPLATES_ERROR = 'POST_CREATE_UNIT_TEMPLATES_ERROR' as const;

export const PUT_UPDATE_UNIT_TEMPLATES_REQUEST = 'PUT_UPDATE_UNIT_TEMPLATES_REQUEST' as const;
export const PUT_UPDATE_UNIT_TEMPLATES_SUCCESS = 'PUT_UPDATE_UNIT_TEMPLATES_SUCCESS' as const;
export const PUT_UPDATE_UNIT_TEMPLATES_ERROR = 'PUT_UPDATE_UNIT_TEMPLATES_ERROR' as const;

export const POST_UPDATE_PHOTO_UNIT_TEMPLATES_REQUEST = 'POST_UPDATE_PHOTO_UNIT_TEMPLATES_REQUEST' as const;
export const POST_UPDATE_PHOTO_UNIT_TEMPLATES_SUCCESS = 'POST_UPDATE_PHOTO_UNIT_TEMPLATES_SUCCESS' as const;
export const POST_UPDATE_PHOTO_UNIT_TEMPLATES_ERROR = 'POST_UPDATE_PHOTO_UNIT_TEMPLATES_ERROR' as const;

export const GET_PHOTO_UNIT_REQUEST = 'GET_PHOTO_UNIT_REQUEST' as const;
export const GET_PHOTO_UNIT_SUCCESS = 'GET_PHOTO_UNIT_SUCCESS' as const;
export const GET_PHOTO_UNIT_ERROR = 'GET_PHOTO_UNIT_ERROR' as const;

export const CLEAR_PHOTO_UNIT = 'CLEAR_PHOTO_UNIT' as const;
export const CLOSE_ERROR_POPUP_UNIT = 'CLOSE_ERROR_POPUP_UNIT' as const;
