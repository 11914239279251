import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { ShippingOrders } from '../../../../entities/ShippingOrders/service';
import {
  GET_AGGREGATION_REPORT_REQUEST,
  GET_EXCEL_FILE_REQUEST,
  GET_GLOBAL_REPORT_REQUEST,
  GET_JSON_FILE_REQUEST,
  PUT_UPDATE_STATUS_REQUEST,
} from './constants';
import {
  getAggregationReportError,
  getAggregationReportRequest,
  getAggregationReportSuccess,
  getExcelFileError,
  getExcelFileRequest,
  getExcelFileSuccess,
  getGlobalReportError,
  getGlobalReportRequest,
  getGlobalReportSuccess,
  getJSONFileError,
  getJSONFileRequest,
  getJSONFileSuccess,
  putUpdateStatusError,
  putUpdateStatusRequest,
  putUpdateStatusSuccess,
} from './actions';
import { Reports } from '../../../../entities/Reports/service';

function* getAggregationReportSaga(action: ReturnType<typeof getAggregationReportRequest>) {
  try {
    const response: AxiosResponse = yield call(Reports.getAggregationReport, action.payload);
    const aggregationReport = response.data.ResponseBody;
    yield put(getAggregationReportSuccess(aggregationReport));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getAggregationReportError(error));
  }
}

function* getExcelFileRequestSaga(action: ReturnType<typeof getExcelFileRequest>) {
  try {
    yield call(ShippingOrders.getExcelFile, action.payload);

    yield put(getExcelFileSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getExcelFileError(error));
  }
}

function* getGlobalReportRequestSaga(action: ReturnType<typeof getGlobalReportRequest>) {
  try {
    yield call(ShippingOrders.getGlobalReport, action.payload);

    yield put(getGlobalReportSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getGlobalReportError(error));
  }
}

function* getJSONFileRequestSaga(action: ReturnType<typeof getJSONFileRequest>) {
  try {
    yield call(ShippingOrders.getJSONFile, action.payload);

    yield put(getJSONFileSuccess());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getJSONFileError(error));
  }
}

function* putUpdateStatusRequestSaga(action: ReturnType<typeof putUpdateStatusRequest>) {
  try {
    const res: AxiosResponse = yield call(ShippingOrders.putUpdateStatus, action.payload);
    const body = res.data?.ResponseBody;

    yield put(putUpdateStatusSuccess(body));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(putUpdateStatusError(error));
  }
}

export function* AggregationReportSagaWatcher() {
  yield takeLatest(GET_EXCEL_FILE_REQUEST, getExcelFileRequestSaga);
  yield takeLatest(GET_JSON_FILE_REQUEST, getJSONFileRequestSaga);
  yield takeLatest(GET_AGGREGATION_REPORT_REQUEST, getAggregationReportSaga);
  yield takeLatest(PUT_UPDATE_STATUS_REQUEST, putUpdateStatusRequestSaga);
  yield takeLatest(GET_GLOBAL_REPORT_REQUEST, getGlobalReportRequestSaga);
}
