import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { GET_ALL_CODES_REQUEST, POST_ALLOW_TO_PRINT_REQUEST, POST_DETAILED_INFO_REQUEST } from './constants';
import { AvailableCodes } from '../../../../entities/AvailableCodes/service';
import {
  getAllCodesError,
  getAllCodesRequest,
  getAllCodesSuccess,
  postAllowToPrintError,
  postAllowToPrintRequest,
  postAllowToPrintSuccess,
  postDetailedInfoError,
  postDetailedInfoRequest,
  postDetailedInfoSuccess,
} from './actions';

function* getAvailableCodesSaga() {
  try {
    const response: AxiosResponse = yield call(AvailableCodes.getAvailableCodes);
    const availableCodes = response.data.ResponseBody.codesBank;
    yield put(getAllCodesSuccess(availableCodes));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getAllCodesError(error));
  }
}

function* postAllowToPrintSaga(action: ReturnType<typeof postAllowToPrintRequest>) {
  try {
    const response: AxiosResponse = yield call(AvailableCodes.allowToPrint, action.payload);
    const availableCodes = response.data.ResponseBody;
    yield put(postAllowToPrintSuccess(availableCodes));
    if (availableCodes.messages.Successful !== null) {
      yield put(getAllCodesRequest());
    }
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postAllowToPrintError(error));
  }
}

function* postDetailedInfoSaga(action: ReturnType<typeof postDetailedInfoRequest>) {
  try {
    const response: AxiosResponse = yield call(AvailableCodes.detailedInfo, action.payload);
    const detailedInfo = response.data.ResponseBody;
    yield put(postDetailedInfoSuccess(detailedInfo));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postDetailedInfoError(error));
  }
}

export function* AvailableCodesSagaWatcher() {
  yield takeLatest(GET_ALL_CODES_REQUEST, getAvailableCodesSaga);
  yield takeLatest(POST_ALLOW_TO_PRINT_REQUEST, postAllowToPrintSaga);
  yield takeLatest(POST_DETAILED_INFO_REQUEST, postDetailedInfoSaga);
}
