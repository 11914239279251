import { combineReducers } from 'redux';
import { ImportCodesReducer } from './ImportCodes/services/reducer';
import { AvailableCodesReducer } from './AvailableCodes/services/reducer';
import { ReceiptsReducer } from './Receipts/services/reducer';

export const codeBankModuleReducer = combineReducers({
  ImportCodesReducer,
  AvailableCodesReducer,
  ReceiptsReducer,
});

export type BankModuleReducer = ReturnType<typeof codeBankModuleReducer>;
