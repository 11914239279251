import * as Yup from 'yup';

export const ProductsSchema = Yup.object().shape({
  internalName: Yup.string().trim().required(),
  weightGR: Yup.mixed().required(),
  packingConfigurations: Yup.array().of(
    Yup.object().shape({
      amountPerLayer: Yup.number()
        .nullable()
        .when('productId', {
          is: (productId: any) => productId !== null,
          then: Yup.number().nullable().required(),
        }),
      layersCount: Yup.number()
        .nullable()
        .when('productId', {
          is: (productId: any) => productId !== null,
          then: Yup.number().nullable().required(),
        }),
      labelTemplateId: Yup.number()
        .nullable()
        .when('productId', {
          is: (productId: any) => productId !== null,
          then: Yup.number().nullable().required(),
        }),
      // amountPerLayer: Yup.mixed().when('productId', {
      //   is: (productId) => productId !== null,
      //   then: Yup.string().required(),
      //   otherwise: Yup.mixed(),
      // }),
    }),
  ),
});
