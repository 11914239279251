import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { GET_DATA_TRUE_API_REQUEST, GET_SETTINGS_REQUEST, POST_UPDATE_SETTINGS_REQUEST } from './constants';
import {
  getDataTrueApiError,
  getDataTrueApiRequest,
  getDataTrueApiSuccess,
  getSettingsError,
  getSettingsRequest,
  getSettingsSuccess,
  postUpdateSettingsError,
  postUpdateSettingsRequest,
  postUpdateSettingsSuccess,
} from './actions';
import { Settings } from '../../../../entities/Settings/service';

function* getSettingsRequestSaga() {
  try {
    const response: AxiosResponse = yield call(Settings.getSettings);
    const settings = response.data.ResponseBody;
    yield put(getSettingsSuccess(settings));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getSettingsError(error));
  }
}

function* postUpdateSettingsRequestSaga(action: ReturnType<typeof postUpdateSettingsRequest>) {
  try {
    const response: AxiosResponse = yield call(Settings.getDataTrueApi, action.payload);
    const settings = response.data.ResponseBody;
    yield put(postUpdateSettingsSuccess(settings));
    yield put(getSettingsRequest());
  } catch (e: any) {
    const error = e.toJSON();
    yield put(postUpdateSettingsError(error));
  }
}

function* getDataTrueApiRequestSaga(action: ReturnType<typeof getDataTrueApiRequest>) {
  try {
    const response: AxiosResponse = yield call(Settings.getDataTrueApi, action.payload);
    const res = response.data;
    yield put(getDataTrueApiSuccess(res));
  } catch (e: any) {
    const error = e.toJSON();
    yield put(getDataTrueApiError(error));
  }
}

export function* SettingsSagaWatcher() {
  yield takeLatest(GET_SETTINGS_REQUEST, getSettingsRequestSaga);
  yield takeLatest(POST_UPDATE_SETTINGS_REQUEST, postUpdateSettingsRequestSaga);
  yield takeLatest(GET_DATA_TRUE_API_REQUEST, getDataTrueApiRequestSaga);
}
