export const GET_AGGREGATION_REPORT_REQUEST = 'GET_AGGREGATION_REPORT_REQUEST' as const;
export const GET_AGGREGATION_REPORT_SUCCESS = 'GET_AGGREGATION_REPORT_SUCCESS' as const;
export const GET_AGGREGATION_REPORT_ERROR = 'GET_AGGREGATION_REPORT_ERROR' as const;

export const CLOSE_ERROR_POPUP_AGGREGATION_REPORT_ERROR = 'CLOSE_ERROR_POPUP_AGGREGATION_REPORT_ERROR' as const;

export const GET_EXCEL_FILE_REQUEST = 'GET_EXCEL_FILE_REQUEST' as const;
export const GET_EXCEL_FILE_SUCCESS = 'GET_EXCEL_FILE_SUCCESS' as const;
export const GET_EXCEL_FILE_ERROR = 'GET_EXCEL_FILE_ERROR' as const;

export const GET_GLOBAL_REPORT_REQUEST = 'GET_GLOBAL_REPORT_REQUEST' as const;
export const GET_GLOBAL_REPORT_SUCCESS = 'GET_GLOBAL_REPORT_SUCCESS' as const;
export const GET_GLOBAL_REPORT_ERROR = 'GET_GLOBAL_REPORT_ERROR' as const;

export const GET_JSON_FILE_REQUEST = 'GET_JSON_FILE_REQUEST' as const;
export const GET_JSON_FILE_SUCCESS = 'GET_JSON_FILE_SUCCESS' as const;
export const GET_JSON_FILE_ERROR = 'GET_JSON_FILE_ERROR' as const;

export const PUT_UPDATE_STATUS_REQUEST = 'PUT_UPDATE_STATUS_REQUEST' as const;
export const PUT_UPDATE_STATUS_SUCCESS = 'PUT_UPDATE_STATUS_SUCCESS' as const;
export const PUT_UPDATE_STATUS_ERROR = 'PUT_UPDATE_STATUS_ERROR' as const;
