import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Box, Button, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import styles from './AddWithdrawalModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import SimpleField from '../../../../common/SimpleField';
import { withdrawalSelector } from '../../services/selector';
import {
  deleteWithdrawalRequest,
  deleteWithdrawalSuccess,
  postAddNewWithdrawalRequest,
  postAddNewWithdrawalSuccess,
  putUpdateWithdrawalRequest,
  putUpdateWithdrawalSuccess,
} from '../../services/actions';
import { IWithdrawalReason } from '../../services/interfaces';
import { format } from 'date-fns';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Spinner } from '../../../../common/Spinner/Spinner';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 905,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  p: 4,
};

interface Props {
  isShown: boolean;
  withdrawalReason?: IWithdrawalReason;
  closeModal: () => void;
  type: 'create' | 'edit';
}

export const AddWithdrawalModal: React.FC<Props> = ({ isShown, closeModal, withdrawalReason, type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { updateWithdrawalResponse, newWithdrawal, deleteWithdrawalResponse, modalLoading }: any =
    useSelector(withdrawalSelector);

  const onSaveHandler = (values: IWithdrawalReason) => {
    const dataToSet = { ...values, name: values.name.trim() };
    if (withdrawalReason) {
      dispatch(putUpdateWithdrawalRequest(dataToSet));
    } else {
      dispatch(postAddNewWithdrawalRequest(dataToSet));
    }
  };

  const initialValue = withdrawalReason ? withdrawalReason : { name: '', createDate: new Date() };

  useEffect(() => {
    if (newWithdrawal) {
      dispatch(postAddNewWithdrawalSuccess(null));
      closeModal();
    }

    if (updateWithdrawalResponse) {
      dispatch(putUpdateWithdrawalSuccess(null));
      closeModal();
    }

    if (deleteWithdrawalResponse) {
      dispatch(deleteWithdrawalSuccess(false));
      closeModal();
    }
  }, [updateWithdrawalResponse, newWithdrawal, deleteWithdrawalResponse]);

  return (
    <div>
      <Modal
        open={isShown}
        onClose={() => {
          dispatch(putUpdateWithdrawalSuccess({}));
          closeModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik initialValues={initialValue} onSubmit={(values) => onSaveHandler(values)} enableReinitialize>
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <div className={styles.menu}>
                  <span className={styles.spinnerWrapper}>
                    {values?.id ? (
                      <Typography id="modal-modal-title" variant="h6" component="h6">
                        {t('EditMasterDataEntry')}
                      </Typography>
                    ) : (
                      <Typography id="modal-modal-title" variant="h6" component="h6">
                        {t('CreateMasterDataEntry')}
                      </Typography>
                    )}

                    <span>{modalLoading && <Spinner />}</span>
                  </span>

                  <div>
                    {withdrawalReason && (
                      <DeleteOutlinedIcon
                        className={styles.icon}
                        onClick={() => {
                          dispatch(deleteWithdrawalRequest({ withdrawalReasonId: withdrawalReason.id }));
                        }}
                      />
                    )}

                    <CloseIcon className={styles.icon} onClick={closeModal} />
                  </div>
                </div>

                {withdrawalReason ? (
                  <div className={styles.editor}>
                    <span className={styles.editorLegend}>
                      {t('CreatedOn')} {values?.creatorEmail},{' '}
                      {values.createDate ? format(new Date(values?.createDate), 'yyyy-MM-dd HH:mm:ss') : null}
                    </span>

                    {values.updateDate && (
                      <span className={styles.editorLegend}>
                        {t('EditedOn')} {values?.updaterEmail},{' '}
                        {values.updateDate ? format(new Date(values?.updateDate), 'yyyy-MM-dd HH:mm:ss') : null}
                      </span>
                    )}
                  </div>
                ) : null}
                <div className={styles.header}>
                  <div className={styles.wrapper}>
                    {values?.id ? (
                      <span className={styles.title}>
                        {t('EditWithdrawalReasonHeaderName')} (ID {values?.id})<em className={styles.required}> *</em>
                      </span>
                    ) : (
                      <span className={styles.title}>
                        {t('NewWithdrawalReasonHeaderName')}
                        <em className={styles.required}> *</em>
                      </span>
                    )}
                    <SimpleField
                      name="name"
                      hasMarginTop={false}
                      maxLength={250}
                      validate={true}
                      value={values.name}
                      disabled={type === 'edit'}
                    />
                  </div>
                </div>

                <section className={styles.btnSection}>
                  <>
                    {updateWithdrawalResponse?.messages?.Failed != null ? (
                      <span className={styles.errorMessage}>{updateWithdrawalResponse?.messages?.Failed[0]}</span>
                    ) : (
                      <div></div>
                    )}
                  </>

                  {type === 'create' && (
                    <Button
                      type="submit"
                      className={styles.saveBtn}
                      disabled={values.name.trim().length === 0}
                      variant="contained"
                    >
                      <span className={styles.btnText}>Зберегти</span>
                      <SaveIcon className={styles.icon} />
                    </Button>
                  )}
                </section>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};
