import { Actions } from './actions';
import {
  CLEAR_PHOTO_UNIT,
  CLOSE_ERROR_POPUP_UNIT,
  GET_PHOTO_UNIT_REQUEST,
  GET_PHOTO_UNIT_SUCCESS,
  GET_UNIT_TEMPLATES_BY_ID_REQUEST,
  GET_UNIT_TEMPLATES_BY_ID_SUCCESS,
  GET_UNIT_TEMPLATES_ERROR,
  GET_UNIT_TEMPLATES_REQUEST,
  GET_UNIT_TEMPLATES_SUCCESS,
  POST_CREATE_UNIT_TEMPLATES_SUCCESS,
  PUT_UPDATE_UNIT_TEMPLATES_SUCCESS,
} from './constants';

const initialState = {
  loading: false as boolean,
  photoLoading: false as boolean,
  error: null as Error | null,
  unitTemplates: [] as any,
  createdUnitTemplates: [] as any,
  selectedUnitTemplates: [] as any,
  updateUnitResponse: [] as any,

  getUnitTemplatesError: {} as any,

  photoUnit: null as any,
};

export type UnitTemplateState = typeof initialState;

export const UnitTemplateReducer = (state: UnitTemplateState = initialState, action: Actions) => {
  switch (action.type) {
    case GET_UNIT_TEMPLATES_REQUEST: {
      return {
        ...state,
        loading: true,
        selectedUnitTemplates: {},
        getUnitTemplatesError: {} as any,
      };
    }
    case GET_UNIT_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        unitTemplates: action.payload,
      };
    }
    case GET_UNIT_TEMPLATES_ERROR: {
      return {
        ...state,
        loading: false,
        getUnitTemplatesError: action.payload,
      };
    }
    case POST_CREATE_UNIT_TEMPLATES_SUCCESS: {
      return {
        ...state,
        createdUnitTemplates: action.payload,
      };
    }
    case GET_UNIT_TEMPLATES_BY_ID_REQUEST: {
      return {
        ...state,
        loading: true,
        selectedUnitTemplates: {},
      };
    }
    case GET_UNIT_TEMPLATES_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedUnitTemplates: action.payload,
        photoUnit: null,
      };
    }
    case PUT_UPDATE_UNIT_TEMPLATES_SUCCESS: {
      return {
        ...state,
        updateUnitResponse: action.payload,
      };
    }
    case GET_PHOTO_UNIT_REQUEST: {
      return {
        ...state,
        photoLoading: true,
        photoUnit: null,
      };
    }
    case GET_PHOTO_UNIT_SUCCESS: {
      return {
        ...state,
        photoLoading: false,
        photoUnit: action.payload,
      };
    }
    case CLEAR_PHOTO_UNIT: {
      return {
        ...state,
        photoUnit: null,
      };
    }
    case CLOSE_ERROR_POPUP_UNIT: {
      return {
        ...state,
        getUnitTemplatesError: {} as any,
      };
    }
    default: {
      return { ...state };
    }
  }
};
