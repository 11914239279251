import {
  CLOSE_ERROR_POPUP_REPORT_ERROR,
  GET_CODES_FOR_IMPORT_REPORT_ERROR,
  GET_CODES_FOR_IMPORT_REPORT_REQUEST,
  GET_CODES_FOR_IMPORT_REPORT_SUCCESS,
} from './constants';

export const getCodesForImportReportRequest = (dates: any) => ({
  type: GET_CODES_FOR_IMPORT_REPORT_REQUEST,
  payload: dates,
});
export const getCodesForImportReportSuccess = (data: any) => ({
  type: GET_CODES_FOR_IMPORT_REPORT_SUCCESS,
  payload: data,
});
export const getCodesForImportReportError = (error: any) => ({
  type: GET_CODES_FOR_IMPORT_REPORT_ERROR,
  payload: error,
});

export const closeErrorPopUpImportReport = () => ({
  type: CLOSE_ERROR_POPUP_REPORT_ERROR,
});

export type Actions =
  | ReturnType<typeof closeErrorPopUpImportReport>
  | ReturnType<typeof getCodesForImportReportRequest>
  | ReturnType<typeof getCodesForImportReportSuccess>
  | ReturnType<typeof getCodesForImportReportError>;
