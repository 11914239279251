export const inputStyle = {
  width: 200,
  height: 30,
  fontSize: '13px !important',
  margin: 0,
  bgcolor: '#ffffff',
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    WebkitTextFillColor: '#000000 !important',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
};

export const disabledInputStyle = {
  width: 200,
  height: 30,
  fontSize: '13px !important',
  margin: 0,
  bgcolor: '#f0eeee',
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    WebkitTextFillColor: '#000000 !important',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
};
