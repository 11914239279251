import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

import './index.css';
import App from './App';
import configureStore from './store';
import './i18n';

const store = configureStore();
// @ts-expect-error
export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((message: EventMessage) => {
  if (message.eventType === EventType.ACCOUNT_ADDED && message.payload) {
    const payload = message.payload as AuthenticationResult;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
  if (message.eventType === EventType.LOGIN_FAILURE || message.eventType === EventType.LOGOUT_FAILURE) {
    localStorage.clear();
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </MsalProvider>,
);
