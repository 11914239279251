import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useStyles } from './styles';

interface Props {
  value?: string;
  placeholder?: string;
  onSearch?: () => void;
  setTableData?: React.Dispatch<React.SetStateAction<any>>;
  data?: any;
  filterFieldName?: string;
  clearOnPropsChange?: boolean;
}

const searchStyle = {
  height: '30px !important',
  fontSize: '13px !important',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '.MuiInputBase-root': {
    fontSize: '13px !important',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
};

const InputSearch: React.FC<Props> = ({
  placeholder,
  setTableData,
  data,
  filterFieldName,
  clearOnPropsChange = true,
}) => {
  const { root, inputSearch, icon, altericon } = useStyles();
  const [value, setValue] = useState<any>('');

  const requestSearch = (searchValue: any) => {
    setValue(searchValue);
    const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
    const filteredRows = data.filter((o: any) => {
      return Object.keys(o).some((k: any) => {
        return filterFieldName
          ? searchRegex.test(o[k] !== null && k === filterFieldName ? o[k].toString() : null)
          : searchRegex.test(o[k] !== null ? o[k].toString() : null);
      });
    });
    // @ts-expect-error
    setTableData(filteredRows);
  };

  const clearSearch = () => {
    // @ts-expect-error
    setTableData(data);
    setValue('');
  };

  useEffect(() => {
    if (data) {
      requestSearch(value);
    }
    if (clearOnPropsChange) {
      setValue('');
    }
  }, [data]);

  return (
    <Paper component="form" className={root}>
      <IconButton aria-label="search" disabled>
        {value !== '' ? <SearchIcon className={altericon} /> : <SearchIcon className={icon} />}
      </IconButton>
      <InputBase
        placeholder={placeholder}
        className={inputSearch}
        value={value}
        sx={searchStyle}
        type="string"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onChange={(event) => requestSearch(event.target.value)}
        inputProps={{ 'aria-label': 'search' }}
      />
      <IconButton aria-label="search" onClick={clearSearch}>
        {value !== '' ? <CloseIcon className={icon} /> : null}
      </IconButton>
    </Paper>
  );
};

export default InputSearch;
